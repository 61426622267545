import { Component, OnInit, Inject, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserDetailsComponent } from '../../manage-users/user-details/user-details.component';
import { DeleteCommomComponent } from 'src/app/pages/commonForAll/delete-commom/delete-commom.component';
import { EditEventComponent } from 'src/app/core/components/event-lists/edit-event/edit-event.component';
import { ContactDetailsComponent } from '../../manage-contacts/contact-details/contact-details.component';
import { SiteDetailsComponent } from '../../manage-sites/site-details/site-details.component';
import { FileDownloadShareComponent } from 'src/app/pages/files/components/file-download-share/file-download-share.component';
import { AddFileComponent } from 'src/app/pages/files/components/add-file/add-file.component';
import { AddNotesComponent } from 'src/app/pages/files/components/add-notes/add-notes.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { MessageService } from 'src/app/core/services/message.service';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { HttpParams } from '@angular/common/http';
import { ViewInitiativesComponent } from '../../management-initiatives/view-initiatives/view-initiatives.component';
import { PopupForAllComponent } from 'src/app/pages/commonForAll/popup-for-all/popup-for-all.component';
import { ReminderViewComponent } from 'src/app/pages/Reminder/reminder-view/reminder-view.component';
import { CreateTaskComponent } from '../create-task/create-task.component';
import { ActivitLogComponent } from '../../activit-log/activit-log.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { PopupCommonComponent } from 'src/app/pages/commonForAll/popup-common/popup-common.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  screentype: any;
  taskId: any;
}

@Component({
  selector: 'app-view-task',
  templateUrl: './view-task.component.html',
  styleUrls: ['./view-task.component.scss'],
})
export class ViewTaskComponent implements OnInit {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  Subscribestatus=true
  loader: boolean = false; // Variable to control overlay visibility
  eventLinkedById: any;
  eventData: any;
  taskData: any;
  eventUsers: any;
  clients: any;
  eventUsersDto: any;
  eventContactsDtos: any;
  eventCreator: any;
  creator: any;
  showdata = false;
  showusers = false;
  showcontact = false;
  showinitiative = false;
  contactLinkedById: any;
  showfile = false;
  getNote: any;
  showNote = false;
  getfile: any;
  eventId: any;
  AdminStatus: any;
  otherpepuleplan = false;
  getuser: any;
  assignedTo: boolean = false;
  showsteps = false;
  showpredecessor = false;
  showsuccessor = false;
  progressValue = 60;
  customColor = 'purple';
  progress: number = 60;
  radius: number = 40;
  predecessorlist = [];
  successorlist = [];
  assignedUser: any;
  assignedContact: any;
  assignedInitiatives: any;
  ApproversList:any=[]
  showApprovers=false
  showReminder: boolean = false;
  remindersLinkedById: any;
  TaskUsers: any = [];
  lablesbasedonentity:any;
  
  constructor(
    public dialogRef: MatDialogRef<ViewTaskComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private calendarService: CalendarService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
    this.AdminStatus = sessionStorage.getItem('isAdmin');
  }

  ngOnInit() {
    console.log('testttt', this.data);

    this.detailsGetApiCalls();
    this.GetLabelsByOrganization()
  }

  SubscribestatusUpdate(x) {
    const deleteorder = this.dialog.open(DeleteCommomComponent, {
      data: {type: this.Subscribestatus==true?'Subscribe':'UnSubscribe' },
    });
    deleteorder.afterClosed().subscribe((result: any) => {
      this.Subscribestatus=!this.Subscribestatus
      // this.injector.get(ManageUsersService).SubscribeOrUnsubscribe(x.id).subscribe((result)=>{
      //   if(result){
      //     console.log(result)
      //   }
      // })

    });
  }
  
  onDoubleClick(value) {
    if(this.AdminStatus=='true'){
      const onDoubleClickDialog = this.dialog.open(PopupCommonComponent, {
        data: { value: value, screenType: 'onDoubleClick' },
        disableClose: true,
        width: '400px',
      });
      onDoubleClickDialog.afterClosed().subscribe((result) => {
        if (result) {
          const data={
            "id": this.lablesbasedonentity[value].id,
            "entityName": "task",
            "defaultColumnName": value,
            "newColumnName": result
          }
          this.authService.UpdateLabelbasedonentity(data).subscribe(
            (result) => {
              if (result) {
                this.snackBar.open('Update Label based on entity done', '', {
                  duration: 2000,
                });
                this.GetLabelsByOrganization();
              }
            },
            (error) => {
              if (error.status == 200) {
                this.snackBar.open('Update Label based on entity done', '', {
                  duration: 2000,
                });
                this.GetLabelsByOrganization();
              }
            }
          );
        }
      });
    }
  }

  GetLabelsByOrganization(){
this.authService.GetColumnMappingsByEntityName('task').subscribe((result)=>{
  console.log(result)
  this.lablesbasedonentity=result
})
  } 

  getlable(value){
    if(this.lablesbasedonentity){
      return this.lablesbasedonentity[value].value
    }else{
      return value
    }
  } 
 
  closepopup() { 
    this.dialogRef.close(true);
  }

  findstepsLinked() {
    this.showsteps = !this.showsteps;
  }
  temformat(value: any) {
    const fractionalPart = Number(value) % 1;
    if (fractionalPart >= 0.5) {
      return Math.ceil(Number(value)) + '%';
    } else if (value == 0) {
      return 0;
    } else {
      return Math.floor(Number(value)) + '%';
    }
  }
  public circumference: number = 2 * Math.PI * this.radius;
  calculateDashoffset(progress: any) {
    const percent = progress > 100 ? 100 : progress < 0 ? 0 : progress;
    return this.circumference - (percent / 100) * this.circumference;
  }

  getApproversList(id){
    if(this.showApprovers==false){
      this.injector.get(ManageUsersService).FindTaskApprovers(id).subscribe((result)=>{
        if(result){
          this.showApprovers=!this.showApprovers
          this.ApproversList=result
        }
       }) 
    }else{
      this.showApprovers=!this.showApprovers
    }

  }
  
  findRemindersLinked(taskData) {
    console.log(taskData)
    this.calendarService.FindReminderLinked(taskData, 3).subscribe((res) => {
      this.remindersLinkedById = res;
      console.log(this.remindersLinkedById)
      this.showReminder = !this.showReminder;
    }); 
    this.FindUserbyTaskId()
  } 
    // reminderDialog
  // openContactDialog
  openReminderDialog(row:any, type: string, id:any): void {
    const updateReminder = this.dialog.open(ReminderViewComponent, {
      data: { data: row, type: type, selectedUsers: this.assignedUser, eventId: id},
      width: '500px',
    });
    updateReminder.afterClosed().subscribe((result) => {
      if(result) {
        this.detailsGetApiCalls()
      }
    
    });
     
  }

  detailsGetApiCalls() {
    this.loader = true;
    this.injector.get(ManageUsersService).FindTaskById(this.data).subscribe((res: any) => {
      console.log('ressss', res);
      this.taskData = res;
      this.loader = false;
      if (res.hasStep == true && res.stepsCount != 0) {
        this.injector.get(ManageUsersService).FindTaskSteps(this.data).subscribe((result) => {
          if (result) {
            this.taskData.stepsDto = result;
            console.log(result);
          }
        });
      }
      if(res.approversCount!=0){
        this.injector.get(ManageUsersService).FindTaskApprovers(this.data).subscribe((result)=>{
          if(result){
            res.TaskApprovers=result
          }
        })

      }
    });
  }

  get50TenCharacters(value: any) {
    if (value.length > 30) {
      let firstTenCharacters = value.slice(0, 30);
      var data = firstTenCharacters + '...';
      return this.toTitleCase(data);
    } else {
      return this.toTitleCase(value);
    }
  }
  toTitleCase(str: any) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word: any) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  assignCssClass1(progress: number): string {
    let cssClass = '';
    if (Number(progress) >= 0 && Number(progress) <= 20) {
      cssClass = 'color1-20';
    } else if (Number(progress) >= 21 && Number(progress) <= 50) {
      cssClass = 'color2-50';
    } else if (Number(progress) >= 51 && Number(progress) <= 80) {
      cssClass = 'color3-80';
    } else if (Number(progress) >= 81 && Number(progress) <= 90) {
      cssClass = 'color4-90';
    } else if (Number(progress) >= 100) {
      cssClass = 'color5-100';
    }
    return cssClass;
  }

  toggleTaskCompletion(taskData: any, Stage: any) {
    if (taskData.isPublic == true&&taskData?.needAllApproval==true) {
      this.makeapproval(taskData);
    } else {
      const formData = new HttpParams()
        .set('taskId', taskData.id)
        .set('Stage', Stage);
      this.injector.get(ManageUsersService).UpdateTaskStatus(formData).subscribe(
        (res: any) => {
          this.injector.get(MessageService).showMessage('UpdateTaskStatus successfully');
          this.ngOnInit();
        },
        (error) => {
          console.error('Error Complete The Task:', error);
        }
      );
    }
  }

  // Updatestatus(taskData,status){
  //   const formData = new HttpParams()
  //   .set('taskId', taskData.id)
  //   .set('Stage', status =='Assigned'?'1' : status =='in progress'?'3':status =='Completed'?'4':status =='Canceled'?'5':'6');
  //   this.injector.get(ManageUsersService).UpdateTaskStatus(formData).subscribe(
  //     (res: any) => {
  //       this.injector.get(MessageService).showMessage('Task status Update successfully');
  //       this.ngOnInit();
  //     },
  //     error => {
  //       console.error('Error status Update ', error);
  //     }
  //   );
  // }

  Updatestepstatus(taskData: any, id: any) {
    console.log(taskData);
    if (taskData.isPublic == true&&taskData.approvalStatus!='None') {
      this.makeapproval(taskData);
    } else {
      this.injector.get(ManageUsersService).UpdateTaskStepStatus(id).subscribe(
        (res: any) => {
          if (res) {
            this.injector.get(MessageService).showMessage(
              'Task Step status Update successfully'
            );
            this.ngOnInit();
          }
        },
        (error) => {
          console.error('Error status Update ', error);
        }
      );
    }
  }

  makeapproval(taskData: any) {
    const makeapproval = this.dialog.open(PopupForAllComponent, {
      data: { taskData, type: 'makeapproval' },
    });
    makeapproval.afterClosed().subscribe((result: any) => {
      if (result == true) {
        console.log(taskData);
        this.injector.get(ManageUsersService).RequestApproveTask(taskData.id).subscribe((result: any) => {
            if (result) {
              console.log(result);
              this.injector.get(MessageService).showMessage(
                'Your request for task approval has been successfully sent.'
              );
              this.ngOnInit();
            }
          });
      }
      console.log(result);
    });
  }

  findassignedToUserLinked() {
    this.assignedTo = !this.assignedTo;
  }

  FindUserbyTaskId() {
    this.injector.get(ManageUsersService).FindUserByTaskId(this.data).subscribe((res) => {
      console.log(res);
      this.assignedUser = res;
    });
  }

  findUserLinked(count: any) {
    if (count > 0) {
      this.injector.get(ManageUsersService).FindTaskById(this.data).subscribe((res: any) => {
        console.log('ressss', res);
        this.taskData = res;
        this.showusers = !this.showusers;
      });
      this.FindUserbyTaskId();
    }
  }

  findContactLinked(count: any) {
    if (count > 0) {
      this.injector.get(ManageUsersService).FindContactsByTaskId(this.data).subscribe((res) => {
        console.log(res);
        this.assignedContact = res;
      });
      this.showcontact = !this.showcontact;
    }
  }

  findInitiativeLinked(count: any) {
    if (count > 0) {
      this.injector.get(ManageUsersService).FindInitiativesByTaskId(this.data).subscribe((res) => {
        console.log(res);
        this.assignedInitiatives = res;
      });
      this.showinitiative = !this.showinitiative;
    }
  }

  getlinkedpredecessor(data: any) {
    this.showpredecessor = !this.showpredecessor;
    this.loader = true;
    if (data.predecessorTaskcount != 0) {
      this.injector.get(ManageUsersService).GetLinkedTasks(data.id, false, true).subscribe(
        (result) => {
          if (result) {
            this.loader = false;
            console.log(result);
            this.predecessorlist = result;
          }
        },
        (error: any) => {
          this.loader = false;
        }
      );
    }
  }
  getlinkedsuccessor(data: any) {
    this.showsuccessor = !this.showsuccessor;
    this.loader = true;
    if (data.predecessorTaskcount != 0) {
      this.injector.get(ManageUsersService).GetLinkedTasks(data.id, true, false).subscribe(
        (result) => {
          if (result) {
            console.log(result);
            this.loader = false;
            this.successorlist = result;
          }
        },
        (error) => {
          this.loader = false;
        }
      );
    }
  }

  opentaskDialog(id: any) {
    this.dialog.open(ViewTaskComponent, {
      width: '550px',
      position: { top: '125px', left: '700px' },
      autoFocus: false,
      disableClose: true,
      data: id,
    });
  }

  openInitiativeDialog(row: any, type: string) {
    this.dialog.open(ViewInitiativesComponent, {
      width: '550px',
      position: { top: '125px', left: '700px' },
      autoFocus: false,
      disableClose: true,
      data: { data: row, type: type },
    });
  }

  // openUserDialog
  openUserDialog(row: any, type: string) {
    this.dialog.open(UserDetailsComponent, {
      width: '500px',
      position: { top: '125px', left: '700px' },
      autoFocus: false,
      disableClose: true,
      data: { data: row, type: type },
    });
  }
  deleteDialogConfirmation(data: any, type: any) {
    const deleteorder = this.dialog.open(DeleteCommomComponent, {
      data: { data, type: type },
    });
    deleteorder.afterClosed().subscribe((result: any) => {
      this.dialogRef.close(true);
    });
  }

  // openEditdEventDialog
  openEditdEventDialog(data: any,type:string) {
    const openAddEventDialog = this.dialog.open(CreateTaskComponent, {
      width: '500px',
      data: { data: data,type:type },
    });
    openAddEventDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  // openContactDialog
  openContactDialog(row: any, type: string): void {
    this.dialog.open(ContactDetailsComponent, {
      data: { data: row, type: type },
      width: '500px',
      position: { top: '125px', left: '700px' },
    });
  }
  // openSiteDialog
  openSiteDialog(siteId: any, type: string): void {
    const data = { id: siteId };
    this.dialog.open(SiteDetailsComponent, {
      data: { data: data, type: type },
      width: '500px',
      position: { top: '125px', left: '700px' },
    });
  }
  // findfilesLinked
  findfilesLinked(count: any) {
    if (count > 0) {
      this.injector.get(ManageUsersService) .getfile(this.data, 'Task') .subscribe((res) => {
          this.getfile = res;
          this.showfile = !this.showfile;
        });
    }
  }
  // findnotesLinked
  findnotesLinked(count: any) {
    if (count > 0) {
      this.injector.get(ManageUsersService) .getnotes(this.data, 'Task') .subscribe((res) => {
          this.getNote = res;
          this.showNote = !this.showNote;
        });
    }
  }
  // gotofiles
  gotofiles(row: any) {
    this.dialog.open(FileDownloadShareComponent, {
      width: '500px',
      data: row,
      position: { top: '125px', left: '700px' },
      autoFocus: false,
      disableClose: true,
    });
  }



  getstatus(value: any) {
    if (value) {
      return value.replace('_', ' ');
    } else {
      return '';
    }
  }

  getStatusColorClass(status: string) {
    let value = '';
    if (status == 'Assigned') {
      value = 'Assign-color';
    } else if (status == 'Not Assigned' || status == 'Not_Assigned') {
      value = 'NotAssign-color';
    } else if (status == 'Completed') {
      value = 'completed-color';
    } else if (status == 'Canceled') {
      value = 'cancelled-color';
    } else if (
      status == 'In Progress' ||
      status == 'In_Progress' ||
      status == 'in Progress'
    ) {
      value = 'InProgress-color';
    }
    return value;
  }



  showActivitylogs(data:any){
    const activityDialog = this.dialog.open(ActivitLogComponent, {
      width: '600px',
      data: { type: 'Tasks', data:data },
      autoFocus: false,
      disableClose: true,
    });
    activityDialog.afterClosed().subscribe(result => {
      if(result){
        this.detailsGetApiCalls();
      }
    })
  }

  assignCssClass(percentage: number): string {
    if (percentage <= 20) {
      return 'color-20';
    } else if (percentage <= 50) {
      return 'color-50';
    } else if (percentage <= 80) {
      return 'color-80';
    } else if (percentage <= 90) {
      return 'color-90';
    } else {
      return 'color-100';
    }
  }

    // AddFile
    AddFile(row: any, type: any) {
      this.dialog.open(AddFileComponent, {
        disableClose: true,
        width: '500px',
        data: {
          data: row,
          attachmentLevel: 'Task',
          ismanage: true,
          type: type,
        },
        panelClass: ['addFiles'],
      });
    }
    // AddNote
    AddNote(row: any, type: any) {
      this.dialog.open(AddNotesComponent, {
        disableClose: true,
        data: {
          data: row,
          attachmentLevel: 'Task',
          ismanage: true,
          type: type,
        },
        width: '600px',
        panelClass: ['addNotes'],
      });
    }
}
