import { Component, ElementRef, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
@Component({
  selector: 'app-show-template-view',
  templateUrl: './show-template-view.component.html',
  styleUrls: ['./show-template-view.component.scss']
})
export class ShowTemplateViewComponent implements OnInit {
  Form: FormGroup;
  htmlContent: string = '';
  @ViewChild('dialogContent') dialogContent: ElementRef;
  @ViewChild('htmlContainer') htmlContainer: ElementRef<HTMLDivElement>;
  GetAllUsercolumes=[]
  GetAllContactcolumes=[]
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public elementRef: ElementRef,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private matDialogRef: MatDialogRef<ShowTemplateViewComponent>,
    private injector: Injector,
  ) {

    this.Form = this.formBuilder.group({
      AddTags: this.formBuilder.array([]),
    });
   }

  ngOnInit(): void {
    if (this.dialogContent && this.dialogContent.nativeElement) {
      this.dialogContent.nativeElement.scrollTop = 0;
    }
    this.scrollToTop()
    var myComponent = document.getElementById('myComponent');
    myComponent.scrollTop = 0;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if(this.data){
      if(this.data.htmlContent){
        this.htmlContent=this.data.htmlContent
      }
      this.GetAllTagColumns()
    }
    console.log(this.data.htmlContent)
  }

  scrollToTop() {
    const htmlContentContainer = this.elementRef.nativeElement.querySelector('.html-content');
    if (htmlContentContainer) {
      htmlContentContainer.scrollTop = 0;
    }
  }


  GetAllTagColumns(){
    this.injector.get(ManageUsersService).GetMapColumns('users').subscribe((result:any)=>{
      if(result){
        this.GetAllUsercolumes=result
      }
    })
    this.injector.get(ManageUsersService).GetMapColumns('contacts').subscribe((result:any)=>{
      if(result){
        this.GetAllContactcolumes=result
      }
    })
    }
      




  AddTags(): FormArray {
    return this.Form.get('AddTags') as FormArray;
  }

  newFile(selectedText,index): FormGroup {
    return this.formBuilder.group({
      selectedText: [selectedText],
      index: [index],
      Columnname:[''],
      Entity:['']
    });
  }

  getentityname(index){
    return this.AddTags().at(index).get('Entity').value
  }

  Removecondictuon(i: number) {
    this.AddTags().removeAt(i);
  }


  onSubmit(){
    this.matDialogRef.close(this.Form.value.AddTags)
  }


  onClick(): void {
    const selectedText = window.getSelection()?.toString(); // Get the selected text
    if (selectedText) {
      const index = this.htmlContent.indexOf(selectedText);
      console.log('Index position:', index);
      console.log('selectedText', selectedText)
      console.log(this.Form.value.AddTags.lenght)
      console.log(this.Form.value.AddTags)
      console.log(this.Form)
      this.AddTags().push(this.newFile(selectedText,index));
    }
  }

}
