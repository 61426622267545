<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100"><mat-icon style="position: relative;right: 10px;">
            group</mat-icon>{{'Create Dynamic Group'|translate}}</h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="DynamicForm" id="add-notes-form">
        <div class="row w-100">
            <div class="col-12">
                <!-- title -->
                <mat-form-field appearance="legacy" class="w-100">
                    <mat-label>{{'Group Name'|translate}}</mat-label>
                    <input matInput formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-12">
                <!-- description  -->
                <mat-form-field appearance="legacy" class="w-100">
                    <mat-label>{{'Description'|translate}}</mat-label>
                    <input matInput formControlName="description">
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-chip-list" style="width: 100% !important;">
                    <mat-label>{{'keyword'|translate}}</mat-label><br>
                    <mat-chip-list #chipGridforkeyword aria-label="Fruit selection" class="keywordlist">
                        <mat-chip-row class="keywordrow" *ngFor="let keyword of keywordlist">
                            <button class="keywordbutton">
                                <span class="keywordpostion">{{keyword|titlecase}}</span>
                                <mat-icon class="keywordcancelicon" (click)="remove(keyword)">cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-list>
                    <input [matChipInputFor]="chipGridforkeyword" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event)" />
                </mat-form-field>
            </div>
        </div>
        <div class="row condictionbodystyle">
            <div class="col-10"> <h4 style="text-align: center;"><b>Dynamic Group Conditions</b></h4> </div>
            <div class="col-2">
                <mat-icon (click)="addConditionalBody()">add_box</mat-icon>
            </div>
        </div>
        <div class="row w-100" formArrayName="dynamicGroupConditions" style="border: 1px solid gray;">
            <!-- conditionsBodyGroup  -->
            <div class="row  Conditionsbody "
                *ngFor="let conditionsBodyGroup of getConditionsBody().controls; let i=index" [formGroupName]="i">
                <!-- entety -->
                <ng-container *ngIf="type=='Users'">
                    <div class="row ">
                        <div class="col-12">
                            <mat-form-field  class="w-100 ">
                                <mat-label>{{'Select Entity'|translate}}</mat-label>
                                <mat-select formControlName="conditionDropDownList">
                                    <mat-option [value]="selactValue" *ngFor="let selactValue of selactValues"
                                        (change)="finddropdownname(selactValue)">
                                        {{selactValue}}
                                    </mat-option> 
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="type=='Sites'">
                    <div class="row ">
                        <div class="col-12">
                            <mat-form-field  class="w-100 ">
                                <mat-label>{{'Select Entity'|translate}}</mat-label>
                                <mat-select formControlName="conditionDropDownList">
                                    <mat-option [value]="selactValue" *ngFor="let selactValue of selactValues1"
                                        (change)="finddropdownname(selactValue)">
                                        {{selactValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="type=='Contacts'">
                    <div class="row ">
                        <div class="col-12">
                            <mat-form-field  class="w-100 ">
                                <mat-label>{{'Select Entity'|translate}}</mat-label>
                                <mat-select formControlName="conditionDropDownList">
                                    <mat-option [value]="selactValue" *ngFor="let selactValue of selactValues2"
                                        (change)="finddropdownname(selactValue)">
                                        {{selactValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="type=='Products'">
                    <div class="row ">
                        <div class="col-12">
                            <mat-form-field  class="w-100 ">
                                <mat-label>{{'Select Entity'|translate}}</mat-label>
                                <mat-select formControlName="conditionDropDownList">
                                    <mat-option [value]="selactValue" *ngFor="let selactValue of selactValues3"
                                        (change)="finddropdownname(selactValue)">
                                        {{selactValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
                <div>
                    <!-- condictions  -->
                    <div class="row ">
                        <div class="col-4">
                            <!-- UsersValue -->
                            <ng-container *ngIf="getvalue(i)=='Users'">
                                <mat-form-field  class="w-100">
                                    <mat-label>{{'Parameter'|translate}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x" *ngFor="let x of UsersValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </ng-container>
                            <!-- ContactsValue -->
                            <ng-container *ngIf="getvalue(i)=='Contacts'">
                                <mat-form-field  class="w-100">
                                    <mat-label>{{'Parameter'|translate}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x" *ngFor="let x of ContactsValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <!-- Products  -->
                            <ng-container *ngIf="getvalue(i)=='Products'">
                                <mat-form-field  class="w-100">
                                    <mat-label>{{'Parameter'|translate}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x" *ngFor="let x of ProductsValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <!-- Sites  -->
                            <ng-container *ngIf="getvalue(i)=='Sites'">
                                <mat-form-field  class="w-100">
                                    <mat-label>{{'Parameter'|translate}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x" *ngFor="let x of SitesValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <!-- TransationValue  -->
                            <ng-container *ngIf="getvalue(i)=='Transactions'">
                                <mat-form-field  class="w-100">
                                    <mat-label>{{'Parameter'|translate}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x" *ngFor="let x of TransationValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <!-- EventsValue  -->
                            <ng-container *ngIf="getvalue(i)=='Events'">
                                <mat-form-field  class="w-100">
                                    <mat-label>{{'Parameter'|translate}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x" *ngFor="let x of EventsValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </div>
                        <div class="col-4">
                            <!-- AllOption  -->
                            <ng-container
                                *ngIf="getdataType(i)!='string'&&getdataType(i)!='String' && getdataType(i)!='number'&&getdataType(i)!='Number'
                                     && getdataType(i)!='bool' &&getdataType(i)!='boolean'&&getdataType(i)!='Boolean'&&getdataType(i)!='Bool' &&getdataType(i)!='text' &&getdataType(i)!='Text'">
                                <mat-form-field  class="w-100">
                                    <mat-label>{{'Comparison'|translate}}</mat-label>
                                    <mat-select formControlName="conditionComparison">
                                        <mat-option [value]="x.v1" *ngFor="let x of AllOption">
                                            {{x.v2|translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <!-- string -->
                            <ng-container
                                *ngIf="getdataType(i)=='string' || getdataType(i)=='String' || getdataType(i)=='text'|| getdataType(i)=='Text'">
                                <mat-form-field  class="w-100">
                                    <mat-label>{{'Comparison'|translate}}</mat-label>
                                    <mat-select formControlName="conditionComparison">
                                        <mat-option [value]="x.v1" *ngFor="let x of stringoptions">
                                            {{x.v2|translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <!-- number  -->
                            <ng-container *ngIf="getdataType(i)=='number' || getdataType(i)=='Number'">
                                <mat-form-field  class="w-100">
                                    <mat-label>{{'Comparison'|translate}}</mat-label>
                                    <mat-select formControlName="conditionComparison">
                                        <mat-option [value]="x.v1" *ngFor="let x of numberoptions">
                                            {{x.v2|translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <!-- bool  -->
                            <ng-container
                                *ngIf="getdataType(i)=='bool' || getdataType(i)=='boolean'||getdataType(i)=='Bool'||getdataType(i)=='Boolean'">
                                <mat-form-field  class="w-100">
                                    <mat-label>{{'Comparison'|translate}}</mat-label>
                                    <mat-select formControlName="conditionComparison">
                                        <mat-option [value]="x.v1" *ngFor="let x of booleanoptions">
                                            {{x.v2|translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </div>
                        <div class="col-4">
                            <mat-form-field  class="w-100">
                                <mat-label>{{'value'|translate}}</mat-label>
                                <input matInput formControlName="value">
                            </mat-form-field>
                        </div>
                        <ng-container *ngIf="getvalue(i)=='Events'||getvalue(i)=='Transactions'">
                            <div class="row w-100">
                                <div class="col-6">
                                    <mat-form-field appearance="legacy" class="w-100">
                                        <mat-label>{{'Start Date'|translate}}</mat-label>
                                        <input matInput [matDatepicker]="startdatepicker2" formControlName="startDate">
                                        <mat-datepicker-toggle matSuffix
                                            [for]="startdatepicker2"></mat-datepicker-toggle>
                                        <mat-datepicker #startdatepicker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field appearance="legacy" class="w-100">
                                        <mat-label>{{'End Date'|translate}}</mat-label>
                                        <input matInput [matDatepicker]="enddatepicker2" formControlName="endDate">
                                        <mat-datepicker-toggle matSuffix [for]="enddatepicker2"></mat-datepicker-toggle>
                                        <mat-datepicker #enddatepicker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                
              <ng-container *ngIf="i!=0">
                <div class="row justify-content-end">
                    <div class="col-1">
                        <mat-icon (click)="removeConditionalBody(i)">delete</mat-icon>
                    </div>
                </div>
              </ng-container>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="my-1">
    <div class="row mt-2 w-100 justify-content-center">
        <div class="col-4"> 
            <button mat-raised-button  mat-dialog-close>{{'Cancel'|translate}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button (click)="onSubmit()" class="b-blue">{{'Apply'|translate}}</button>
        </div>
    </div>
</mat-dialog-actions>