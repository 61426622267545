<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <mat-icon style="position: relative;right: 10px;">search</mat-icon>{{'Advance Search'|translate}}
    </h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>



<mat-dialog-content style="padding: 0px  !important; margin: 0px !important; overflow: auto">
    <form [formGroup]="ActivitySearch">
        <div class="row justify-content-center mt-3">
            <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Choose Entity</mat-label>
                    <mat-select formControlName="Entity">
                        <mat-option [value]="x.id" *ngFor="let x of AllOption">
                            {{x.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row justify-content-center mt-3" >
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>{{"Name"|translate}}</mat-label>
                    <input matInput type="text" id="name" formControlName="Name">
                </mat-form-field>
            </div>
        </div>
        <div class="row w-100">
            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Start Date"|translate}}</mat-label>
                    <input matInput [matDatepicker]="startdatepicker1" style="color: black;"
                        formControlName="StartDate">
                    <mat-datepicker-toggle matSuffix [for]="startdatepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #startdatepicker1></mat-datepicker>
                   
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"End Date"|translate}}</mat-label>
                    <input matInput [matDatepicker]="enddatepicker1" style="color: black;"
                        formControlName="EndDate">
                    <mat-datepicker-toggle matSuffix [for]="enddatepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #enddatepicker1></mat-datepicker>
                   
                </mat-form-field>
            </div> 
        </div>
        <div class="row w-100">
            <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Linked By Users"|translate}} </mat-label>
                    <mat-select multiple (openedChange)="filterOptions('','users')" #select formControlName="UsersId">
                        <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'users')"
                            placeholder="Filter" />
                        <mat-option *ngFor="let user of filteredusers" [value]="user">
                            <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                                onError="this.src='assets/avatar.svg'">
                            {{ user.firstName| titlecase}} {{user.lastName|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row w-100">
            <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Linked By Contacts "|translate}} </mat-label>
                    <mat-select multiple (openedChange)="filterOptions('','contacts')" #select
                        formControlName="ContactsId">
                        <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'contact')"
                            placeholder="Filter" />
                        <mat-option *ngFor="let contact of filteredContacts" [value]="contact">
                            <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                [src]="contact?.profilePicture ? contact.profilePicture:'assets/avatar.svg'"
                                onError="this.src='assets/avatar.svg'">
                            {{ contact.firstName| titlecase}} {{contact.lastName|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row w-100">
            <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Linked By Sites "|translate}} </mat-label>
                    <mat-select multiple (openedChange)="filterOptions('','sites')" #select formControlName="SitesId">
                        <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'site')"
                            placeholder="Filter" />
                        <mat-option *ngFor="let site of filteredSites" [value]="site">
                            <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                [src]="site?.profilePicture ? site.profilePicture : 'assets/images/iconsForsvg/Site_Icon.svg'"
                                onError="this.src='assets/images/iconsForsvg/Site_Icon.svg'">
                            {{ site.companyName| titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row w-100">
            <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Linked By Products "|translate}} </mat-label>
                    <mat-select multiple (openedChange)="filterOptions('','contacts')" #select
                        formControlName="ProductsId">
                        <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'contact')"
                            placeholder="Filter" />
                        <mat-option *ngFor="let product of filteredProducts" [value]="product">
                            <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                [src]="product?.profilePicture ? product.profilePicture:'assets/images/iconsForsvg/icon_product.svg'"
                                onError="this.src='assets/images/iconsForsvg/icon_product.svg'">
                            {{ product.productName| titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row justify-content-center mt-3">
            <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Choose File Type</mat-label>
                    <mat-select formControlName="fileFormat">
                        <mat-option [value]="x.id" *ngFor="let x of FileFormat">
                            {{x.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>

</mat-dialog-content>

<mat-dialog-actions class="my-3 mb-3 mt-3 justify-content-end">
    <div class="row justify-content-end">
        <div class="col-4">
            <button mat-button mat-dialog-close>{{"Cancel"|translate}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button class="b-blue" (click)="submit()">{{"Apply"|translate}} </button>
        </div>
    </div>
</mat-dialog-actions>
