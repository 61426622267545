<div mat-dialog-title
    style="display: flex;justify-content: space-around;background-color: #f3f0f069;overflow: hidden;">
    <div style="width: 10%;position: relative;top: 10px;">
        <mat-icon style="color: black;" (click)="clocepopup()">keyboard_backspace</mat-icon>
    </div>
    <div style="width: 80%;position: relative;top: 10px;">
        <h1 style="text-align: center;"><b>{{"Contact Us"|translate}}</b></h1>
        </div>
    <div style="width: 10%;position: relative;top: 10px;"></div>
</div>

<mat-dialog-content class="mr-0 ml-0">
    <mat-card style="padding: 5px !important;">
        <div class="row w-100">
            <div
                class="col-12 order-2  col-sm-12 order-sm-2 col-md-12 order-md-2 col-lg-6 order-lg-1 order-xl-1 col-xl-5  "
                style="background-color: black;color: white !important;">
                <div class="row mt-4 justify-content-center">
                    <div class=" col-3 col-sm-3  col-md-4 col-lg-12 col-xl-12">
                        <img src="../../../assets/logo/startpro.png" class="pnmlogo">
                    </div>
                    <div class="col-9 col-sm-9  col-md-8 col-lg-12 col-xl-12">
                        <div class="row mt-3">
                            <div class="col-2 p-0"><mat-icon
                                    style="color: white !important;">
                                    location_on</mat-icon></div>
                            <div class="col-10"><p>40 Avenue de Saint Mandé
                                    75012 PARIS FRANCE </p> </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-2 p-0"><mat-icon
                                    style="color: white !important;"> phone</mat-icon></div>
                            <div class="col-10"> <p>+33 6 98 33 88 80</p>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-2 p-0"><mat-icon
                                    style="color: white !important;"> email</mat-icon></div>
                            <div class="col-10"> <p>support@planningandme.com</p>
                            </div>
                        </div>
    
                        <div class="row mt-3">
                            <div class="col-2 p-0"><mat-icon
                                    style="color: white !important;"> blur_circular</mat-icon></div>
                            <div class="col-10"><p><a
                                        style="color: white !important;text-decoration: none;"
                                        href="http://planningmeui.s3-website.eu-west-3.amazonaws.com"
                                        target="_blank">PlanningAndMe.com</a></p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="col-12 order-1 col-sm-12 order-sm-1 col-md-12 order-md-1 col-lg-7 order-lg-2 col-xl-7 order-xl-2">
                <form [formGroup]="contactForm">
                    <div>
                        <div class="row firstAndLastName">
                            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6 ">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Prénom'|translate}} <span style="color: red;">*</span></mat-label>
                                    <input matInput type="text"
                                        formControlName="FirstName">
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6 ">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Nom'|translate}} <span style="color: red;">*</span></mat-label>
                                    <input matInput type="text"
                                        formControlName="LastName">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row firstAndLastName">
                            <div class="col-12 ">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Email'|translate}} <span style="color: red;">*</span></mat-label>
                                    <input matInput type="email" 
                                        formControlName="Email">
                                </mat-form-field>
                            </div>
                            </div>
                    
                        <div class="row firstAndLastName">
                            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6 ">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Raison sociale '|translate}} <span style="color: red;">*</span></mat-label>
                                    <input matInput type="text"
                                        formControlName="Raisonsociale">
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6 ">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Code Postal'|translate}} <span style="color: red;">*</span></mat-label>
                                    <input matInput type="text"
                                        formControlName="CodePostal">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row firstAndLastName">
                            <div class="col-12">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Téléphone'|translate}} <span style="color: red;">*</span></mat-label>
                                    <input matInput type="number"
                                        formControlName="Téléphone">
                                </mat-form-field>
                            </div>
                            </div>
                      
                            <div class="row firstAndLastName">
                                <div class="col-12">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{'Purpose'|translate}} </mat-label>
                                        <input matInput type="text" 
                                            formControlName="Comments">
                                    </mat-form-field>
                                </div>
                                </div>
                    
                    </div>
                    <div class="row justify-content-center p-2"> 
                        <div class="col-5 p-2">
                            <button  mat-raised-button mat-dialog-close>Cancel</button>
                        </div>
                        <div class="col-5 p-2">
                            <button mat-raised-button class="b-blue" [disabled]="!contactForm.valid"
                                (click)="onSubmit()">Apply</button>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    </mat-card>
</mat-dialog-content>
