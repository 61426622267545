<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <mat-icon style="position: relative;right: 10px;"> import_export</mat-icon>
        {{'Export Planning'|translate}}
    </h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
    <mat-dialog-content class="mr-0 ml-0">
        <form [formGroup]="exportPlanningForm">
        <div class="row mt-2  mb-2">
            <div class="col-md-12 col-sm-12">
                <span class=" fw-600">{{'File Format'|translate}} : </span>
                <mat-radio-group id="fileFormat" formControlName="fileFormat">
                    <mat-radio-button value="csv" class="mr-4">CSV</mat-radio-button>
                    <mat-radio-button value="json" class="mr-4">JSON</mat-radio-button>
                    <mat-radio-button value="xlsx" class="mr-4">XLSX</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="row firstAndLastName">
            <div class="col-6">
                <mat-form-field class="w-100"> 
                    <mat-label>{{"Start Date"|translate}}</mat-label>
                    <input matInput [matDatepicker]="startdatepicker" 
                        formControlName="startDate">
                    <mat-datepicker-toggle matSuffix [for]="startdatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #startdatepicker></mat-datepicker>
                    <mat-error *ngIf="exportPlanningForm.controls['startDate'].hasError('required')">
                        {{'StartDate is'|translate}} <strong>{{'required'|translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-label>{{"End Date"|translate}}</mat-label>
                    <input matInput [matDatepicker]="enddatepicker" formControlName="endDate">
                    <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #enddatepicker></mat-datepicker>
                    <mat-error *ngIf="exportPlanningForm.controls['endDate'].hasError('required')">
                        {{'EndDate is'|translate}} <strong>{{'required'|translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-3">
                <mat-list-item>
                    <mat-checkbox formControlName="isPrivate">
                        <mat-icon>lock</mat-icon>
                        {{'Private'|translate}}</mat-checkbox>
                </mat-list-item>
            </div>
            <div class="col-3">
                <mat-list-item>
                    <mat-checkbox formControlName="isPublic">
                        <img src="../../../../assets/images/iconsForsvg/Unlock.svg">
                        {{'Public'|translate}}</mat-checkbox>
                </mat-list-item>
            </div>
            <div class="col-3">
                <mat-list-item>
                    <mat-checkbox formControlName="isonline">
                        <img src="../../../../assets/images/iconsForsvg/phone-call.svg" alt="">
                        {{'Online'|translate}}</mat-checkbox>
                </mat-list-item>
            </div>
            <div class="col-3">
                <mat-list-item>
                    <mat-checkbox formControlName="offline">
                        <img src="../../../../assets/images/iconsForsvg/handshake.svg" alt="">
                        {{'Off-line'|translate}}</mat-checkbox>
                </mat-list-item>
            </div>
        </div>


        <div class="row mt-2">
            <!-- users  -->
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'Users'|translate}} <span class="danger"></span></mat-label>
                <mat-select id="userGroupName" formControlName="resource" multiple>
                    <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'" [array]="sites$"
                        (filteredReturn)="filteredList1 =$event"></mat-select-filter>
                    <mat-option [value]="user.id" *ngFor="let user of filteredList1">
                        <span>
                            <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                                onError="this.src='assets/avatar.svg'">
                        </span> {{user.firstName + ' ' +user.lastName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- UserGroup  -->
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'User Group'|translate}}</mat-label>
                <mat-select placeholder="Select" multiple formControlName="selectGroup">
                    <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'"
                        [array]="filterforuserGrouplist"
                        (filteredReturn)="filterforuserGrouplist =$event"></mat-select-filter>
                    <mat-option [value]="user.id" *ngFor="let user of filterforuserGrouplist">
                        <mat-icon [ngStyle]="{'color':(user.dynamicGroupId ==null)?'red':'blue'}"
                            class="m-r">group</mat-icon>{{user.userGroupName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row w-100">
            <!-- contact  -->
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'Contacts'|translate}}</mat-label>
                <mat-select placeholder="Select" multiple formControlName="contactids">
                    <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'"
                        [array]="filterforcontactlist"
                        (filteredReturn)="filterforcontactlist=$event"></mat-select-filter>
                    <mat-option [value]="user.id" *ngFor="let user of filterforcontactlist">
                        <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                            [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                            onError="this.src='assets/avatar.svg'">
                        {{user.firstName +' '+user.lastName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- contact Group  -->
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'Contact Groups'|translate}}</mat-label>
                <mat-select placeholder="Select" multiple formControlName="selectGroup">
                    <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'"
                        [array]="filterforcontactGrouplist"
                        (filteredReturn)="filterforcontactGrouplist =$event"></mat-select-filter>
                    <mat-option [value]="user.id" *ngFor="let user of filterforcontactGrouplist">
                        <mat-icon [ngStyle]="{'color':(user.dynamicGroupId ==null)?'red':'blue'}"
                            class="m-r">group</mat-icon>{{user.contactGroupName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <div class="row w-100">
            <!-- sites  -->
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'Sites'|translate}}</mat-label>
                <mat-select placeholder="Sites" multiple id="SiteId" formControlName="SiteId">
                    <mat-select-filter [placeholder]="'Filter'" [displayMember]="'companyName'" [array]="sites$"
                        (filteredReturn)="filteredList3 =$event"></mat-select-filter>
                    <mat-option *ngFor="let site of filteredList3" [value]="site.id">
                        <img src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">{{site.companyName|titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- siteGroup  -->
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{"Site Groups"|translate}}</mat-label>
                <mat-select placeholder="Select" multiple formControlName="selectGroup">
                    <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'"
                        [array]="filterforsiteGrouplist"
                        (filteredReturn)="filterforsiteGrouplist =$event"></mat-select-filter>
                    <mat-option [value]="site.id" *ngFor="let site of filterforsiteGrouplist">
                        <mat-icon [ngStyle]="{'color':(site.dynamicGroupId ==null)?'red':'green'}"
                            class="m-r">group</mat-icon>{{site.siteGroupName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row w-100">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'Categories'|translate}}</mat-label>
                <mat-select multiple formControlName="categoryId">
                    <mat-select-filter [placeholder]="'Filter'" [displayMember]="'categoryName'" [array]="categories"
                        (filteredReturn)="categories =$event"></mat-select-filter>
                    <mat-option *ngFor="let opt of categories" [value]="opt.id">{{opt.categoryName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="row w-100">
            <mat-form-field appearance="fill" class="example-chip-list">
                <mat-label>{{'keyword'|translate}}</mat-label><br>
                <mat-chip-list #chipGridforkeyword aria-label="Fruit selection" style="margin-top: 10px !important;">
                    <mat-chip-row style="margin-top:5px !important; margin-bottom:6px !important;"
                        *ngFor="let keyword of keywordlist">
                        <button style="border: none; border-radius:20px; background-color: rgb(228, 223, 223);">
                            <span style="position: relative; left: 3px;">{{keyword}}</span>
                            <mat-icon
                                style="cursor: pointer; position: relative; top:2px;left: 7px; font-size: 20px; color: rgb(158, 154, 154);"
                                (click)="remove(keyword)">cancel</mat-icon></button>
                    </mat-chip-row>
                </mat-chip-list>
                <input placeholder="keyword" [matChipInputFor]="chipGridforkeyword"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)" />
            </mat-form-field>
        </div>

    </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="my-1">
        <div class="row mt-2 w-100 justify-content-center">
            <div class="col-4">
                <button mat-raised-button mat-dialog-close  class="W-blue" >{{"Cancel"|translate}}</button>
            </div>
            <div class="col-4">
                <button mat-raised-button [disabled]="!exportPlanningForm.valid" (click)="download()"
                class="b-blue">{{'Download'|translate}}</button>            </div>
        </div>
    </mat-dialog-actions>
