import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimeManagementService } from 'src/app/core/services/time-management.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnInit {

  ProjectForm: FormGroup;
  public color1: string = '#f9d797';
  public color2: string = '#1e81b0';
  formattedDate: string;
  timeEntryForm: FormGroup;
  hourEntryForm: FormGroup
  Projects: Object;
  FinalData: any[];
  ConfrimForm: FormGroup
  CancelForm: FormGroup
  currentMode: number;
  currentTime =  new Date ()

  constructor(
    private fb: FormBuilder, 
    private dialogRef: MatDialogRef<CreateProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    public snackBar: MatSnackBar,
    private datepipe: DatePipe
  ) {

    this.ProjectForm = this.fb.group({
      id: [null],
      ProjectName: ['', Validators.required],
      color: ['', Validators.required],
      fontColor: ['', Validators.required],
    });

    this.timeEntryForm = this.fb.group({
      Conditions: this.fb.array([this.newFile()]) // Initialize with one entry
    });

    this.hourEntryForm = this.fb.group({
      Conditions: this.fb.array([this.newFile1()]) // Initialize with one entry
    });

    this.ConfrimForm = this.fb.group({
      project: ['', Validators.required],
      comment: [''],

    });
    this.CancelForm = this.fb.group({
      comment: [''],
    });

   }

   Addcondition(): void {
    this.Conditions().push(this.newFile());
  }
  RemoveCondition(i: number): void {
    this.Conditions().removeAt(i);
  }

  Conditions(): FormArray {
    return this.timeEntryForm.get('Conditions') as FormArray;
  }

  newFile(): FormGroup {
    return this.fb.group({
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      project: ['', Validators.required],
      comment: ['']
    });
  }

  
  Addcondition1(): void {
    this.Conditions().push(this.newFile1());
  }
  RemoveCondition1(i: number): void {
    this.Conditions().removeAt(i);
  }

  Conditions1(): FormArray {
    return this.hourEntryForm.get('Conditions') as FormArray;
  }

  newFile1(): FormGroup {
    return this.fb.group({
      hours: ['', Validators.required],
      project: ['', Validators.required],
      comment: ['']
    });
  }


  ngOnInit(): void {

    if(this.data.type == 'Edit') {
      this.updateProject()
    }

    const today = new Date();

    const options:any = { weekday: 'long', month: 'short', day: 'numeric' };
    
     this.formattedDate = today.toLocaleDateString('en-US', options);

     this.getProjects()
  }

  getProjects(){
    this.injector.get(TimeManagementService).getAllProjects().subscribe(data => {
      this.Projects = data
    })
  }

  
  AddNewProject(){
    this.dialogRef.close(this.ProjectForm.value)
  }

  updateProject(){
    this.ProjectForm.get('ProjectName').setValue(this.data.data.projectName)
    this.ProjectForm.get('color').setValue(this.data.data.color)
    this.ProjectForm.get('fontColor').setValue(this.data.data.fontColor)
    this.ProjectForm.get('id').setValue(this.data.data.id)
  }

  addEntry(data:any) {
    this.currentMode = data == 'ClockInClockOut' ? 1 : data == 'StartEndTime' ? 2 : 3
    if(data == 'StartEndTime') {
      const conditionsArray = this.Conditions().value; 
      const timeEntries = conditionsArray.map(condition => {
        const startTime = this.getCurrentDateTimeWithFormattedTime(condition.startTime);  // e.g., "2024-10-25T12:06:00.000Z"
        const endTime = this.getCurrentDateTimeWithFormattedTime(condition.endTime);

        const currentDate: Date = new Date();
        const formattedStartTime: string =
          this.datepipe.transform(currentDate, 'yyyy-MM-dd') +
          'T' +
          currentDate.toTimeString().split(' ')[0];

        return {
          startTime: startTime,
          endTime: endTime,
          startComments: condition.comment,
          duration: this.calculateDuration(startTime, endTime),
          projectId: condition.project,
          mode: this.currentMode,
          created: formattedStartTime
      };
      });
      this.FinalData = [...timeEntries, ];
    } else if (data == 'HoursPerDay') {
      const hourConditionsArray = this.Conditions1().value;
      const hourEntries = hourConditionsArray.map(condition => {

        const currentDate: Date = new Date();
          const formattedStartTime: string =
            this.datepipe.transform(currentDate, 'yyyy-MM-dd') +
            'T' +
            currentDate.toTimeString().split(' ')[0];

            const startTime = new Date(formattedStartTime);

         const endTime = new Date(startTime.getTime() + condition.hours * 60 * 60 * 1000);

         const formattedEndTime: string =
         this.datepipe.transform(endTime, 'yyyy-MM-dd') +
         'T' +
         endTime.toTimeString().split(' ')[0];

        return {
            "startComments": condition.comment,
            "isHourEntry": true,
            "duration": this.formatHours(condition.hours),
            "projectId": condition.project,
            "mode": this.currentMode,
            "startTime": formattedStartTime,
            "endTime": formattedEndTime,
            "created": formattedStartTime
          };
      });
      this.FinalData = [...hourEntries];
    }

    this.injector.get(TimeManagementService).CreateEntry(this.FinalData).subscribe((result:any) => {
      if(result) {
        this.snackBar.open(result.response[0].message,'Undo', { duration: 2000 });
        this.dialogRef.close(true)
      }
    });
  }

  formatHours(hours: number): string {
    const formattedHours = hours.toString().padStart(2, '0');
    return `${formattedHours}:00:00`;  // Format as HH:00:00
  }
  

  calculateDuration(startTime, endTime) {
    const start = new Date(startTime);
    const end = new Date(endTime);

    const diffMs = end.getTime() - start.getTime();  // Difference in milliseconds
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));  // Convert milliseconds to hours
    const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));  // Remaining minutes

    const hours = diffHours.toString().padStart(2, '0');
    const minutes = diffMinutes.toString().padStart(2, '0');

    return `${hours}:${minutes}:00`;  // Return duration as HH:mm:ss with seconds set to 00
}

  formatTime(time) {
    const formattedSeconds = '00'; // You can change this for dynamic seconds
    return `${time}:${formattedSeconds}`;
}

getCurrentDateTimeWithFormattedTime(time) {
  const currentDate = new Date().toISOString().split('T')[0]; // Gets the date part only in YYYY-MM-DD format
  return `${currentDate}T${this.formatTime(time)}.000Z`;
}
  
  public onEventLog(event: string, data: any): void {
    console.log(event, data);
    this.ProjectForm.get('color')?.setValue(data);
  }
  public onEventLog1(event: string, data: any): void {
    console.log(event, data);
    this.ProjectForm.get('fontColor')?.setValue(data);
  } 

  closeDialog(){
    this.dialogRef.close(true)
  }

  AddComment(){
    if(this.data.type == 'ClockIn') {
      this.dialogRef.close(this.CancelForm.value)
    } else {
      this.dialogRef.close(this.ConfrimForm.value)
    }
  }

}
