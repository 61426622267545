<div class="d-flex flex-row align-items-center modal-header">
    <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
      <span> List Of Accounts</span>
    </h2>
    <mat-icon  (click)="addASynchronization()">add_box</mat-icon>
  </div>
  <mat-dialog-content class="mr-0 ml-0" style="min-height: 300px !important;">
    <div class="row cardbody cardrowstyle" id="strategy">
      <div class="col-12  col-sm-12 col-md-6 col-lg-4 col-xl-4" *ngFor="let r of AllSynchronizationData; let i =index">
        <mat-card (click)="gettingonAsstoken(r)" class="cardsize">
          <mat-card-header style="width: 100%;">
            <mat-card-title style="width: 100%;">
              <div class="row w-100">
                <div class="col-2">
                  <img style="width: 30px;height: 30px;border-radius:50%;float: right;"  onError="this.src='assets/avatar.svg'"
                  [src]="r?.profileLink ? r?.profileLink:'assets/avatar.svg'" />
                </div>
                <div class="col-6 p-0 m-0"><p class="mt-2 f-weight">{{r.name | titlecase }}</p></div>
                <div class="col-4">
                    <div style="display: flex;">
                      <div>
                        <mat-slide-toggle [(ngModel)]="r.isActive" style="float: left;" (change)="Updateaccountstatus($event,r)" ></mat-slide-toggle>
                        </div>
                        <div>
                      <mat-icon style="margin-left: 10px;" (click)="deleteAccount(r,'Deletesuycaccount')" class="icon-options">delete</mat-icon>
                        </div>
                    </div>
                </div>
              </div>              
              <mat-divider class="divider"></mat-divider>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="row w-100">
              <div class="col-10">
                <p style="color: gray;font-weight: 900;"> <b>{{r.email | titlecase }}</b> </p>
              </div>
              <div class="col-2">
                  <mat-icon class="icon-options" [matMenuTriggerFor]="moreMenu">more_vert</mat-icon>
              <mat-menu #moreMenu="matMenu" class="eventlist">
                  <ng-template matMenuContent>
                      <div class="fw-600" mat-menu-item *ngFor="let x of r?.accountDtls">
                        <div style="display: flex;" class="displayflex">
                          <div>
                              <img *ngIf="x.applicationName==='GoogleDrive'" style="width:25px;height:25px;border-radius:50%;object-fit: contain;float: right;" src="../../../../assets/notecreateicons/GoogleDrive.png" alt="GoogleDrive">
                              <img *ngIf="x.applicationName==='OneDrive'" style="width:25px;height:25px;border-radius:50%;object-fit: contain;float: right;" src="../../../../assets/notecreateicons/OneDrive.svg" alt="GoogleDrive">
                              <img *ngIf="x.applicationName==='Gmail'" style="width:25px;height:25px;border-radius:50%;object-fit: contain;float: right;" src="../../../../assets/notecreateicons/Gmail.svg" alt="GoogleDrive">
                              <img *ngIf="x.applicationName==='Outlook'" style="width:25px;height:25px;border-radius:50%;object-fit: contain;float: right;" src="../../../../assets/notecreateicons/Outlook.svg" alt="GoogleDrive">
                              <img *ngIf="x.applicationName==='GoogleCalendar'" style="width:25px;height:25px;border-radius:50%;object-fit: contain;float: right;" src="../../../../assets/notecreateicons/googlecalendar.svg" alt="GoogleCalendar">
                              <img *ngIf="x.applicationName==='OutlookCalendar'" style="width:25px;height:25px;border-radius:50%;object-fit: contain;float: right;" src="../../../../assets/notecreateicons/outlookcalendar.svg" alt="outlookcalendar">
                          </div>
                          <div>
                            <mat-slide-toggle (change)="Updateaccountservice($event,x)"  [(ngModel)]="x.isActive" ></mat-slide-toggle>
                          </div>
                          <!-- <div>
                            <mat-icon (click)="deleteAccount(x,'Deletesuycaccountservice')" class="icon-options">delete</mat-icon>
                           </div> -->
                        </div>
                      </div>
                  </ng-template>
              </mat-menu>
              </div>
            </div>
            <div style="display: flex;" class="displayflex">
              <div *ngFor="let x of r?.accountDtls">
                <img [ngClass]="{'disabled': !x.isActive}" *ngIf="x.applicationName==='GoogleDrive'" style="width:30px;height:30px;border-radius:50%;object-fit: contain;float: right;" src="../../../../assets/notecreateicons/GoogleDrive.png" alt="GoogleDrive">
                <img [ngClass]="{'disabled': !x.isActive}" *ngIf="x.applicationName==='OneDrive'" style="width:30px;height:30px;border-radius:50%;object-fit: contain;float: right;" src="../../../../assets/notecreateicons/OneDrive.svg" alt="GoogleDrive">
                <img [ngClass]="{'disabled': !x.isActive}" *ngIf="x.applicationName==='Gmail'" style="width:30px;height:30px;border-radius:50%;object-fit: contain;float: right;" src="../../../../assets/notecreateicons/Gmail.svg" alt="GoogleDrive">
                <img [ngClass]="{'disabled': !x.isActive}" *ngIf="x.applicationName==='Outlook'" style="width:30px;height:30px;border-radius:50%;object-fit: contain;float: right;" src="../../../../assets/notecreateicons/Outlook.svg" alt="GoogleDrive">
                <img [ngClass]="{'disabled': !x.isActive}" *ngIf="x.applicationName==='GoogleCalendar'" style="width:30px;height:30px;border-radius:50%;object-fit: contain;float: right;" src="../../../../assets/notecreateicons/googlecalendar.svg" alt="GoogleCalendar">
                <img [ngClass]="{'disabled': !x.isActive}" *ngIf="x.applicationName==='OutlookCalendar'" style="width:30px;height:30px;border-radius:50%;object-fit: contain;float: right;" src="../../../../assets/notecreateicons/outlookcalendar.svg" alt="outlookcalendar">
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  
    <!-- ---------------------  -->
    <div class="overlay" *ngIf="spinner">
      <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
      </mat-progress-spinner>
    </div>
  </mat-dialog-content>