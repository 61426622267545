import { Component, Inject, OnInit } from '@angular/core';
import { SyncOptionsListComponent } from '../sync-options-list/sync-options-list.component';
import { GoogleApiService } from 'src/app/core/services/SYNC/google-api.service';
import { CalendarService } from '../../services/calendar.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PlanningSynchronisationComponent } from 'src/app/pages/calendar/planning-synchronisation/planning-synchronisation.component';
import { DeleteCommomComponent } from 'src/app/pages/commonForAll/delete-commom/delete-commom.component';

@Component({
  selector: 'app-synchronization-data',
  templateUrl: './synchronization-data.component.html',
  styleUrls: ['./synchronization-data.component.scss']
})
export class SynchronizationDataComponent implements OnInit {
  isChecked = true
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  spinner = false
  AllSynchronizationData: any = []
  screenType = ''
  ActionName = ''
  ApplicationIds = '1,2,3,4'
  google=true
  outlook=true
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private calendarService: CalendarService,
    private goole: GoogleApiService,
    private dialog: MatDialog,
    private matDialogRef: MatDialogRef<SynchronizationDataComponent>,
  ) { }

  ngOnInit(): void {
    console.log(this.data)
    this.screenType = this.data.ScreenType
    this.ActionName = this.data?.ActionName
    if (this.data?.ActionName == 'GoogleDrive' || this.data?.ActionName == 'Gmail' || this.data?.ActionName == 'GoogleCalendar') {
      this.google=true
      this.outlook=false
    } else if (this.data?.ActionName == 'OneDrive' || this.data?.ActionName == 'Outlook' || this.data?.ActionName == 'OutlookCalendar') {
      this.google=false
      this.outlook=true
    }
    this.getAllSyncAccDetais()
    
  }



  getAllSyncAccDetais() {
    this.goole.GetSyncAccDetais(this.google,this.outlook).subscribe((result) => {
      if (result) {
        this.AllSynchronizationData = result
        console.log(result)
      }
    })
  }



  deleteAccount(data:any, type:any) {
    const deleteorder = this.dialog.open(DeleteCommomComponent, { data: { data, type: type } });
    deleteorder.afterClosed().subscribe((result: any) => {
      if (result)
        this.getAllSyncAccDetais()
    })
  }

  Updateaccountstatus(event,data){
    console.log(event)
    console.log(data)
    this.goole.DeactivateSyncAccount(data.accUserId,event.checked).subscribe(result=>{
      if(result){
       console.log(result)
       this.getAllSyncAccDetais()
      }
    },error=>{
      if(error.status==200){
       this.getAllSyncAccDetais()

      }
    })
  }

  Updateaccountservice(event,data){
    console.log(event)
    console.log(data)
    this.goole.DeActivateSyncService(data.id,event.checked).subscribe(result=>{
      if(result){
       console.log(result)
       this.getAllSyncAccDetais()
      }
    },error=>{
      if(error.status==200){
       this.getAllSyncAccDetais()

      }
    })
  }

  


  SynchronisationComponent1() {
    this.dialog.open(SyncOptionsListComponent, {
      data:{screentype:'twooptions'},
      autoFocus: false,
      disableClose: true,
    });
  } 

  addASynchronization() {
    this.SynchronisationComponent1()
  }



  SynchronisationComponent2() {
    this.dialog.open(PlanningSynchronisationComponent, {
      width: '500px',
    })
  }




  gettingonAsstoken(data:any) {
    console.log(data)
    if(this.screenType=='PlanningPage'||this.screenType=='FilesAndNotesPage'){
      let accountDt:any=null
      data.accountDtls.forEach(element => {
        console.log(element.applicationName)
        console.log(this.data?.ActionName)
        if(element.applicationName==this.data?.ActionName){
          accountDt=element
        }
      });
      console.log(accountDt)
      if(accountDt!=null){
        if (this.data?.ActionName == 'GoogleDrive' || this.data?.ActionName == 'Gmail' || this.data?.ActionName == 'GoogleCalendar') {
          this.goole.GetGoogleAccessTokenByRF(accountDt.id).subscribe((result:any) => {
            if (result) {
              result.applicationName=data.applicationName
              if(this.screenType=='PlanningPage'&&this.data?.ActionName == 'GoogleCalendar'){
                this.matDialogRef.close(accountDt.id)
              }else {
                this.matDialogRef.close(result)
              }
            }
          })
        } else if (this.data?.ActionName == 'OneDrive' || this.data?.ActionName == 'Outlook' || this.data?.ActionName == 'OutlookCalendar') {
          this.goole.GetMicrosoftAccessTokenByRF(accountDt.id).subscribe((result:any) => {
            if (result) {
              result.applicationName=data.applicationName
              if(this.screenType=='PlanningPage'&&this.data?.ActionName == 'OutlookCalendar'){
                this.matDialogRef.close(accountDt.id)
              }else if(this.screenType=='FilesAndNotesPage'){
                this.matDialogRef.close(result)
              }
            }
          })
        }
      }

    } 
  }

}
