<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title *ngIf="screentype=='Composemail'" class="m-0 dialog-title text-center w-100">
        New Message 
    </h2>  
    <h2 mat-dialog-title *ngIf="screentype=='Replyemail'||screentype=='ReplyAllemail'" class="m-0 dialog-title text-center w-100">
    Reply Message 
</h2>
<h2 mat-dialog-title *ngIf="screentype=='groupUserDetails'" class="m-0 dialog-title text-center w-100">
    Email Read Receipt
</h2>  
<h2 mat-dialog-title *ngIf="screentype=='EventDetails'" class="m-0 dialog-title text-center w-100">
    Event Created
</h2> 
<h2 mat-dialog-title *ngIf="screentype=='TaskDetails'" class="m-0 dialog-title text-center w-100">
    Task Created
</h2>  
<h2 mat-dialog-title *ngIf="data.type =='Send Email'" class="m-0 dialog-title text-center w-100">
    Send Email
</h2> 
<h2 mat-dialog-title *ngIf="screentype =='EditSendEmailAutomation'" class="m-0 dialog-title text-center w-100">
    Edit Send Email
</h2> 
<h2 mat-dialog-title *ngIf="screentype=='Forwardemail'"class="m-0 dialog-title text-center w-100">Forward Message </h2>
<h2 mat-dialog-title *ngIf="screentype=='SelectTO'"class="m-0 dialog-title text-center w-100">Select Your TO</h2>
<h2 mat-dialog-title *ngIf="screentype=='SelectCC'"class="m-0 dialog-title text-center w-100">Select Your CC </h2>
<h2 mat-dialog-title *ngIf="screentype=='SelectBCC'"class="m-0 dialog-title text-center w-100">Select Your BCC </h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div> 

<mat-dialog-content class="mr-0 ml-0">
    <!-- Composemail  -->
    <ng-container *ngIf="screentype=='Composemail' || screentype == 'SendEmail' || screentype == 'EditSendEmailAutomation'">
        <form [formGroup]="EmailSendForm">
        <div class="row w-100 mt-2" *ngIf="screentype!='Composemail'">
           
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Mails</mat-label>
                    <mat-select (selectionChange)="selectChange($event)" formControlName="AccountId">
                        <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'" 
                        [array]="mailids" (filteredReturn)="mailids =$event"></mat-select-filter>
                        <mat-option [value]="x" *ngFor="let x of mailids" >
                            <span style="margin-left: 5px;">{{x?.email}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>   
        </div>
        <div class="row w-100">
            <!-- To  -->
            <div style="display: flex;width: 100%;">
                <div style="width: 5%;"><span style="width: 10%;position: relative;top: 22px;font-size: 14px;color: gray;font-weight: 900;cursor: pointer;" (click)="showcontacts('TO')">To</span></div>
                 <div style="width: 85%;">
                    <mat-form-field class="example-chip-list" style="width: 100% !important;">
                        <mat-chip-list #chipGridforkeyword1 aria-label="Fruit selection" class="keywordlist">
                            <mat-chip-row class="keywordrow" *ngFor="let keyword of keywordlistTO">
                                <button class="keywordbutton">
                                    <span class="keywordpostion">{{keyword.Email|titlecase}}</span>
                                    <mat-icon class="keywordcancelicon" (click)="remove(keyword,'TO')">cancel</mat-icon>
                                </button>
                            </mat-chip-row> 
                        </mat-chip-list>
                        <input [matChipInputFor]="chipGridforkeyword1" [matChipInputSeparatorKeyCodes]="separatorKeysCodes1"
                            (matChipInputTokenEnd)="add($event,'TO')" (click)="openPopup('ToMail')"/>
                    </mat-form-field>
                 </div>
                 <div style="width: 5%;" *ngIf="!showCC"><span class="showccoption" (click)="showCCoption()">CC</span></div>
                 <div style="width: 5%;" *ngIf="!showBCC"><span class="showBCCoption" (click)="showBCCoption()">BCC</span></div>
            </div>
        </div>

        <div class="row">
            <!-- CC  -->
             <ng-container *ngIf="showCC">
                <div class="row w-100">
                    <div style="display: flex;width: 100%;">
                        <div style="width: 5%;"><span style="width: 10%;position: relative;top: 22px;font-size: 14px;color: gray;font-weight: 900;cursor: pointer;" (click)="showcontacts('CC')">Cc</span></div>
                         <div style="width: 95%;">
                            <mat-form-field class="example-chip-list" style="width: 100% !important;">
                                <mat-chip-list #chipGridforkeyword2 aria-label="Fruit selection" class="keywordlist">
                                    <mat-chip-row class="keywordrow" *ngFor="let keyword of keywordlistCC">
                                        <button class="keywordbutton">
                                            <span class="keywordpostion">{{keyword.Email|titlecase}}</span>
                                            <mat-icon class="keywordcancelicon" (click)="remove(keyword,'CC')">cancel</mat-icon>
                                        </button>
                                    </mat-chip-row> 
                                </mat-chip-list>
                                <input [matChipInputFor]="chipGridforkeyword2" [matChipInputSeparatorKeyCodes]="separatorKeysCodes2"
                                    (matChipInputTokenEnd)="add($event,'CC')" (click)="openPopup('ccMail')"/>
                            </mat-form-field>
                         </div>
                    </div>
                </div>
             </ng-container>
        </div>

        <div class="row">
            <!-- BCC  -->
             <ng-container *ngIf="showBCC">
                <div class="row w-100">
                    <div style="display: flex;width: 100%;">
                        <div style="width: 5%;"><span style="width: 10%;position: relative;top: 22px;font-size: 14px;color: gray;font-weight: 900;cursor: pointer;" (click)="showcontacts('BCC')">BCc</span></div>
                         <div style="width: 95%;">
                            <mat-form-field class="example-chip-list" style="width: 100% !important;">
                                <mat-chip-list #chipGridforkeyword3 aria-label="Fruit selection" class="keywordlist">
                                    <mat-chip-row class="keywordrow" *ngFor="let keyword of keywordlistBCC">
                                        <button class="keywordbutton">
                                            <span class="keywordpostion">{{keyword.Email|titlecase}}</span>
                                            <mat-icon class="keywordcancelicon" (click)="remove(keyword,'BCC')">cancel</mat-icon>
                                        </button>
                                    </mat-chip-row> 
                                </mat-chip-list>
                                <input [matChipInputFor]="chipGridforkeyword3" [matChipInputSeparatorKeyCodes]="separatorKeysCodes2"
                                    (matChipInputTokenEnd)="add($event,'BCC')" (click)="openPopup('ccMail')"/>
                            </mat-form-field>
                         </div>
                    </div>
                </div>
             </ng-container>
        </div>
          <!-- Do you want to add Entitys -->
          <!-- <div class="row mt-2 mb-2">
            <mat-checkbox  formControlName="HasEntitys" class="example-margin">Do you want to add Users and Contacts .. ?</mat-checkbox>
        </div>
        <ng-container *ngIf="getHasEntitysstatus()">
            <div class="row">
                <div class="col-4">
                <mat-checkbox formControlName="HasUsers" class="example-margin">Users</mat-checkbox>

                </div>
                <div class="col-4">
                <mat-checkbox formControlName="HasContacts" class="example-margin">Contacts</mat-checkbox>
                      
                </div>
            </div>

            <ng-container *ngIf="getHasEntitysuser()">
                <div class="row justify-content-end">
                    <div class="col-12">
                        <mat-tab-group class="w-100" >
                            <mat-tab label="Users">
                                <mat-form-field appearance="fill"
                                    class="w-100">
                                    <mat-label>Users</mat-label>
                                    <mat-select placeholder="Users" multiple
                                        formControlName="UserId">
                                        <mat-select-filter
                                            [placeholder]="'Filter'"
                                            [displayMember]="'firstName'"
                                            [array]="users$"
                                            (filteredReturn)="filteredList1 =$event"></mat-select-filter>
                                        <mat-option [value]="user.id"
                                            *ngFor="let user of filteredList1  ">
                                            <span> <img
                                                    style="border-radius: 50%;"
                                                    width="30" height="30"
                                                    onError="this.src='assets/avatar.svg'"
                                                    [src]="user.profilePicture!= null ? user.profilePicture : 'assets/avatar.svg'"></span>
                                            <span>
                                                {{user.firstName|titlecase}}
                                                {{user.lastName|titlecase}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-tab>
                            <mat-tab label="Add group member into user">
                                <mat-form-field appearance="fill"
                                    class="w-100">
                                    <mat-label>Groups</mat-label>
                                    <mat-select multiple>
                                        <mat-select-filter
                                            [placeholder]="'Filter'"
                                            [displayMember]="'groupName'"
                                            [array]="userGroups$"
                                            (filteredReturn)="filteredList4 =$event"></mat-select-filter>
                                        <mat-option [value]="userGroup.id"
                                            *ngFor="let userGroup of filteredList4"
                                            (click)="GroupToSingleconvert(userGroup,'UserContent','Users')">
                                            <span
                                                *ngIf="userGroup.dynamicGroupId ==null">
                                                <img
                                                    style="border-radius: 50%;"
                                                    width="30" height="30"
                                                    src="../../../../../assets/images/iconsForsvg/groupiconRed.svg">
                                            </span>
                                            <span
                                                *ngIf="userGroup.dynamicGroupId !=null">
                                                <img
                                                    style="border-radius: 50%;"
                                                    width="30" height="30"
                                                    src="../../../../../assets/images/iconsForsvg/groupiconblue.svg">
                                            </span>
                                            {{userGroup.groupName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </ng-container>
                <ng-container *ngIf="getHasEntityscontact()">
                    <div class="row justify-content-end">
                        <div class="col-12">
                            <mat-tab-group class="w-100">
                                <mat-tab label="Contacts">
                                    <mat-form-field appearance="fill"
                                        class="w-100">
                                        <mat-label>Contacts</mat-label>
                                        <mat-select placeholder="Contact"
                                            multiple
                                            formControlName="ContactId">
                                            <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'"
                                                [array]="contacts$"
                                                (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                                            <mat-option [value]="contact.id"
                                                *ngFor="let contact of filteredList2 ">
                                                <img style="border-radius: 50%;"
                                                    width="30" height="30"
                                                    onError="this.src='assets/avatar.svg'"
                                                    [src]="contact.profilePicture!= null ? contact.profilePicture : 'assets/avatar.svg'">
                                                {{contact.firstName|titlecase}}
                                                {{contact.lastName|titlecase}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-tab>
                                <mat-tab label="Add group member into Contact">
                                    <mat-form-field appearance="fill"
                                        class="w-100">
                                        <mat-label>Groups</mat-label>
                                        <mat-select multiple>
                                            <mat-select-filter [placeholder]="'Filter'" [displayMember]="'groupName'"
                                                [array]="contactGroups$"
                                                (filteredReturn)="filteredList5 =$event">
                                            </mat-select-filter>
                                            <mat-option
                                                [value]="contactGroup.id"
                                                *ngFor="let contactGroup of filteredList5 "
                                                (click)="GroupToSingleconvert(contactGroup,'ContactContent','Contacts')">
                                                <span
                                                    *ngIf="contactGroup.dynamicGroupId ==null">
                                                    <img
                                                        style="border-radius: 50%;"
                                                        width="30" height="30"
                                                        src="../../../../../assets/images/iconsForsvg/groupiconRed.svg">
                                                </span>
                                                <span
                                                    *ngIf="contactGroup.dynamicGroupId !=null">
                                                    <img
                                                        style="border-radius: 50%;"
                                                        width="30" height="30"
                                                        src="../../../../../assets/images/iconsForsvg/groupiconblue.svg">
                                                </span>
                                                {{contactGroup.groupName|titlecase}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                </ng-container>


        </ng-container> -->

        <div class="row mt-2 mb-2">
            <mat-form-field class="w-100 custom-form-field">
                <mat-label>Subject</mat-label>
                <input matInput formControlName="subject" (click)="openPopup('subject')">
            </mat-form-field>
        </div>
        <div class="row" *ngIf="screentype == 'Composemail'">
            <mat-checkbox  formControlName="Hastracking" >
                <img src="../../../../assets/images/iconsForsvg/tracking.svg" >
                <span style="margin-left: 5px;">Enable  tracking</span>
            </mat-checkbox>
        </div>
        <div class="row">
            <mat-checkbox  formControlName="HasImportTemplate" >
                <img src="../../../../assets/images/iconsForsvg/upload_file.svg" >
                <span style="margin-left: 5px;">Choose Template</span>
            </mat-checkbox>
        </div>
        <div class="row" *ngIf="screentype == 'Composemail'">
            <mat-checkbox  formControlName="HasSchedule" >
                <img src="../../../../assets/images/iconsForsvg/schedule_clock.svg" >
                <span style="margin-left: 5px;">Add Schedule</span>
            </mat-checkbox>
        </div>
      <ng-container *ngIf="!getHasImportTemplate()">
        <div class="row mt-2 mb-2">
            <!-- body   -->
              <ckeditor formControlName="bodycontent"></ckeditor>
              <!-- body end  -->
        </div>
      </ng-container>
        </form>
        <div class="mb-4 mt-2" style="display: flex;justify-content: space-around;">
            <div  *ngIf="getHasImportTemplate()" >
                <button mat-raised-button [matMenuTriggerFor]="beforeMenu" class="b-blue" style="border: none;" >Import a Template</button>

                <mat-menu #beforeMenu="matMenu" xPosition="before">
                    <button class="fw-600" mat-menu-item (click)="fileInput1.click()">
                        <img src="../../../../assets/images/iconsForsvg/gui-folder-open-svgrepo-com.svg" alt="">
                        <span style="margin-left: 5px;">Form Local</span>
                        <input #fileInput1 accept=".html" type="file"
                         (change)="handleFileInput($event)" style="display:none;" />
                    </button>
                    <button class="fw-600" mat-menu-item (click)="FromMediaLibrary()" >
                        <img src="../../../../assets/images/iconsForsvg/media-color-1-image-01-svgrepo-com.svg" alt="">
                        <span style="margin-left: 5px;">From Media Library</span>
                    </button>
                </mat-menu> 
                <ng-container *ngIf="chooseoptionFile">
                    <div class="row p-0 mt-2">
                        <div class="col-2 p-2"> 
                            <mat-icon [matMenuTriggerFor]="moreMenu" style="position: relative;top: -10px;right: 10px;" class="icon-options">more_vert</mat-icon>
                            <mat-menu #moreMenu="matMenu" class="eventlist">
                                <ng-template matMenuContent>
                                    <button class="fw-600" mat-menu-item (click)="ShowTemplateView()">
                                        <mat-icon class="icon-options">visibility</mat-icon>
                                        <span class="fw-600 color_black">{{'View'|translate}}</span>
                                    </button>
                                    <button class="fw-600" mat-menu-item (click)="deleteFile()">
                                        <mat-icon class="icon-options">delete</mat-icon>
                                        <span class="fw-600 color_black">{{'Delete'|translate}}</span>
                                    </button>
                                    </ng-template>
                                    </mat-menu>
                        </div>
                        <div class="col-10 p-0 m-0">{{fileToUpload}}</div>
                    </div>
                </ng-container>
            </div> 
            <div *ngIf="getHasSchedule()">
                <button mat-raised-button  class="b-blue" (click)="Schedule()" >Schedule</button>
                <ng-container *ngIf="Scheduleoption">
                    <div class="row justify-content-start mt-2">
                        <div class="col-2 p-2">
                            <mat-icon [matMenuTriggerFor]="moreMenu" style="position: relative;right: 10px;" class="icon-options">more_vert</mat-icon>
                            <mat-menu #moreMenu="matMenu" class="eventlist">
                                <ng-template matMenuContent>
                                    <button class="fw-600" mat-menu-item (click)="Schedule()">
                                    <mat-icon class="icon-options">edit</mat-icon>
                                    <span class="fw-600 color_black">{{'Edit'|translate}}</span>
                                </button>
                                    <button class="fw-600" mat-menu-item (click)="deleteSchedule()">
                                        <mat-icon class="icon-options">delete</mat-icon>
                                        <span class="fw-600 color_black">{{'Delete'|translate}}</span>
                                    </button>
                                    </ng-template>
                                    </mat-menu>
                        </div>
                        <div class="col-10 p-0">
                                <div><mat-icon>event</mat-icon>{{Scheduledata.date| date:'dd-MMM-yyyy'}}</div>
                                <div><mat-icon>timer</mat-icon>{{Scheduledata.time}}</div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="screentype == 'Composemail'">
                <button mat-raised-button  [ngClass]="getststus()? 'b-blue':'g-blue'"
                [disabled]="!getststus()" (click)="onSend()">Draft</button>
            </div>
            <div *ngIf="screentype == 'Composemail'">
                <button mat-raised-button  [ngClass]="getststus()? 'b-blue':'g-blue'"
                [disabled]="!getststus()" (click)="onSend()">Send</button>
            </div>
            <div *ngIf="screentype != 'Composemail'">
                <button *ngIf="screentype != 'EditSendEmailAutomation'" mat-raised-button  class="b-blue"  (click)="onSendEmail()">Create</button>
                <button *ngIf="screentype == 'EditSendEmailAutomation'" mat-raised-button  class="b-blue"  (click)="onSendEmail()">Update</button>
            </div>
        </div>
    </ng-container>

        <!-- Replyemail  --> 
        <ng-container *ngIf="screentype=='Replyemail'||screentype=='ReplyAllemail'">
            <form [formGroup]="EmailSendForm">
            <div class="row mt-2 mb-2">
                <mat-form-field class="w-100 custom-form-field">
                    <mat-label style="cursor: pointer !important;">To</mat-label>
                    <input matInput [readonly]="readonly" formControlName="to">
                </mat-form-field>
            </div>
            <div class="row mt-2 mb-2">
                    <!-- body   -->
                      <ckeditor formControlName="bodycontent"></ckeditor>
                      <!-- body end  -->
            </div>
        </form>
            <div class="row mb-4 justify-content-end">
                <div class="col-4">
                    <button mat-raised-button  class="b-blue"  (click)="onSend()">Send</button>
                </div>
            </div>
        </ng-container>

        <!-- Forwardemail  -->
    <ng-container *ngIf="screentype=='Forwardemail'">
        <form [formGroup]="EmailSendForm">
        <div class="row mt-2 mb-2">
            <mat-form-field class="w-100 custom-form-field">
                <mat-label>To</mat-label>
                <input matInput formControlName="to">
            </mat-form-field> 
        </div>
        </form>
        <div class="row mb-4 justify-content-end">
            <div class="col-4">
                <button mat-raised-button  class="b-blue"  (click)="onSend()">Send</button>
            </div>
        </div>
    </ng-container>

    <!-- SelectTO  -->
    <ng-container *ngIf="screentype=='SelectTO'||screentype=='SelectCC'||screentype=='SelectBCC'">
        <div class="row">
            <div class="row w-100 mt-2">
                <div class="col-9 col-sm-9  col-md-9 col-lg-8 col-xl-8">
                    <mat-form-field class="w-100">
                        <input matInput [(ngModel)]="singleSearchValue" placeholder="Search">
                            <mat-icon *ngIf="!showicon" matSuffix class="pointer searchicon"
                            (click)="applyFilter(singleSearchValue)">search</mat-icon>
                                <mat-icon *ngIf="showicon" matSuffix class="pointer searchicon"
                                 (click)="closesearch()">close</mat-icon>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <button mat-raised-button class="b-blue" (click)="applyformailinset()">{{"Insert"|translate}}</button>
                </div>
            </div>
        </div>
       <div class="row">
        <mat-tab-group class="w-100" mat-align-tabs="center" class="no-bg"  (selectedTabChange)="tabChange($event.index)">
            <!-- Users  -->
            <mat-tab label="Users" >
                <div class="row">
                    <div class="col-6 p-2" *ngFor="let x of users$;let i = index">
                      <div style="display: flex; align-items: center; margin-bottom: 10px;">
                        <!-- Checkbox -->
                        <div>
                          <mat-checkbox [checked]="x?.checked"     (change)="onCheckboxChange1(x)" class="example-margin"></mat-checkbox>
                        </div>
                        <div class="avatar"  style="cursor: pointer;">
                            <img *ngIf="x?.profilePicture && x?.profilePicture !== ''"
                            [src]="x.profilePicture"
                            onError="this.src='assets/avatar.svg'"
                            style="width: 35px; height: 35px; border-radius: 50%;">
                            
                       <span *ngIf="!x?.profilePicture || x?.profilePicture === ''"
                             [style.background]="getColor(i)"
                             style="color: white; padding: 10px; border-radius: 50%; width: 35px; height: 35px; display: inline-flex; align-items: center; justify-content: center; overflow: hidden; font-size: 12px;">
                         {{ getuserFLnames(x.firstName + ' ' + x.lastName) }}
                       </span>
                          </div>
                        <!-- User Name -->
                        <div style="margin-left: 10px;">
                            <p style="margin: 0 !important;padding: 5px !important;color: black;font-weight: 900;">{{ x.firstName | titlecase }} {{ x.lastName | titlecase }}</p>
                            <p style="margin: 0 !important;padding: 5px !important;color: gray;font-weight: 900;">{{ x.email}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  
            </mat-tab>
            <!-- User Groups  -->
            <mat-tab label="User Groups" >
                <div class="row">
                    <div class="col-6 p-2" *ngFor="let x of userGroups$;let i = index">
                        <div style="display: flex; align-items: center; margin-bottom: 10px;">
                            <!-- Checkbox -->
                            <div>
                              <mat-checkbox [checked]="x?.checked"  (change)="onCheckboxChange2(x)" class="example-margin"></mat-checkbox>
                            </div>
                              <div>
                                <span
                                *ngIf="x.dynamicGroupId ==null">
                                <img style="border-radius: 50%;" width="30" height="30"
                                 src="../../../../../assets/images/iconsForsvg/groupiconRed.svg">
                            </span>
                            <span
                                *ngIf="x.dynamicGroupId !=null">
                                <img style="border-radius: 50%;" width="30" height="30" 
                                src="../../../../../assets/images/iconsForsvg/groupiconblue.svg">
                            </span>
                            {{x.groupName|titlecase}}
                              </div>
                            </div>
                        </div>
                        </div>
             </mat-tab>
             <!-- Contacts  -->
            <mat-tab label="Contacts">
                <div class="row">
                    <div class="col-6 p-2" *ngFor="let x of contacts$;let i = index">
                        <div style="display: flex; align-items: center; margin-bottom: 10px;">
                            <!-- Checkbox -->
                            <div>
                              <mat-checkbox [checked]="x?.checked"  (change)="onCheckboxChange3(x)" class="example-margin"></mat-checkbox>
                            </div>
                            <div class="avatar"  style="cursor: pointer;">
                                <img *ngIf="x?.profilePicture!=''&&x?.profilePicture!=null"
                                     [src]="x.profilePicture"
                                     onError="this.src='assets/avatar.svg'"
                                     style="width: 35px; height: 35px; border-radius: 50%;">
                                     
                                <span *ngIf="x?.profilePicture==''||x?.profilePicture==null"
                                      [style.background]="getColor(i)"
                                      style="color: white; padding: 10px; border-radius: 50%; width: 35px; height: 35px; display: inline-flex; align-items: center; justify-content: center; overflow: hidden; font-size: 12px;">
                                      {{getuserFLnames(x.firstName + ' ' + x.lastName) }}
                                </span>
                              </div>
                            <!-- User Name -->
                            <div style="margin-left: 10px;">
                                <p style="margin: 0 !important;padding: 5px !important;color: black;font-weight: 900;">{{ x.firstName | titlecase }} {{ x.lastName | titlecase }}</p>
                                <p style="margin: 0 !important;padding: 5px !important;color: gray;font-weight: 900;">{{ x.email}}</p>
                            </div>
                          </div>
                    </div>
                </div>
            </mat-tab>
            <!-- Contact Groups  -->
            <mat-tab label="Contact Groups">
                <div class="row">
                    <div class="col-6 p-2" *ngFor="let x of contactGroups$;let i = index">
                        <div style="display: flex; align-items: center; margin-bottom: 10px;">
                            <!-- Checkbox -->
                            <div>
                              <mat-checkbox [checked]="x?.checked"  (change)="onCheckboxChange4(x)" class="example-margin"></mat-checkbox>
                            </div>
                              <div>
                                <span
                                *ngIf="x.dynamicGroupId ==null">
                                <img style="border-radius: 50%;" width="30" height="30"
                                 src="../../../../../assets/images/iconsForsvg/groupiconRed.svg">
                            </span>
                            <span
                                *ngIf="x.dynamicGroupId !=null">
                                <img style="border-radius: 50%;" width="30" height="30" 
                                src="../../../../../assets/images/iconsForsvg/groupiconblue.svg">
                            </span>
                            {{x.groupName|titlecase}}
                              </div>
                            </div>
                        </div>
                        </div>
           </mat-tab>
        </mat-tab-group>
       </div>
    </ng-container>


    <!-- show user Details  -->
    <ng-container *ngIf="screentype=='groupUserDetails'">
        <div class="row justify-content-center">
            <div class="col-2">
                <div>
                    <img  style="width: 40px;height: 40px;border-radius: 50%;object-fit: cover;"  *ngIf="user?.profile!=''&&user?.profile!=null" onError="src='assets/avatar.svg'" 
                    [src]="user?.profile ? user?.profile:'assets/avatar.svg'">
                    <div *ngIf="user?.profile==''||user?.profile==null"  class="profile_JG">{{getuserFLnames(user?.userName)}}</div>
                </div> 
            </div>
        </div>
        <div class="row mt-2 mb-2">
            <h4 style="text-align: center;width: 100%;font-weight: 900;">{{user?.userName}}</h4>
            <h4 style="text-align: center;width: 100%;">
                <span>
                    <img  src="../../../assets/images/iconsForsvg/done_all.svg" alt="">
                    </span>
                <span class="ml-3 fw-600 color_grey"> {{user?.viewdDateTime| date:'dd-MMM-yyyy H:mm'}}</span>
            </h4>
        </div>
    </ng-container>

    <!-- Event_Task_Details  -->
    <ng-container *ngIf="screentype=='TaskDetails'||screentype=='EventDetails'">
        <div class="row  mt-2 justify-content-center">
           <div class="col-6">
            <div style="display: flex;">
                <div>
                    <img class="sidemenuicons" (click)="openEvents()" *ngIf="screentype=='EventDetails'"  src="../../../assets/images/iconsForsvg/calendar.svg"  >
                    <img class="sidemenuicons" (click)="opentaskDialog()" *ngIf="screentype=='TaskDetails'"   src="../../../assets/images/iconsForsvg/task.svg"  >
                </div> 
                <div>
                    <p style="position: relative;top:8px;left: 5px;">{{eventtaskdetails?.title}}</p>
                </div>
            </div>
           </div>
        </div>
        
        <div class="row p-0 mt-2 justify-content-center">
            <div class="col-8 p-0">
               <div style="display: flex;">
                <div>
                    <p style="text-align: center;position: relative;top: 12px;right: 2px;color: black;font-weight: 900;">
                        Creator : 
                    </p>
                </div>
                <div>
                    <img  style="width: 40px;height: 40px;border-radius: 50%;object-fit: cover;"  *ngIf="eventtaskdetails?.profile!=''&&eventtaskdetails?.profile!=null" onError="src='assets/avatar.svg'" 
                    [src]="eventtaskdetails?.profile ? eventtaskdetails?.profile:'assets/avatar.svg'">
                    <div *ngIf="eventtaskdetails?.profile==''||eventtaskdetails?.profile==null"  class="profile_JG">{{getuserFLnames(eventtaskdetails?.firstName+''+eventtaskdetails?.lastName)}}</div>
                </div>
                <div>
                    <p style="text-align: center;    position: relative;top: 12px;left: 10px;">{{eventtaskdetails?.firstName+' '+eventtaskdetails?.lastName}}</p>
                </div>
               </div>
            </div>
        </div>

        <div class="row mt-2 justify-content-center">
            <div class="col-10">
                <h4 style="text-align: center;width: 100%;">
                    <span style="font-weight: 900;"> Created on : </span>
                    <span>
                        <mat-icon class="ml-3">transform</mat-icon>
                    </span>
                    <span class="ml-3 fw-600 color_grey"> {{eventtaskdetails?.convertedDateTime| date:'dd-MMM-yyyy H:mm'}}</span>
                </h4>
            </div>
        </div>
    </ng-container>


    
</mat-dialog-content>
