

<!-- --------  -->
<mat-sidenav-container [hasBackdrop]="mode === 'side' ? false : true">
    <mat-sidenav style="width: 250px !important;" #sideNav fixedInViewport [mode]="mode" [opened]="opened" >
        <div style="margin-top: 20px;margin: 5px;">
            <!-- mails  -->
            <div>
            <form [formGroup]="AccountForm"> 
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Mails</mat-label>
                    <mat-select multiple (selectionChange)="selectChange($event)" formControlName="AccountList">
                        <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'" 
                        [array]="mailids" (filteredReturn)="mailids =$event"></mat-select-filter>
                        <mat-option [value]="x" *ngFor="let x of mailids" >
                            <span *ngIf="!x?.isCollabrative"><img src="../../../assets/images/iconsForsvg/person.svg"></span>
                            <span *ngIf="x?.isCollabrative"><img src="../../../assets/images/iconsForsvg/group.svg"></span>
                            <span style="margin-left: 5px;">{{x?.email}}</span>
                            <span style="margin-left: 5px;"><img src="../../../assets/images/iconsForsvg/delete.svg" ></span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                </form>
                 
            </div>
            <!-- Compose  --> 
            <div style="margin-top:5px;">
                <button mat-raised-button (click)="Composemail()"  class="R-blue w-100">
                    <span><img  style="width: 25px;height: 25px;" src="../../../assets/images/iconsForsvg/new-mail.svg" ></span>
                    <span style="margin-left: 5px;">New Email</span>
                </button>
            </div>
            <div [ngClass]="siddemenu('Inbox') " (click)="sidemenuclick('Inbox')">
                <span>
                    <img class="sidemenuicons" src="../../../assets/images/iconsForsvg/inbox.svg">
                </span>
                <span class="lablestyle">Inbox</span>
                <span *ngIf="sidemenusection=='Inbox'" style="float: right;font-weight: 900;">{{mailLength}}</span>
            </div>
            <div [ngClass]="siddemenu('Sent') " (click)="sidemenuclick('Sent')">
                <span>
                    <img class="sidemenuicons" src="../../../assets/images/iconsForsvg/send.svg">
                </span>
                <span class="lablestyle">Sent</span>
                <span *ngIf="sidemenusection=='Sent'" style="float: right;font-weight: 900;">{{mailLength}}</span>

            </div>
            <div [ngClass]="siddemenu('Draft') " (click)="sidemenuclick('Draft')">
                <span><img class="sidemenuicons" src="../../../assets/images/iconsForsvg/draft.svg"     ></span>
                <span class="lablestyle">Draft</span>
                <span *ngIf="sidemenusection=='Draft'" style="float: right;font-weight: 900;">{{mailLength}}</span>
            </div>
            <div [ngClass]="siddemenu('Spam') " (click)="sidemenuclick('Spam')">
                <span><img class="sidemenuicons" src="../../../assets/images/iconsForsvg/inbox.svg"     ></span>
                <span class="lablestyle">Spam</span>
                <span *ngIf="sidemenusection=='Spam'" style="float: right;font-weight: 900;">{{mailLength}}</span>
            </div>
            <div [ngClass]="siddemenu('Trash') " (click)="sidemenuclick('Trash')">
                <span><img class="sidemenuicons" src="../../../assets/images/iconsForsvg/trash.svg"     ></span>
                <span class="lablestyle">Trash</span>
                <span *ngIf="sidemenusection=='Trash'" style="float: right;font-weight: 900;">{{mailLength}}</span>
            </div>
            <div [ngClass]="siddemenu('Blocked') " (click)="sidemenuclick('Blocked')">
                <span><img class="sidemenuicons" src="../../../assets/images/iconsForsvg/block.svg" ></span>
                <span class="lablestyle">Blocked</span>
                <span *ngIf="sidemenusection=='Blocked'" style="float: right;font-weight: 900;">{{mailLength}}</span>
            </div>
            <!-- Categories  -->
             <div style="margin-top: 10px;">
                <div class="row">
                    <p style="font-weight: 900;">Categories</p>
                </div>
                <div *ngFor="let x of categorieslist" [ngClass]="siddemenu(x?.name) " (click)="sidemenuclick(x?.name)">
                    <span class="lablestyle">
                        <span [ngStyle]="{'background-color': x?.bgColorCode, 'color': x?.textColorCode}" class="Categoriesstyle" style="float: left !important;">{{x?.name}}</span>
                    </span>
                </div>
             </div>
        </div>
     </mat-sidenav>
    <mat-sidenav-content >
      <mat-toolbar class="no-bg shadow-1">
        <mat-toolbar-row class="d-flex ">
          <div class="col-2 col-sm-2  col-md-1 col-lg-1 col-xl-1 pl-0 " style="padding-left: 0 !important;">
            <button type="button" mat-icon-button (click)="sideNav.toggle()">
              <mat-icon>menu</mat-icon>
            </button>
          </div>
          <div class="col-10 col-sm-10  col-md-11 col-lg-11 col-xl-11 pl-0 " style="padding-left: 0 !important;">
           <div style="display: flex;width: 100%;justify-content: end;">
            <div style="padding: 5px;" (click)="syncaccount()">
                <mat-icon style="cursor: pointer;">sync</mat-icon>
           </div>
           <ng-container *ngIf="Adminstatus||MailsSettings">
            <div style="padding: 5px;" (click)="clickingsetting()">
                <img  style="cursor: pointer;" src="../../../assets/images/iconsForsvg/settings.svg" >
            </div>
        </ng-container>
        <div style="padding: 5px;"(click)="closepopup()">
            <mat-icon style="cursor: pointer;">cancel</mat-icon>
        </div>
           </div>
          </div>
        </mat-toolbar-row> 
      </mat-toolbar>
      <div class="p-0 m-0">
        <div class="row">
                    <!-- two  -->
         <ng-container *ngIf="mailListview">
            <div class="p-0 col-12"
            style="border-left: 1px solid rgb(237, 231, 231);border-right: 1px solid rgb(237, 231, 231);padding-left: 5px !important;padding-right: 5px !important;">
               <!-- search  -->
               <div class="row">
                  <div class="col-10">
                    <form [formGroup]="Form">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label style="color: lightgray;">Search</mat-label>
                        <input matInput formControlName="SearchValue" >
                        <mat-icon matSuffix (click)="onSearch()">search</mat-icon>
                      </mat-form-field>
                    </form>
                  </div> 
                  <div class="col-2">
                    <button mat-raised-button style="position: relative;top: 10px;"  class="b-blue" (click)="onSearch()">{{"Apply"|translate}}</button>
                  </div>
               </div>
               <div style="overflow-y: scroll;height: 70vh;overflow-x: hidden;">
                   <ng-container *ngIf="allmails.length==0&&!spinner">
                           <h1 style="text-align: center;">No Data Found</h1>
                   </ng-container>
                   <ng-container *ngIf="allmails.length==0&&spinner">
                    <app-skeleton-loader></app-skeleton-loader>
                    </ng-container>
                   <ng-container *ngIf="allmails.length!=0&&!spinner">
                       <div class="mt-4 " [ngClass]="gethoverclass(x)" *ngFor="let x of allmails;let k = index">
                           <div style="display: flex;width: 100%;cursor: pointer;">
                               <div style="width: 10%;">
                                   <div style="display: flex;justify-content: center;">
                                    <div>
                                        <mat-icon [matMenuTriggerFor]="moreMenu">more_vert</mat-icon>
                                         <mat-menu #moreMenu="matMenu" class="eventlist">
                                             <ng-template matMenuContent>
                                                 <button class="fw-600" mat-menu-item (click)="Replyemail(x)">
                                                     <img  src="../../../assets/images/iconsForsvg/reply.svg">
                                                     <span class="fw-600 color_black" style="margin-left: 5px;">{{'Reply'|translate}}</span>
                                                 </button>
                                                 <button class="fw-600" mat-menu-item (click)="ReplyAllemail(x)">
                                                     <img  src="../../../assets/images/iconsForsvg/reply_all.svg">
                                                     <span class="fw-600 color_black" style="margin-left: 5px;">{{'Reply All'|translate}}</span>
                                                 </button>
                                                 <button class="fw-600" mat-menu-item (click)="Forwardemail(x)">
                                                     <img  src="../../../assets/images/iconsForsvg/forward.svg">
                                                     <span class="fw-600 color_black" style="margin-left: 5px;">{{'Forward'|translate}}</span>
                                                 </button>
                                                 <button class="fw-600" mat-menu-item (click)="deletemail(x)">
                                                     <img  src="../../../assets/images/iconsForsvg/delete.svg">
                                                     <span class="fw-600 color_black" style="margin-left: 5px;">{{'Delete'|translate}}</span>
                                                 </button>
                                                 <button class="fw-600" mat-menu-item (click)="Markspammail(x)">
                                                     <img  src="../../../assets/images/iconsForsvg/mark_email_unread.svg">
                                                     <span class="fw-600 color_black" style="margin-left: 5px;">{{'Mark spam'|translate}}</span>
                                                 </button>
                                                 <button class="fw-600" mat-menu-item (click)="BlockEmail(x)">
                                                     <img  src="../../../assets/images/iconsForsvg/block.svg">
                                                     <span class="fw-600 color_black" style="margin-left: 5px;">{{'Block'|translate}}</span>
                                                 </button>
                                                 <button class="fw-600" mat-menu-item (click)="Markasunread(x)">
                                                     <img  src="../../../assets/images/iconsForsvg/mark_email_unread.svg">
                                                     <span class="fw-600 color_black" style="margin-left: 5px;">{{'Mark as unread'|translate}}</span>
                                                 </button>
                                                 <button class="fw-600" mat-menu-item (click)="CreateTask(x)" >
                                                     <img  src="../../../assets/images/iconsForsvg/task.svg">
                                                     <span class="fw-600 color_black" style="margin-left: 5px;">{{'Create Task'|translate}}</span>
                                                 </button>
                                                 <button class="fw-600" mat-menu-item (click)="CreateEvent(x)">
                                                     <img  src="../../../assets/images/iconsForsvg/calendar.svg">
                                                     <span class="fw-600 color_black" style="margin-left: 5px;">{{'Create Event'|translate}}</span>
                                                 </button>
                                                 <button class="fw-600" mat-menu-item (click)="CreateInitiative(x)"  >
                                                    <img  src="../../../assets/images/iconsForsvg/ballot.svg">
                                                    <span class="fw-600 color_black" style="margin-left: 5px;">{{'Create Initiative'|translate}}</span>
                                                </button>
                                                 <button class="fw-600" mat-menu-item (click)="ConverttoContact(x)">
                                                     <img  src="../../../assets/images/iconsForsvg/contacts.svg">
                                                     <span class="fw-600 color_black" style="margin-left: 5px;">{{'Convert to Contact'|translate}}</span>
                                                 </button> 
                                                 <button class="fw-600" mat-menu-item >
                                                    <img  src="../../../assets/images/iconsForsvg/autivity log.svg">
                                                    <span class="fw-600 color_black" style="margin-left: 5px;">{{'Activity logs'|translate}}</span>
                                                </button>
                                             </ng-template>
                                         </mat-menu>
                                      </div>
                                    <div>
                                        <img *ngIf="x.isPersonal" src="../../../assets/images/iconsForsvg/person.svg">
                                        <img *ngIf="!x.isPersonal" src="../../../assets/images/iconsForsvg/group.svg">
                                     </div>
                                    
                                   </div>
                                 </div>
                                 <div style="width: 90%;" >
                                     <!-- one   -->
                                     <div class="row" (click)="getmailbody(x)" (click)="showmailbody()">
                                         <div class="col-8 p-0"> 
                                             <h6 class="mb-0">{{x?.senderName}}</h6>
                                         </div>
                                         <div class="col-4">
                                             <button *ngIf="x?.isPromotions||x?.isSocial"  [ngClass]="lablecolor(x)">{{lablename(x)}}</button>
                                         </div>
                                     </div>
                                     <!-- two  -->
                                     <h6 (click)="getmailbody(x)" (click)="showmailbody()" class="fw-semibold">{{x?.subject}}</h6>
                                     <!-- three  -->
                                          <div style="display: flex;width: 100%;">
                                              <div style="display: flex;width: 90%;" class="totalmailbody">
                                                  <div *ngIf="x?.isEventConverted">
                                                      <img class="sidemenuicons"  (click)="showevent(x)" src="../../../assets/images/iconsForsvg/calendar.svg"  >
                                                  </div>
                                                  <div *ngIf="x?.isTaskConverted">
                                                      <img class="sidemenuicons" (click)="showtask(x)"  src="../../../assets/images/iconsForsvg/task.svg"  >
                                                  </div>
                                                    <div style="display: flex;" class="flexdiv" *ngIf="sidemenusection=='Sent'">
                                                        <div *ngIf="x.isOpened">
                                                            <img src="../../../assets/images/iconsForsvg/open-email.svg" alt="">
                                                          </div>
                                                          <div *ngIf="x.isClicked">
                                                            <img src="../../../assets/images/iconsForsvg/click-email.svg" alt="">
                                                          </div>
                                                          <div *ngIf="x.isOpened">
                                                            <img src="../../../assets/images/iconsForsvg/reply-email.svg" alt="">
                                                          </div>
                                                    </div>
                                                    <div style="display: flex;width: 100%;justify-content: end;">
                                                        <div style="display: flex;width: 90%;" class="totalmailbody" *ngIf="!x.isPersonal">
                                                                <div *ngFor="let User of x?.viewedUsers;let i = index" >
                                                                  <div>
                                                                    <div [style.background]="getColor(i)"  *ngIf="!User?.profile || User?.profile === ''"
                                                                    (click)="showgroupinUser(User)" class="profile_ML">
                                                                    {{getuserFLnames(User?.userName)}}</div>
                                                                    <div>
                                                                        <img *ngIf="User?.profile && User?.profile !== ''"
                                                                        [src]="User.profile" (click)="showgroupinUser(User)"
                                                                        onError="this.src='assets/avatar.svg'"
                                                                        style="width: 35px; height: 35px; border-radius: 50%;">
                                                                    </div>
                                                                  </div>
                                                            </div>
                                                           </div>
                                                             <div style="display: flex;justify-content: end;">
                                                                <div>
                                                                    <p style="text-align: end;color: gray;">{{x.datetime|date:'H:mm'}}</p>
                                                                   </div>
                                    
                                                                   <div>
                                                                       <img *ngIf="!x?.isUnReaded" src="../../../assets/images/iconsForsvg/done_all.svg" >
                                                                   </div>
                                                             </div>
                                                    </div>
                                              </div>
                                          </div>
                                           <!-- two  -->
                                           <ng-container *ngIf="getseleteduserscount()!=1&&getseleteduserscount()!=0">
                                            <div class="row">
                                                <span [style.background]="getColor(x?.accountId)"  style="color: white;padding: 2px 15px;border-radius: 15px;">{{x?.recipients}}</span>
                                            </div>
                                           </ng-container>
                                 </div>
                           </div>
                      </div>
                   </ng-container>    
               </div>
               <ng-container *ngIf="allmails.length!=0">
                <div class="row m-0">
                    <mat-paginator  [pageSize]="10" [length]="mailLength" (page)="nextpage($event)"></mat-paginator>
                </div>
               </ng-container>
           </div>
         </ng-container>

        <!-- tree  -->
         <ng-container *ngIf="mailbodyview">
            <div class="col-12" style="padding: 3px !important;" >
                <ng-container *ngIf="mailbody==null">
                    <app-skeleton-loader></app-skeleton-loader>
                    </ng-container>
                    <ng-container *ngIf="mailbody!=null">
                        <div style="overflow-y: scroll;height: 85vh;overflow-x: hidden;" >
                            <div  style=" display: flex;width: 100%; border-bottom: 1px solid rgb(237, 231, 231); margin-bottom: 10px;padding: 10px;">
                                    <div style="width: 90%;display: flex;" class="flexdiv">
                                        <div style="display: flex;width: 90%;" class="totalmailbody">
                                            <ng-container>
                                                <div (click)="backarrow()">
                                                    <mat-icon>keyboard_backspace</mat-icon>  
                                                </div>
                                            </ng-container>
                                            <div *ngIf="mailbody?.isEventConverted">
                                                <img class="sidemenuicons"  (click)="showevent(mailbody)" src="../../../assets/images/iconsForsvg/calendar.svg"  >
                                            </div>
                                            <div *ngIf="mailbody?.isTaskConverted">
                                                <img class="sidemenuicons" (click)="showtask(mailbody)"  src="../../../assets/images/iconsForsvg/task.svg"  >
                                            </div>
                                            <ng-container  *ngIf="!mailbody?.isPersonal">
                                              <div style="display: flex;width: 100%;" *ngIf="mailbody?.viewedUsers!=null">
                                                <div *ngFor="let User of mailbody?.viewedUsers;let i = index" >
                                                    <div>
                                                      <div [style.background]="getColor(i)"  *ngIf="!User?.profile || User?.profile === ''"
                                                      (click)="showgroupinUser(User)" class="profile_ML">
                                                      {{getuserFLnames(User?.userName)}}</div>
                                                      <div>
                                                          <img *ngIf="User?.profile && User?.profile !== ''"
                                                          [src]="User.profile" (click)="showgroupinUser(User)"
                                                          onError="this.src='assets/avatar.svg'"
                                                          style="width: 35px; height: 35px; border-radius: 50%;">
                                                      </div>
                                                    </div>
                                              </div>
                                              </div>
                                             </ng-container>
                                        </div>
                                </div>
                              <div style="width: 10%;">
                                  <button mat-icon-button [matMenuTriggerFor]="moreMenu">
                                      <mat-icon>more_vert</mat-icon>
                                  </button>
                                  <mat-menu #moreMenu="matMenu" class="eventlist">
                                      <ng-template matMenuContent>
                                          <button class="fw-600" mat-menu-item (click)="Replyemail(mailbody)">
                                              <img  src="../../../assets/images/iconsForsvg/reply.svg">
                                              <span class="fw-600 color_black" style="margin-left: 5px;">{{'Reply'|translate}}</span>
                                          </button>
                                          <button class="fw-600" mat-menu-item (click)="Replyemail(mailbody)">
                                              <img  src="../../../assets/images/iconsForsvg/reply_all.svg">
                                              <span class="fw-600 color_black" style="margin-left: 5px;">{{'Reply All'|translate}}</span>
                                          </button>
                                          <button class="fw-600" mat-menu-item (click)="Forwardemail(mailbody)">
                                              <img  src="../../../assets/images/iconsForsvg/forward.svg">
                                              <span class="fw-600 color_black" style="margin-left: 5px;">{{'Forward'|translate}}</span>
                                          </button>
                                          <button class="fw-600" mat-menu-item (click)="printmailmessage()">
                                              <img  src="../../../assets/images/iconsForsvg/print.svg">
                                              <span class="fw-600 color_black" style="margin-left: 5px;">{{'Print'|translate}}</span>
                                          </button>
                                          <button class="fw-600" mat-menu-item (click)="deletemail(mailbody)">
                                            <img  src="../../../assets/images/iconsForsvg/delete.svg">
                                            <span class="fw-600 color_black" style="margin-left: 5px;">{{'Delete'|translate}}</span>
                                        </button>
                                        <button class="fw-600" mat-menu-item (click)="Markspammail(mailbody)">
                                            <img  src="../../../assets/images/iconsForsvg/mark_email_unread.svg">
                                            <span class="fw-600 color_black" style="margin-left: 5px;">{{'Mark spam'|translate}}</span>
                                        </button>
                                          <button class="fw-600" mat-menu-item (click)="BlockEmail(mailbody)">
                                              <img  src="../../../assets/images/iconsForsvg/block.svg">
                                              <span class="fw-600 color_black" style="margin-left: 5px;">{{'Block'|translate}}</span>
                                          </button>
                                          <button class="fw-600" mat-menu-item (click)="downloadmailmessage(mailbody?.subject)">
                                              <img  src="../../../assets/images/iconsForsvg/download.svg">
                                              <span class="fw-600 color_black" style="margin-left: 5px;">{{'Download'|translate}}</span>
                                          </button>
                                          <button class="fw-600" mat-menu-item (click)="Markasunread(mailbody)">
                                              <img  src="../../../assets/images/iconsForsvg/mark_email_unread.svg">
                                              <span class="fw-600 color_black" style="margin-left: 5px;">{{'Mark as unread'|translate}}</span>
                                          </button>
                                          <button class="fw-600" mat-menu-item (click)="CreateTask(mailbody)"  >
                                              <img  src="../../../assets/images/iconsForsvg/task.svg">
                                              <span class="fw-600 color_black" style="margin-left: 5px;">{{'Create Task'|translate}}</span>
                                          </button>
                                          <button class="fw-600" mat-menu-item (click)="CreateEvent(mailbody)"  >
                                            <img  src="../../../assets/images/iconsForsvg/calendar.svg">
                                            <span class="fw-600 color_black" style="margin-left: 5px;">{{'Create Event'|translate}}</span>
                                        </button>
                                          <button class="fw-600" mat-menu-item (click)="CreateInitiative(mailbody)"  >
                                              <img  src="../../../assets/images/iconsForsvg/ballot.svg">
                                              <span class="fw-600 color_black" style="margin-left: 5px;">{{'Create Initiative'|translate}}</span>
                                          </button>
                                          <button class="fw-600" mat-menu-item (click)="ConverttoContact(mailbody)">
                                            <img  src="../../../assets/images/iconsForsvg/contacts.svg">
                                            <span class="fw-600 color_black" style="margin-left: 5px;">{{'Convert to Contact'|translate}}</span>
                                        </button>
                                        <button class="fw-600" mat-menu-item >
                                            <img  src="../../../assets/images/iconsForsvg/autivity log.svg">
                                            <span class="fw-600 color_black" style="margin-left: 5px;">{{'Activity logs'|translate}}</span>
                                        </button>
                                      </ng-template>
                                  </mat-menu>
                              </div>
                          </div>
                          <div id="printSection">
                          <!-- subject  -->
                          <div class="row mt-2">
                            <h2 style="font-weight: 700;">{{mailbody?.subject|titlecase}}</h2>
                        </div>
                          <div class="row">
                              <div class="col-2">
                                <div  class="profile">{{getuserFLnames(mailbody?.senderName)}}</div> 
                              </div>
                              <div class="col-10 p-0">
                                  <div class="row p-0 m-0">
                                      <div class="col-9 p-0 m-0">
                                         <p style="font-weight: 900;margin: 0 !important;font-size: 14px;">{{mailbody?.senderName}}</p>
                                         <p style="color: gray;font-size: 12px;">{{mailbody?.senderEmail?mailbody?.senderEmail:mailbody?.fromEmail}}</p>
                                         <ng-container *ngIf="mailbody?.toRecipients!=null">
                                           <div>
                                            <span><b>To :</b></span>
                                            <span *ngFor="let x of mailbody?.toRecipients">
                                                <span>{{x}}</span>
                                            </span>
                                           </div>
                                        </ng-container>
                                         <ng-container *ngIf="mailbody?.ccRecipients!=nul">
                                          <div>
                                            <span><b>Cc :</b></span>
                                            <span *ngFor="let x of mailbody?.ccRecipients">
                                                <span>{{x}}</span>
                                            </span>
                                          </div>
                                        </ng-container>
                                         <ng-container *ngIf="mailbody?.bccRecipients!=nul">
                                          <div>
                                            <span><b>BCC :</b></span>
                                            <span *ngFor="let x of mailbody?.bccRecipients">
                                                <span>{{x}}</span>
                                            </span>
                                          </div>
                                        </ng-container>
                                      </div>
                                      <div class="col-3 p-0 m-0">
                                          <!-- <button style="float: right;"   *ngIf="mailbody?.isPromotions||mailbody?.isSocial"  [ngClass]="lablecolor(mailbody)">{{lablename(mailbody)}}</button> -->
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <ng-container class="row mt-2" *ngIf="mailbody?.bodyContentUrl!=null">
                            <div #templateContainer [innerHTML]="httpGet(mailbody?.bodyContentUrl )">
                            </div>
                          </ng-container>
                          <ng-container class="row mt-2" *ngIf="mailbody?.bodyContentUrl==null">
                            <ng-container *ngIf="mailbody?.body?.contentType=='html'">
                                <div style="overflow-x: hidden;" [innerHTML]="mailbody?.body?.content"></div>
                            </ng-container>
                            <ng-container *ngIf="mailbody?.body?.contentType=='text'">
                                <p>{{mailbody?.body?.content}}</p>
                            </ng-container>
                          </ng-container>
                          <div style="margin-top: 30px;">
                            <p style="margin: 0 !important;">Regards,</p>
                            <p style="font-weight: 700;">{{mailbody?.senderName}}</p>
                        </div>
                          <ng-container *ngIf="mailbody?.attachmentFileDtls!=null&&mailbody?.attachmentFileDtls.length!=0">
                          <hr>
                               <p style="font-weight: 700;text-align: center;">Attachments</p>
                               <div class="row">
                                  <div class="col-6 p-2" *ngFor="let x of  mailbody?.attachmentFileDtls">
                                      <div class="row">
                                          <div class="col-2">
                                            <img src="../../../../assets/notecreateicons/doc-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='docx'" class="drowdownimage" >
                                            <img src="../../../../assets/notecreateicons/png-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='png'" class="drowdownimage" >
                                            <img src="../../../../assets/notecreateicons/png-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='PNG'" class="drowdownimage" >
                                            <img src="../../../../assets/notecreateicons/jpg-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='jpg'" class="drowdownimage" >
                                            <img src="../../../../assets/notecreateicons/xls-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='xlsx'" class="drowdownimage" >
                                            <img src="../../../../assets/notecreateicons/avi-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='avi'" class="drowdownimage" >
                                            <img src="../../../../assets/notecreateicons/pdf-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='pdf'" class="drowdownimage" >
                                            <img src="../../../../assets/notecreateicons/ppt-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='powerpoint'" class="drowdownimage" >
                                            <img src="../../../../assets/notecreateicons/html-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='html'" class="drowdownimage" >
                                            <img src="../../../../assets/notecreateicons/svg.svg" *ngIf="getfiletype(x.fileName)=='svg'" class="drowdownimage" >
                                            <img src="../../../../assets/notecreateicons/csv-svgrepo-com.svg" (click)="View(data)" *ngIf="getfiletype(x.fileName)=='csv'" class="drowdownimage" >
                                            <img src="../../../../assets/notecreateicons/txt image.jpg" (click)="View(data)" *ngIf="getfiletype(x.fileName)=='txt'" class="drowdownimage" >
                                          </div>
                                          <div class="col-10">
                                              <p>{{x?.fileName}}</p>
                                          </div>
                                      </div>
                                  </div>
                               </div>
                          </ng-container>
                        </div>
                          </div>
                        </ng-container>
            </div>
         </ng-container>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
<!-- ---------   -->

<div class="overlay" *ngIf="spinner">
    <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>
