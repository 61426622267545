import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class TimeManagementService {

  constructor(private http: HttpClient) { }


  createMode(mode:any): Observable<any> {
    let params = new HttpParams();
     params = params.append('Mode', mode);
      return this.http.post(baseUrl + '/api/TimeSheet/UpdateTrackingMode', {}, { params });
  }

  createprojects(name: string, color:string, fontcolor:string): Observable<any> {
    console.log(name,color,fontcolor)
    let params = new HttpParams();
     params = params.append('ProjectName', name);
        params = params.append('Color', color);
        params = params.append('FontColor', fontcolor);
        return this.http.post(baseUrl + '/api/TimeSheet/CreateProject', {}, { params });
  }

  updateProject(id:any, name: string, color:string, fontcolor:string) {
    let params = new HttpParams();
    params = params.append('projectId', id);
    params = params.append('ProjectName', name);
       params = params.append('Color', color);
       params = params.append('FontColor', fontcolor);
       return this.http.put(baseUrl + '/api/TimeSheet/UpdateProject', {}, { params });
  }

  deleteProject(id:any){
    return this.http.delete(baseUrl + '/api/TimeSheet/DeleteProject?projectId=' + id);
  }

  createTimeTrack(requestData: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.post(baseUrl + '/api/TimeSheet/UpdateTrackingOption', requestData, httpOptions);
  }

  createRequest(requestData: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.post(baseUrl + '/api/TimeSheet/CreateTimeOffRequest', requestData, httpOptions);
  }

  getAllProjects(){
      return this.http.get(baseUrl + '/api/TimeSheet/GetProjects')
  }

  getAllClockInHistory(){
    return this.http.get(baseUrl + '/api/TimeSheet/GetClockInHistory')
}

getAllEventClockInHistory(id:any){
  return this.http.get(baseUrl + '/api/TimeSheet/GetClockInHistory?EventId=' + id)
}

  getPendingApprovals(pageno:any, numberofRecord:any){
    return this.http.get(baseUrl + '/api/TimeSheet/GetManagerPendingRequests?PageNumber=' + pageno + '&NumberOfRecord=' + numberofRecord)
  }

  getTimesheetPendings(startdate:any, enddate:any, search:any){
    return this.http.get(baseUrl + '/api/TimeSheet/GetPendingTimeEntries?StartDate=' + startdate + '&EndDate=' + enddate + '&Search=' + search)
  }

  approveRequest(id:number, status:number){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.put(baseUrl + '/api/TimeSheet/ApproveRequest?requestId=' + id + '&Status=' + status , httpOptions);
  }

  getSelectedNode(){
    return this.http.get(baseUrl + '/api/TimeSheet/FindUserTimeTrackingMode')
  }

  getEntryWithDate(startDate:any, endDate:any, search:any){
    return this.http.get(baseUrl + '/api/TimeSheet/FindEntries?StartDate=' + startDate + '&EndDate=' + endDate + '&search=' + search);
  }

  CreateEntry(requestData:any){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.post(baseUrl + '/api/TimeSheet/AddEntry', requestData, httpOptions);
  }

  UpdateEntry(requestData:any){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.put(baseUrl + '/api/TimeSheet/UpdateEntry', requestData, httpOptions);
  }

  ClockInTime(startTime:any, projectId:any){
    let params = new HttpParams();
    params = params.append('ProjectId', projectId);
       params = params.append('startTime', startTime);
       return this.http.post(baseUrl + '/api/TimeSheet/StartTimesheet', {}, { params });
  }

  approveTimeEntry(data:any){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.put(baseUrl + '/api/TimeSheet/ApproveTimeEntry', data, httpOptions);
  }
}
