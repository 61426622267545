<ng-container *ngIf="eventdailog">
    <div class="d-flex flex-row align-items-center modal-header">
        <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
            <mat-icon style="position: relative;right: 10px;"> event</mat-icon>
            <span *ngIf="screenType=='CreateEvent' || screenType=='CreateEventAutomation'|| screenType=='CreateEvent_with_mail'"> {{"Create Event"|translate}}</span>
            <span *ngIf="screenType=='CreateSimulationEvent'">{{"Simulation Event"|translate}}</span>
        </h2>
        <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
        <mat-menu #menu="matMenu">
            <button class="fw-600" (click)=" importdata()" mat-menu-item>
                <mat-icon> import_export</mat-icon>{{"Import Data"|translate}}
            </button>
            <button class="fw-600" mat-menu-item (click)="downloadTemplate()">
                <mat-icon>download</mat-icon> {{"Download Template"|translate}}
            </button>
        </mat-menu>
    </div>

    <mat-dialog-content class="mr-0 ml-0">
        <form [formGroup]="form"> 
            <!-- Title  -->
            <div class="row w-100 mt-2">
                <mat-form-field class="w-100">
                    <mat-label>{{"Title"|translate}}<span class="danger">* </span></mat-label>
                    <input matInput type="text" id="Title" formControlName="Title" (click)="openPopup('Title')">
                    <mat-error *ngIf="form.controls['Title'].hasError('required')">
                        {{"Title required"|translate}}
                    </mat-error>
                </mat-form-field> 
            </div> 
            <!-- Start Date & Time   -->
            <div class="row firstAndLastName">
                <div  class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Start Date"|translate}}</mat-label>
                        <input matInput [matDatepicker]="startdatepicker1" style="color: black;"
                            formControlName="StartDate" (click)="openPopup('StartDate')">
                        <mat-datepicker-toggle matSuffix [for]="startdatepicker1"></mat-datepicker-toggle>
                        <mat-datepicker #startdatepicker1></mat-datepicker>
                        <mat-error *ngIf="form.controls['StartDate'].hasError('required')">
                            {{"Start Date"|translate}} is <strong>{{"required"|translate}}</strong>
                        </mat-error>
                    </mat-form-field> 
                </div>
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Start Time"|translate}} <span class="danger">* </span></mat-label>
                        <mat-select formControlName="StartTime">
                            <mat-option *ngFor="let time of timeList" [value]="time">
                                {{ time }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.controls['StartTime'].hasError('required')">
                            {{"Start Time"|translate}} is <strong>{{"required"|translate}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
              
            </div>
            <!-- End Date & Time -->
            <div class="row firstAndLastName">
                <div  class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"End Date"|translate}}</mat-label>
                        <input matInput [matDatepicker]="enddatepicker1" style="color: black;"
                            formControlName="EndDate" (click)="openPopup('EndDate')">
                        <mat-datepicker-toggle matSuffix [for]="enddatepicker1"></mat-datepicker-toggle>
                        <mat-datepicker #enddatepicker1></mat-datepicker>
                        <mat-error *ngIf="form.controls['EndDate'].hasError('required')">
                            {{"End Date"|translate}} is <strong>{{"required"|translate}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>̉
               
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"End Time"|translate}} <span class="danger">* </span></mat-label>
                        <mat-select formControlName="EndTime">
                            <mat-option *ngFor="let time of timeList" [value]="time">
                                {{ time }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.controls['EndTime'].hasError('required')">
                            {{"End Time"|translate}} is <strong>{{"required"|translate}}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
              
            </div>

            <div class="row p-0">
                <div class="col-12 p-0">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Project"|translate}}</mat-label>
                        <mat-select formControlName="ProjectId">
                            <mat-option *ngFor="let x of Projects?.data" [value]="x.id">
                              <span [ngStyle]="{'color': x.fontColor, 'background': x.color}"
                                style="padding:6px;border-radius: 5px;">{{x?.projectName }}</span>
                            </mat-option>
                          </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <!-- Sites  -->
            <div class="row p-0">
                <div class="col-12 p-0">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Site"|translate}} <span style="color: red;">*</span></mat-label>
                        <mat-select (openedChange)="filterOptions('','Sites')" #select formControlName="SiteId"
                            (selectionChange)="updateAddress($event)">
                            <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'Sites')"
                                placeholder="Filter" />
                            <mat-option *ngFor="let site of filteredsites" [value]="site.id">
                                <span>
                                    <img src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
                                </span>
                                {{site.companyName|titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>


            <!-- ------------  --> 
            <!-- --------------  -->
            <!-- Address  -->
            <div class="row p-0">
                <mat-form-field class="w-100">
                    <mat-label>{{"Address"|translate}}</mat-label>
                    <input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                        (onAddressChange)="handleAddressChange($event)" matInput type="text" formControlName="Address"
                        (click)="openPopup('Address')">
                </mat-form-field>
            </div>
            
            <!-- City Country Zipcode  -->
            <div class="row mt-2 countryCityZipCode" >
                <div class=" col-4 col-md-4 col-sm-12">
                    <mat-form-field class="w-100 p-0">
                        <mat-label>{{"City"|translate}}</mat-label>
                        <input matInput type="text" formControlName="City" (click)="openPopup('City')">
                    </mat-form-field>
                </div>
                <div class="col-4 col-md-4 col-sm-12">
                    <mat-form-field class="w-100">
                        <mat-label>{{"Country"|translate}}</mat-label>
                        <mat-select formControlName="SelactCountry">
                            <div class="mat-filter-field">
                                <input class="mat-filter-input-search" matInput
                                    (input)="filterCountry($event.target.value)" (click)="openPopup('SelactCountry')">
                            </div>
                            <div>
                                <mat-option [value]="country.name" (click)="selactcountry(country.code)"
                                    *ngFor="let country of listofcountry$">
                                    {{country.name|titlecase}}
                                </mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-4 col-md-4 col-sm-12" >
                    <mat-form-field class="w-100 p-0">
                        <mat-label>{{"Zipcode"|translate}}</mat-label>
                        <input matInput type="text" formControlName="ZipCode" (click)="openPopup('ZipCode')">
                    </mat-form-field>
                </div>
            </div>



            <ng-container *ngIf="screenType=='CreateSimulationEvent'">
                <mat-form-field class="w-100" appearance="legacy">
                    <mat-label>{{"No of People needed?"|translate}}</mat-label>
                    <input matInput type="number" id="NumberOfUsers" formControlName="NumberOfUsers">
                </mat-form-field>
            </ng-container>
            <!-- -----------  -->
            <!-- Users  -->
            <div class="row w-100">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Users"|translate}} <span style="color: red;">*</span></mat-label>
                    <mat-select multiple (selectionChange)="selectedUsers($event)" (openedChange)="filterOptions('','users')" #select formControlName="UserId">
                        <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'users')"
                            placeholder="Filter" />
                        <mat-option *ngFor="let user of filteredusers" [value]="user.id">
                            <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                                onError="this.src='assets/avatar.svg'">
                            {{ user.firstName| titlecase}} {{user.lastName|titlecase}}
                        </mat-option>
                    </mat-select> 
                </mat-form-field>
            </div>
            <!-- User Group  -->
            <div class="row w-100">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"User Group"|translate}}</mat-label>
                    <mat-select multiple (openedChange)="filterOptions('','UserGroups')" #select
                        formControlName="UserGroupsId">
                        <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'UserGroups')"
                            placeholder="Filter" />
                        <mat-option [value]="userGroup.id" *ngFor="let userGroup of filteredusersgroup">
                            <span *ngIf="userGroup.dynamicGroupId ==null">
                                <img style="border-radius: 50%;" width="30" height="30"
                                    src="../../../../../assets/images/iconsForsvg/groupiconRed.svg">
                            </span>
                            <span *ngIf="userGroup.dynamicGroupId !=null">
                                <img style="border-radius: 50%;" width="30" height="30"
                                    src="../../../../../assets/images/iconsForsvg/groupiconblue.svg">
                            </span>
                            {{userGroup.groupName|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <!-- Contact  -->
            <div class="row w-100">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{'Contact'|translate}} <span style="color: red;">*</span></mat-label>
                    <mat-select multiple (openedChange)="filterOptions('','Contacts')" #select
                        formControlName="ContactId">
                        <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'Contacts')"
                            placeholder="Filter" />
                        <mat-option *ngFor="let contact of filteredcontacts" [value]="contact.id"
                            (click)="getDiscountValue(contact,'Contacts')">
                            <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                [src]="contact?.profilePicture ? contact.profilePicture:'assets/avatar.svg'"
                                onError="this.src='assets/avatar.svg'">
                            {{ contact.firstName|titlecase }} {{contact.lastName|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- Contact Group  -->
            <div class="row w-100">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Contact Group"|translate}}</mat-label> 
                    <mat-select multiple (openedChange)="filterOptions('','ContactGroups')" #select
                        formControlName="ContactGroupsId">
                        <input style="padding: 15px;" matInput
                            (keyup)="filterOptions($event.target.value,'ContactGroups')" placeholder="Filter" />
                        <mat-option [value]="contactGroup.id" *ngFor="let contactGroup of filteredcontactsgroup">
                            <span *ngIf="contactGroup.dynamicGroupId ==null">
                                <img style="border-radius: 50%;" width="30" height="30"
                                    src="../../../../../assets/images/iconsForsvg/groupiconRed.svg">
                            </span>
                            <span *ngIf="contactGroup.dynamicGroupId !=null">
                                <img style="border-radius: 50%;" width="30" height="30"
                                    src="../../../../../assets/images/iconsForsvg/groupiconblue.svg">
                            </span>
                            {{contactGroup.groupName|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="row no-padding">
                <div class="col-11 no-padding">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Category"|translate}} <span class="danger">* </span></mat-label>
                        <mat-select id="CategoryId" formControlName="CategoryId">
                            <mat-option (click)="removecategory()">Select Category</mat-option>
                            <mat-option (click)="updatecategory(category)" [value]="category.id"
                                *ngFor="let category of categories ">{{category.categoryName|titlecase}}
                            </mat-option>
                            <mat-option value="OffTime">{{"Off Time"|translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-1 pointer">
                    <mat-icon *ngIf="showcreate == 0 && totalitems < 4" (click)="createcategory()"
                        matTooltip="create category" class="danger">
                        add</mat-icon>
                    <mat-icon *ngIf="showcreate == 1 && totalitems < 4" (click)="closecategory()" matTooltip="close"
                        class="danger">close
                    </mat-icon>
                </div>
            </div>

            <div class="row w-100" *ngIf="totalitems < 4 && category">
                <mat-label *ngIf="totalitems < 4 && category">{{"Create Category"|translate}}</mat-label>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Category name"|translate}} <span class="danger">* </span></mat-label>
                        <input matInput formControlName="categoryname">
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <mat-label>{{"Category Color"|translate}} <span class="danger">* </span></mat-label>
                    <input [style.background]="color1" [(colorPicker)]="color1"
                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                </div>
            </div>

            <div class="row firstAndLastName">
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Critic Level"|translate}}</mat-label>
                        <mat-select formControlName="CriticityId">
                            <mat-option [value]="criticity.criticityId" *ngFor="let criticity of CriticityId$ | async">
                                {{criticity.criticityName|translate}}
                            </mat-option>
                        </mat-select> 
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label> {{"Periodicity"|translate}} </mat-label>
                        <mat-select formControlName="PeriodicityId">
                            <mat-option (click)="radioChange(period)" [value]="period.id"
                                *ngFor="let period of periodicity">
                                {{period.name|titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>


            <div *ngIf="showPeriodDate == true">
                <div class="row w-100">
                    <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label> {{"Periodicity End Date"|translate}} </mat-label>
                            <input matInput [matDatepicker]="PeriodicityEndDate" style="color: black;"
                                formControlName="PeriodicityEndDate">
                            <mat-datepicker-toggle matSuffix [for]="PeriodicityEndDate"></mat-datepicker-toggle>
                            <mat-datepicker #PeriodicityEndDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label> {{"Start Time"|translate}} </mat-label>
                            <mat-select formControlName="PeriodicityTime">
                                <mat-option *ngFor="let time of timeList" [value]="time">
                                    {{ time }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <section *ngIf="showFilterDay == true">
                <ng-container *ngFor="let trd of trade">
                    <label class="btn btn-filter">
                        <mat-checkbox class="example-margin" (change)="changeTradesByCategory($event,trd.id)"
                            [checked]="trd.selected">{{ trd.label }}</mat-checkbox>
                    </label>&nbsp;
                </ng-container>
            </section>
            <div class="row w-100">
                <div class="col-12">
                    <mat-list-item>
                        <mat-checkbox formControlName="IsEventOrganizer">
                            {{"Do you want to exclude yourself ..?"|translate}}
                        </mat-checkbox>
                    </mat-list-item>
                </div>
                </div>

            <div class="row w-100 mt-2" *ngIf="getEventOrganizer()">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Event owner"|translate}} <span style="color: red;">*</span></mat-label>
                    <mat-select   #select formControlName="EventOwner" >
                        <mat-option *ngFor="let user of getseletedUsers()" [value]="user.id">
                            <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                                onError="this.src='assets/avatar.svg'">
                            {{ user.firstName| titlecase}} {{user.lastName|titlecase}}
                        </mat-option>
                    </mat-select> 
                </mat-form-field>
            </div>

            <!-- Private  -->
            <div class="row w-100">
                <div class="col-12 col-sm-12  col-md-12 col-lg-4 col-xl-4">
                    <mat-list-item>
                        <mat-checkbox formControlName="isPublic">
                            <mat-icon class="lock" style="position: relative;top:3px; left: 3px;">lock</mat-icon>
                            {{"Private"|translate}}</mat-checkbox>
                    </mat-list-item>
                </div>
                <div class="col-12 col-sm-12  col-md-12 col-lg-4 col-xl-4" style="margin:5px;">
                    <mat-list-item>
                        <mat-checkbox formControlName="IsOnlineEvent">
                            <img src="../../../../assets/images/iconsForsvg/phone-call.svg" alt="">
                            {{"Online"|translate}}</mat-checkbox>
                    </mat-list-item>
                </div>
            </div>

                <mat-list>
                    <div class="d-flex justify-content-between">
                        <h4 style="color: grey;margin-left: 10px;">
                            <img src="../../../../assets/images/iconsForsvg/remainders_Add.svg" alt="">
                            <b>{{"Reminders"|translate}}</b>
                        </h4>
                        <mat-chip-list>
                            <mat-chip (click)="showreminderspopup()">{{"Add"|translate}}</mat-chip>
                        </mat-chip-list>
                    </div>
                </mat-list>
           
            <ng-container *ngIf="getreminderststus()">
                <div class="row mt-2" *ngFor="let x of ReminderList;let i = index">
                 <span style="margin-left: 7px;">  
                <span *ngIf="x.triggertype=='Email'" style="padding: 5px;"><mat-icon> email</mat-icon></span>
                 <span *ngIf="x.triggertype=='Notification'" style="padding: 5px;"><mat-icon>campaign</mat-icon></span>
                 <span>{{x.value}} {{x.durationtype}} {{x.triggerMoment}} the event  {{x.Parameter}} date and time</span>
                 <span style="margin-left: 5px;cursor: pointer;" (click)="removereminder(i)"><mat-icon>delete</mat-icon></span>
                </span>
                </div>
            </ng-container> 


            <!-- Keywords  -->
            <div class="row w-100">
                <mat-form-field class="example-chip-list" style="width: 100% !important;">
                    <mat-label>{{"keyword"|translate}}</mat-label><br>
                    <mat-chip-list #chipGridforkeyword aria-label="Fruit selection" class="keywordlist">
                        <mat-chip-row class="keywordrow" *ngFor="let keyword of keywordlist">
                            <button class="keywordbutton">
                                <span class="keywordpostion">{{keyword|titlecase}}</span>
                                <mat-icon class="keywordcancelicon" (click)="remove(keyword)">cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-list>
                    <input [matChipInputFor]="chipGridforkeyword" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event)" />
                </mat-form-field>
            </div>



            <!-- Another Field  -->
            <ng-container *ngIf="AdminStatus=='true'">
                <mat-list>
                    <div class="d-flex justify-content-between">
                        <h4 class="m-0" style="color: grey;"><b>{{"Another Field"|translate}}</b></h4>
                        <mat-chip-list>
                            <mat-chip (click)="showdropdown()">{{"Add"|translate}}</mat-chip>
                        </mat-chip-list>
                    </div>
                </mat-list>
                <!-- ------------------------045555555555555555  -->
                <mat-card class="matcardstyle" *ngIf="dropdown == true">
                    <mat-card-content>
                        <div class="row w-100">
                            <mat-radio-group class="example-radio-group" aria-label="Select an option"
                                formControlName="tagType" (click)="selacttagType()">
                                <mat-radio-button class="example-radio-button" value="Checkbox">
                                    {{"Check box"|translate}}</mat-radio-button>
                                <mat-radio-button class="example-radio-button" value="Dropdown">
                                    {{"Dropdown list"|translate}}</mat-radio-button>
                                <mat-radio-button class="example-radio-button" value="Freefeild">
                                    {{"Free feild"|translate}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="row w-100" *ngIf="selectedradioval == 'Freefeild'">
                            <mat-radio-group class="example-radio-group" aria-label="Select an option"
                                formControlName="numeralType" (click)="checkChanges()">
                                <mat-radio-button class="example-radio-button"
                                    value="Alphanumeric">{{"Alphanumeric"|translate}}
                                </mat-radio-button>
                                <mat-radio-button class="example-radio-button" value="Numerals">
                                    {{"Numerals Only"|translate}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="row w-100">
                            <mat-form-field class="w-100"
                                *ngIf="selectedradioval == 'Freefeild' && selectednumeralval == 'Alphanumeric'">
                                <mat-label>{{"Title"|translate}}</mat-label>
                                <input matInput type="text" placeholder="Title*" formControlName="freefeildTitle">
                            </mat-form-field>

                            <mat-form-field class="w-100"
                                *ngIf="selectedradioval == 'Freefeild' && selectednumeralval == 'Numerals'">
                                <mat-label>{{"Title"|translate}}</mat-label>
                                <input matInput type="text" placeholder="Title*" formControlName="freefeildnumber">
                            </mat-form-field>
                        </div>

                        <div class="row w-100" *ngIf="selectedradioval != 'Freefeild'">
                            <mat-form-field class="w-100">
                                <mat-label>{{"Title"|translate}}</mat-label>
                                <input matInput type="text" placeholder="Title*" formControlName="Tagetitle">
                            </mat-form-field>
                        </div>
                        <div class="row w-100" *ngIf="selectedradioval == 'Dropdown'">
                            <mat-form-field class="w-100">
                                <mat-select formControlName="dropdowntype">
                                    <mat-option [value]="value" *ngFor="let value of dropdownvalue;">
                                        {{value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="row w-100" *ngIf="selectedradioval == 'Dropdown'">
                            <div class="col-8"></div>
                            <div class="col-4">
                                <button mat-raised-button class="b-blue" (click)="addAdditionalFieldLine()">
                                    {{"Add value"|translate}}</button>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions>
                        <div class="row w-100">
                            <div class="col-6"></div>
                            <div class="col-6">
                                <button mat-button (click)="close()">{{"Cancel"|translate}}</button>
                                <button mat-raised-button class="b-blue"
                                    (click)="applytag()">{{"Apply"|translate}}</button>
                            </div>
                        </div>
                    </mat-card-actions>
                </mat-card>
            </ng-container>

 

            <!-- Description  -->
            <div class="row w-100" >
                <mat-form-field class="w-100 mt-2 mb-2">
                    <mat-label>{{"Description"|translate}}</mat-label><br>
                    <textarea matInput formControlName="Description" (click)="openPopup('Description')"></textarea>
                    <mat-error *ngIf="form.controls['Description'].hasError('required')">
                        {{"Description is required"|translate}}
                    </mat-error>
                </mat-form-field>
            </div>
           
            <!-- -----------  -->
        </form>
        <mat-dialog-actions align="center" class="mb-24">
            <div class="row mt-2 w-100 justify-content-center">
                <div class="col-4">
                    <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
                </div>
                <div class="col-4">
                    <button mat-raised-button class="b-blue" (click)="ApplyEvent()">{{"Create"|translate}}</button>
                </div>
            </div>
        </mat-dialog-actions>
    </mat-dialog-content>
</ng-container>