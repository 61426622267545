<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">Service</h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content class="mr-0 ml-0">
    <!-- twooptions  -->
    <ng-container *ngIf="screentype=='twooptions'">
        <div class="row justify-content-center">
                <p style="text-align: center;margin-top: 10px;font-weight: 700;">Add a Media Service (1/3)</p>
        </div>
        <div class="row mt-3">
            <div class="col-8">
                <img  class="drowdownimage" src="../../../../assets/images/img/google-logo.jpg"> 
                <span style="margin-left: 5px;"><b>{{"Google"|translate}}</b></span></div>
            <div class="col-4">
                <button mat-raised-button class="b-blue" (click)="selectservice('Googleservice')">
                 {{"Sync"|translate}}</button>
            </div>
        </div>
        <div class="row mt-3 mb-2">
            <div class="col-8">
                <img  class="drowdownimage" src="../../../../assets/images/img/microsoft-logo.jpg"> 
                <span style="margin-left: 5px;"><b>{{"microsoft "|translate}}</b></span></div>
            <div class="col-4">
                <button mat-raised-button class="b-blue" (click)="selectservice('Mircosaftservice')">
                 {{"Sync"|translate}}</button>
            </div>
        </div>
    </ng-container>
    <!-- Googleservice  -->
    <ng-container *ngIf="screentype=='Googleservice'">
        <div class="row justify-content-center">
            <p style="text-align: center;margin-top: 10px;font-weight: 700;">Add a Media Service (2/3)</p>
         </div>
        <div class="row mt-3 mb-4 ">
            <div class="col-12 p-0 m-0"> 
                <mat-checkbox class="example-margin" [(ngModel)]="Gmail"></mat-checkbox>
                <img  class="drowdownimage" src="../../../../assets/notecreateicons/Gmail.svg"> 
                <span style="margin-left: 5px;"><b>{{"Gmail"|translate}}</b></span>
            </div>
        </div>
        <div class="row mt-3 mb-4">
            <div class="col-12 p-0 m-0">
                <mat-checkbox class="example-margin" [(ngModel)]="Drive"></mat-checkbox>
                <img  class="drowdownimage" src="../../../../assets/notecreateicons/GoogleDrive.png"> 
                <span style="margin-left: 5px;"><b> {{"Google Drive"|translate}}</b></span>
            </div>
        </div>
        <div class="row mt-3 mb-4">
            <div class="col-12 p-0 m-0">
                <mat-checkbox class="example-margin" [(ngModel)]="GoogleCalenders"></mat-checkbox>
                <img  src="../../../../assets/images/iconsForsvg/google-calendar-svgrepo-com.svg"> 
                <span style="margin-left: 5px;"><b>{{"Google Calender"|translate}}</b></span>
            </div>
        </div>
        <div class="row mt-3 mb-4">
        <div class="col-6"></div>
            <div class="col-6">
                <button mat-raised-button class="b-blue" (click)="Sync()">
                 {{"Sync"|translate}}</button>
            </div>
        </div>

    </ng-container>

    <!-- Mircosaftservice  -->
    <ng-container *ngIf="screentype=='Mircosaftservice'">
        <div class="row justify-content-center">
            <p style="text-align: center;margin-top: 10px;font-weight: 700;">Add a Media Service (2/3)</p>
    </div>
        <div class="row mt-3 mb-4">
            <div class="col-12 m-0 p-0">
                <mat-checkbox class="example-margin" [(ngModel)]="OneDrive"></mat-checkbox>
                <img  class="drowdownimage" src="../../../../assets/notecreateicons/OneDrive.svg">    
                 <span style="margin-left: 5px;"><b>{{"OneDrive"|translate}}</b></span>
                </div>
        </div>
        <div class="row mt-3 mb-4">
            <div class="col-12 p-0 m-0">
                <mat-checkbox class="example-margin" [(ngModel)]="OutLook"></mat-checkbox>
                <img  class="drowdownimage" src="../../../../assets/notecreateicons/Outlook.svg">    
                 <span style="margin-left: 5px;"><b>{{"OutLook"|translate}}</b></span>
                </div>
        </div>
        <div class="row mt-3 mb-4">
            <div class="col-12 p-0 m-0">
                <mat-checkbox class="example-margin" [(ngModel)]="OutLookCalender"></mat-checkbox>
                <img  src="../../../../assets/images/iconsForsvg/icons8-outlook-calendar-96.svg">    
                 <span style="margin-left: 5px;"><b>{{"OutLook Calender"|translate}}</b></span>
                </div>
        </div>
        <div class="row mt-3 mb-4">
            <div class="col-6"></div>
                <div class="col-6">
                    <button mat-raised-button class="b-blue" (click)="Sync()">
                     {{"Sync"|translate}}</button>
                </div>
            </div>

    </ng-container>
</mat-dialog-content>


<div class="overlay" *ngIf="spinner">
    <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>