import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProfilePickFormatService } from 'src/app/core/services/profile-pick-format.service';
import { CreateCategoryComponent } from '../create-category/create-category.component';
import { CategoryDetailsComponent } from '../category-details/category-details.component';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { GoogleApiService } from 'src/app/core/services/SYNC/google-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PopupCommonComponent } from '../../commonForAll/popup-common/popup-common.component';
import { DeleteCommomComponent } from '../../commonForAll/delete-commom/delete-commom.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { colors } from '../../graph/mock/graph.mock';
import { AgmMarkerCluster, ClusterManager } from '@agm/js-marker-clusterer';
import { InfoWindowManager, MarkerManager } from '@agm/core';
import { endOfMonth, startOfMonth } from 'date-fns';
import { DatePipe } from '@angular/common';
import { CreateTemplateComponent } from './create-template/create-template.component';

@Component({
  selector: 'app-email-setting',
  templateUrl: './email-setting.component.html',
  styleUrls: ['./email-setting.component.scss'],
  providers: [ClusterManager, { provide: MarkerManager, useExisting: ClusterManager }, InfoWindowManager,]
})
export class EmailSettingComponent implements OnInit {
  @ViewChild(AgmMarkerCluster) markerCluster!: AgmMarkerCluster;
  Form: FormGroup;
  collaborationlist=[]
  AccesspermissionUsers=[]
  categorieslist=[]
  sidemenusection=''
  showsettingscreen=true
  allmails=[]
  CollaboratedUsersCategories=[];
  seletedUserindex
  UpdateAccountAsColabrative=false;
  filterforuserlist: any=[];
  applybuttonstatus=false
  onToggleChangeuserdetails: any;
  AllSynchMailAccounts=[]
  data=[]
  Groupmaildata=[]
  graphDimensions: number[] = [900, 250]; 
  colorScheme = colors;
  lat = 51.678418;    // Latitude of the map center
  lng = 7.809007;     // Longitude of the map center
  zoom = 8;          // Zoom level
  chartWidth = '100%'; // You can change this value dynamically
  chartHeight = '300px'; // You can change this value dynamically
  // Example marker data
  markerEvents = [
    { lat: 51.678418, lng: 7.809007, label: 'Marker 1', title: 'This is Marker 1' },
    { lat: 51.678500, lng: 7.809200, label: 'Marker 2', title: 'This is Marker 2' },
    { lat: 51.678700, lng: 7.809400, label: 'Marker 3', title: 'This is Marker 3' },
  ];
  Analytics: FormGroup;
  listoftemplate=[]
  tabnumber: number=0;
  NumberOfRecord: number=8;
  PageNumber: number=1;
  totalTemplates=0
  totalGoupmails=0
  constructor(
    private matDialogRef: MatDialogRef<EmailSettingComponent>,
    private injector: Injector,
    private GoogleApiService:GoogleApiService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http: HttpClient,
     private sanitizer: DomSanitizer,
     public datepipe: DatePipe,   
  ) { 
    this.Analytics = this.formBuilder.group({
      accountid: [''],
      EndDate: [''],
      StartDate: [''],
    });  

  }

  ngOnInit(): void {
    this.getallCategory() 
    this.GetSyncAccDetails()
    this.GetTemplates()
  }
// chart 
// ------ 
onBarClick(event: any) {
  console.log('Bar clicked:', event);
  // You can access the clicked bar's data here
  console.log('Selected item:', event.series);
  const OpenCount=event.name=='Opened'?true:false
  const ClickCount=event.name=='Clicked'?true:false
  const NotOpenedCount=event.name=='not opened'?true:false

  this.injector.get(ManageUsersService).GetEmailDetails(this.Analytics.value.accountid,event?.series,OpenCount,ClickCount,NotOpenedCount).subscribe((result)=>{
     if(result){
    this.barchartlevel2(result,'Barclicked')
    }
  })
}
// -------- 

showtablerowdata(data,Open,Click,NotOpened){
  this.injector.get(ManageUsersService).GetGroupedEmailAnalytics(data?.emailGroupId, this.datepipe.transform(data?.sentDate, 'yyyy-MM-dd'),Open,Click,NotOpened).subscribe((result)=>{
    if(result){
    this.barchartlevel2(result,'Tableclicked')
    }
  })

}

barchartlevel2(value,type) {
    const onDoubleClickDialog = this.dialog.open(PopupCommonComponent, {
      data: { data: value, screenType: type },
      disableClose: false,
      width: '500px',
    });
    onDoubleClickDialog.afterClosed().subscribe((result) => {

    })
  }






// map 
// ----------- 
  // Handle zoom changes
  onZoomChange(event: any) {
    console.log('Zoom level changed:', event);
  }

  // Handle map click
  onMapClick(event: any) {
    console.log('Map clicked:', event);
  }

  // Handle cluster click
  onClusterClick(event: any) {
    console.log('Cluster clicked:', event);
  }

  // Handle marker drag end
  markerDragEnd(marker: any, event: any) {
    console.log('Marker dragged:', marker, event);
  }

  // Icon URL for marker (optional)
  iconURL(marker: any): string {
    return "assets/images/iconsForsvg/icon_red.svg"
  }


// ----------- 


  FindUserCollaborations(AccountId){
    this.injector.get(ManageUsersService).FindUserCollaborations(AccountId).subscribe((res: any) => {
      console.log(res);
      const data = res;
      data.forEach(element => {
        element.checked=false;
      });
      this.filterforuserlist = data;
    });
  }

  nextpage(e: any) {
    this.PageNumber=e.pageIndex + 1
    this.GetTemplates()
  }

  getkeywords(value){
    return value.split(',')
  }

  nextpage1(e: any) {
    this.PageNumber=e.pageIndex + 1
    this.GetGroupedEmailStats()
  }

  GetTemplates(){
    this.injector.get(ManageUsersService).GetTemplates(this.NumberOfRecord,this.PageNumber).subscribe((res: any) => {
      console.log(res);
        if(res){
          this.totalTemplates=res?.totalItems
          if(res.data.lenght!=0){
            this.listoftemplate=[]
            res.data.forEach(element => {
              this.listoftemplate.push(element)
            });
          }
        } 
  })
}


CreateTemplate(){
  const CreateTemplate = this.dialog.open(CreateTemplateComponent, {
    width: '99%',
    autoFocus: false,
    disableClose: true,
    panelClass: 'custom-dialog-container',
  });
  CreateTemplate.afterClosed().subscribe((result) => {})
}


UploadTemplate(){
  const CollaborationDialog = this.dialog.open(PopupCommonComponent, {
    data: {screenType:'templateupload'},
    disableClose: true,
    width: '900px',
  });
  CollaborationDialog.afterClosed().subscribe((result) => {
    if(result){
       console.log(result)
       const formData = new FormData(); 
       formData.append('templateFile', result.file);

       this.injector.get(ManageUsersService).SaveTemplate(result.keywordlist.toString(),formData).subscribe((res: any) => {
        console.log(res);
        this.GetTemplates()
    },error=>{
      if(error.status==200){
        this.GetTemplates()

      }
    })
    }
  })
}


GetEmailAnalyticsStats(){
  console.log(this.Analytics.value)
  const formData = new FormData();
  formData.append('SynchronizedAccountId', this.Analytics.value.accountid);
  formData.append('StartDate', this.Analytics.value.StartDate);
  formData.append('EndDate', this.Analytics.value.EndDate);
  this.data=[]
  this.injector.get(ManageUsersService).GetEmailStats(formData).subscribe((res: any) => {
    if(res.lenght!=0){
      res.forEach(element => {
        this.data.push({
          name: element?.date,
          series: [ 
            { name: 'Opened', value: element?.opened },
            { name: 'Clicked', value: element?.clicked },
            { name: 'Responses', value: element?.responses },
            { name: 'notOpened', value: element?.notOpened },
            { name: 'Sent', value: element?.sent },
          ]
        })
    
      });
    }
    console.log(res);
    console.log(this.data);
})
}

GetGroupedEmailStats(){
  console.log(this.Analytics.value)
  const formData = new FormData();
  formData.append('SynchronizedAccountId', this.Analytics.value.accountid);
  formData.append('StartDate', this.Analytics.value.StartDate);
  formData.append('EndDate', this.Analytics.value.EndDate);
  formData.append('NumberOfRecord', this.NumberOfRecord.toString());
  formData.append('PageNumber', this.PageNumber.toString());
  this.data=[]
  this.injector.get(ManageUsersService).GetGroupedEmailStats(formData).subscribe((res: any) => {
   if(res){
    if(res.data.lenght!=0){
      this.totalGoupmails=res.totalItems
      this.Groupmaildata=res.data
     }
   }
})
}



onSubmit(){
  this.GetEmailAnalyticsStats()
}


viewtemplate(fileUrl){
  window.open(fileUrl, '_blank');

}

DeleteTemplate(data){
  const deleteorder = this.dialog.open(DeleteCommomComponent, {
    data: { data, type: 'DeleteTemplate' },
  });
  deleteorder.afterClosed().subscribe((result: any) => {
this.GetTemplates()
  });
}


downloadTemplate(fileUrl){
  const link = document.createElement('a');  // Create a download link
  link.href = fileUrl  // Set the file URL
  link.download = 'Template';  // Optionally, set the file name if you want
  link.click();  // Trigger the download
}




  applybutton(){
    if(this.applybuttonstatus==false){
      this.applybuttonstatus=true
    }

  }

  showmailusers(data){
    if(data.isCollabrative==true){
      this.onToggleChangeuserdetails=data
      this.UpdateAccountAsColabrative=true
      this.FindUserCollaborations(data.id)
    }else{
      this.UpdateAccountAsColabrative=false
    }
  }

  gethoverclass(x){

    if(x.id==this.onToggleChangeuserdetails?.id){
      return 'Readedclass'
    }else{
      return 'normalclass'
    }
  }

  GetSyncAccDetails(){
    this.GoogleApiService.GetAllSynchMailAccounts().subscribe((result:any)=>{
      if(result.lenght!=0){
        this.AllSynchMailAccounts=result
        this.collaborationlist=result
        this.onToggleChangeuserdetails=this.collaborationlist[0]
        if(this.collaborationlist[0].isCollabrative==true){
          this.UpdateAccountAsColabrative=true;
          this.FindUserCollaborations(this.collaborationlist[0].id)
        }
        const viewDate = new Date();
        let startDateInitial = new Date(viewDate);
        const startDate = startOfMonth(startDateInitial);
        const endDate = endOfMonth(startDateInitial);
        this.Analytics.get('accountid').setValue(result[0].id)
        this.Analytics.get('StartDate').setValue(this.datepipe.transform(startDate, 'yyyy-MM-dd'))
        this.Analytics.get('EndDate').setValue(this.datepipe.transform(endDate, 'yyyy-MM-dd'))
        this.GetEmailAnalyticsStats()
        this.GetGroupedEmailStats()

        this.allmails=[]
        result.forEach(element => {
          if(element.isCollabrative==true){
            this.allmails.push(element)
          }
        });
      }
    })
  }

  onToggleChange(event,details){
    console.log(event.checked)
    console.log(details)
    this.onToggleChangeuserdetails=details
    const CollaborationDialog = this.dialog.open(PopupCommonComponent, {
      data: { data:details, screenType: event.checked==true?'Collaboration_Add':'Collaboration_Remove' },
      disableClose: true,
      width: '400px',
    });
    CollaborationDialog.afterClosed().subscribe((result) => {
      if(result==true){
        this.UpdateAccountAsColabrative=true
      }
      if(event.checked==false){
        this.GoogleApiService.UpdateAccountAsColabrative(details.id,event.checked,result).subscribe((result)=>{
          if(result){
            this.snackBar.open('Update Collaboration done', '', { duration: 2000, });
            this.GetSyncAccDetails()
          }
        },error=>{
          if(error.status=200){
            this.snackBar.open('Update Collaboration done ', '', { duration: 2000, });
            this.GetSyncAccDetails()
          }else{
            this.snackBar.open('Update Collaboration failed', '', { duration: 2000, });
          }
        })
      }
    })
  }

  onSubmiaccess(){
    let selectedUsers=[]
    this.filterforuserlist.forEach(element => {
      if(element.isCollaborated==true){
        selectedUsers.push(element.userId)
      }
    });
    this.GoogleApiService.UpdateAccountAsColabrative(this.onToggleChangeuserdetails.id,true,selectedUsers).subscribe((result)=>{
      if(result){
        this.snackBar.open('Update Collaboration done', '', { duration: 2000, });
        this.GetSyncAccDetails()
      }
    },error=>{
      if(error.status=200){
        this.snackBar.open('Update Collaboration done', '', { duration: 2000, });
        this.GetSyncAccDetails()
      }else{
        this.snackBar.open('Update Collaboration failed', '', { duration: 2000, });
      }
    })
  }

  onToggleChange2(event,details,index){
    this.seletedUserindex=index
    console.log(event.checked)
    console.log(details)
    if(event.checked){
      this.GoogleApiService.GetCollaboratedUsersCategories(details.id).subscribe((result:any)=>{
        if(result){
          this.CollaboratedUsersCategories=result
          console.log(result)
        }
      })

    }

  }

  getallCategory(){
    this.injector.get(ManageUsersService).GetAllCategories().subscribe((result:any)=>{
      if(result){
        this.categorieslist=result
      }
    })
  }

  siddemenu(value){
    if(this.sidemenusection==value.email){
      return 'siddemenucolor'
    }else{
      return 'siddemenu'
    }
  }
  sidemenuclick(value){
    this.sidemenusection=value.email
    this.injector.get(ManageUsersService).FindCollaborativeUserOftheAccount(value.id).subscribe((result:any)=>{
      if(result){
        this.AccesspermissionUsers=result
      }
    })
  }

  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  } 
  
 
  closepopup(){
      this.matDialogRef.close()
  }

  addCategory(){
    const CreateCategoryDialog = this.dialog.open(CreateCategoryComponent, {
      data: { screenType: 'CreateCategory' },
      disableClose: true,
      width: '500px',
    });
    CreateCategoryDialog.afterClosed().subscribe((result) => {
      this.getallCategory()
    });
  }

  Categorydetails(details){
    const EditCategoryDialog = this.dialog.open(CategoryDetailsComponent, {
      data: {details:details, screenType: 'Categorydetails' },
      disableClose: true,
      width: '500px',
    });
    EditCategoryDialog.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  Categoryedit(details){
    const EditCategoryDialog = this.dialog.open(CreateCategoryComponent, {
      data: {details:details, screenType: 'EditCategory' },
      disableClose: true,
      width: '500px',
    });
    EditCategoryDialog.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  Categorydelete(details){
    const deleteorder = this.dialog.open(DeleteCommomComponent, {
      data: { details, type: 'Categorydelete' },
    });
    deleteorder.afterClosed().subscribe((result: any) => {
      this.getallCategory() 
    });


  }

  tabChange(tabIndex: number): void {
    this.tabnumber = tabIndex;
  }


}
