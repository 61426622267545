import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserDetailsComponent } from '../../management/manage-users/user-details/user-details.component';
import { ReminderCreateComponent } from '../reminder-create/reminder-create.component';
import { DeleteCommomComponent } from '../../commonForAll/delete-commom/delete-commom.component';

@Component({
  selector: 'app-reminder-view',
  templateUrl: './reminder-view.component.html',
  styleUrls: ['./reminder-view.component.scss']
})
export class ReminderViewComponent implements OnInit {
  reminder: any;
  showReminder: boolean = true;
  selectedUsers: any;
  RemindersselectedUsers: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialog: MatDialog,
  private matDialogRef: MatDialogRef<ReminderViewComponent>,) { }

  ngOnInit(): void {
    console.log(this.data)
    console.log(this.data.selectedUsers)
    this.findSelectedUsersId()
    this.reminder = this.data.data
  }

  findReminderLinked(count:any){
    if (count > 0) {
     this.showReminder = !this.showReminder
    }
  }

  ShowUserDetails(row:any, type: string) {
    this.dialog.open(UserDetailsComponent, {
      disableClose: true,
      data: { data: row, type: type },
      width: '500px',
      position: { top: '150px', left: '700px' },

    });
  }

  updatereminderDialog(row:any, type:any) {
    const reminderUpdate = this.dialog.open(ReminderCreateComponent, {
      disableClose: true,
      data: { data: row, screenType: type , type: this.data.type, selectedUsers: this.selectedUsers, EventId: this.data.eventId},
      width: '500px',

    });

    reminderUpdate.afterClosed().subscribe((result) => {
      if (result) {
        this.matDialogRef.close(result);
      }
    });
  }

  deletereminderDialog(row:any, type:any){
    const deletereminder = this.dialog.open(DeleteCommomComponent, {
      disableClose: true,
      data: { data: row, type: type,Entity:this.data.type},
      width: '500px',
    });
    deletereminder.afterClosed().subscribe((result) => {
      if (result) {
        this.matDialogRef.close(true);
      }
    });
  }

  findSelectedUsersId() {
    if(this.data.type == 'Event') {
      const ids = this.data?.selectedUsers?.linkedEventUserTableDtos.map(user => user.id);
      const idsString = ids?.join(', ');
      this.selectedUsers = idsString
    } else if (this.data.type == 'Initiatives' || this.data.type === 'Task') {
      const ids = this.data?.selectedUsers?.map(user => user.id);
      const idsString = ids?.join(', ');
      this.selectedUsers = idsString
    } 
    
  }


}
