import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MicrosoftServicesService {
  clientId=environment.mircosoftclintId
outlookscope='https://graph.microsoft.com/Mail.Read offline_access User.Read profile email openid Mail.ReadWrite.Shared MailboxSettings.ReadWrite'
Calendarscope='https://graph.microsoft.com/Calendars.Read offline_access User.Read profile email openid'
onedrivescope='https://graph.microsoft.com/Files.Read offline_access User.Read profile email openid'
constructor(

  ) { }



  outlookloginpage() {
    const scope =this.outlookscope
    const redirectUrlWithPrompt = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${this.clientId}&scope=${scope}&response_type=code&redirect_uri=${encodeURIComponent(environment.MicrosoftredirectUri)}`
   return redirectUrlWithPrompt
  }

  Calendarloginpage() {
    const scope = this.Calendarscope; 
    const redirectUrlWithPrompt = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${this.clientId}&scope=${encodeURIComponent(scope)}&response_type=code&redirect_uri=${encodeURIComponent(environment.MicrosoftredirectUri)}`;
   return redirectUrlWithPrompt
  }
  onedriveloginpage() {
    const scope = this.onedrivescope; 
    const redirectUrlWithPrompt = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${this.clientId}&scope=${scope}&response_type=code&redirect_uri=${encodeURIComponent(environment.MicrosoftredirectUri)}`
   return redirectUrlWithPrompt
  }


  outlook_Calendar_loginpage(){
    const scope =this.outlookscope+' '+this.Calendarscope
    const redirectUrlWithPrompt = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${this.clientId}&scope=${scope}&response_type=code&redirect_uri=${encodeURIComponent(environment.MicrosoftredirectUri)}`
   return redirectUrlWithPrompt
  }
  outlook_onedrive_loginpage(){
    const scope =this.outlookscope+' '+this.onedrivescope
    const redirectUrlWithPrompt = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${this.clientId}&scope=${scope}&response_type=code&redirect_uri=${encodeURIComponent(environment.MicrosoftredirectUri)}`
   return redirectUrlWithPrompt
  }
  outlook_onedrive_Calendar_loginpage(){
    const scope =this.outlookscope+' '+this.onedrivescope+' '+this.Calendarscope
    const redirectUrlWithPrompt = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${this.clientId}&scope=${scope}&response_type=code&redirect_uri=${encodeURIComponent(environment.MicrosoftredirectUri)}`
   return redirectUrlWithPrompt
  }

}
