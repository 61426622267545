import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-schedule-add',
  templateUrl: './schedule-add.component.html',
  styleUrls: ['./schedule-add.component.scss']
})
export class ScheduleAddComponent implements OnInit {
  selectedDate=new Date();
  selectedTime=new Date().getHours()+':'+new Date().getMinutes();
  selectedDateTime: string = 'No date and time selected yet';

  constructor(
    private matDialogRef: MatDialogRef<ScheduleAddComponent>,

  ) { }

  ngOnInit(): void {
    this.updateSelectedDateTime()
  }


  AddSchedule(){
    console.log(this.selectedDate)
    console.log(this.selectedTime)
    const data={
      date:this.selectedDate,
      time:this.selectedTime
    }
    this.matDialogRef.close(data)
  }


  // Function to update the selected date and time
  onDateChange() {
    this.updateSelectedDateTime();
  }

  onTimeChange() {
    this.updateSelectedDateTime();
  }

  private updateSelectedDateTime() {
    if (this.selectedDate && this.selectedTime) {
      this.selectedDateTime = `Date: ${this.selectedDate.toLocaleDateString()}, Time: ${this.selectedTime}`;
    } else {
      this.selectedDateTime = 'No date and time selected yet';
    }
  }

}
