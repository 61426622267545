<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100" *ngIf="data?.type == 'Create'">
        {{'Add Project'|translate}}
    </h2>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100" *ngIf="data?.type == 'Edit'">
      {{'Edit Project'|translate}}
  </h2>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100" *ngIf="data?.type == 'TimeEntry'">
      {{'Add Entry'|translate}}
  </h2>
  <h2 mat-dialog-title class="m-0 dialog-title text-center w-100" *ngIf="data?.type == 'ClockIn' || data?.type == 'ClockOut'">
    {{'Add Confirmation'|translate}}
</h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
  <ng-container *ngIf="data?.type == 'Create' || data?.type == 'Edit'">
    <form [formGroup]="ProjectForm">
      <div class="row mt-2">
        <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
          <mat-form-field class="w-100">
              <mat-label>{{"Project Name"}} <span style="color: red;">*</span></mat-label>
              <input matInput type="text" formControlName="ProjectName">
          </mat-form-field>
          <mat-error *ngIf="ProjectForm.controls['ProjectName'].hasError('required')">
            {{"Project name"}} is <strong>{{"required"}}</strong>
        </mat-error>
      </div>
      </div>
      <div class="col-md-5">
        <mat-label>{{'Color'|translate}} <span class="danger">* </span></mat-label>
        <input formControlName="color" [style.background]="color1" style="border:none" [(colorPicker)]="color1"
          (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
          (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
  
        <mat-label>{{'Text Color'|translate}} <span class="danger">* </span></mat-label> 
        <input formControlName="fontColor" [style.background]="color2" style="border:none" [(colorPicker)]="color2"
          (colorPickerOpen)="onEventLog1('colorPickerOpen', $event)"
          (colorPickerClose)="onEventLog1('colorPickerClose', $event)" />
      </div>
    </form>
    <div class="col-12 mb-3" style="text-align:end" *ngIf="data.type == 'Create'">
        <button mat-raised-button [ngClass]="!ProjectForm.valid? 'g-blue':'b-blue'"[disabled]="!ProjectForm.valid"
        (click)="AddNewProject()">{{"Add"}}</button>
    </div>
    <div class="col-12 mb-3" style="text-align:end" *ngIf="data.type == 'Edit'">
      <button mat-raised-button [ngClass]="!ProjectForm.valid? 'g-blue':'b-blue'"[disabled]="!ProjectForm.valid"
      (click)="AddNewProject()">{{"Edit"}}</button>
  </div>
  </ng-container>
   <ng-container *ngIf="data?.type == 'TimeEntry'">
    <ng-container *ngIf="data?.Mode == 'StartEndTime'">
      <div class="row mt-3">
        <div class="col-12">
          <span>{{formattedDate}}</span>
        </div>
      </div>
        <hr>
        <form [formGroup]="timeEntryForm">
          <div formArrayName="Conditions">
            <!-- Loop through form array items -->
            <div *ngFor="let condition of Conditions().controls; let i = index" [formGroupName]="i">
              <div class="row">
                <!-- Start Time Field -->
                <div class="col-3">
                  <div class="form-group">
                    <label for="startTime">{{"Start Time" | translate}}</label>
                    <input type="time" class="form-control" id="meeting-time" formControlName="startTime">
                  </div>
                </div>
      
                <!-- End Time Field -->
                <div class="col-3">
                  <div class="form-group">
                    <label for="endTime">{{"End Time" | translate}}</label>
                    <input type="time" class="form-control" id="meeting-time" formControlName="endTime">
                  </div>
                </div>
      
                <!-- Project Select Field -->
                <div class="col-4">
                  <div class="form-group">
                    <label for="project">{{"Select Project" | translate}}</label>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select id="project" formControlName="project" placeholder="Choose Project">
                        <mat-option *ngFor="let x of Projects?.data" [value]="x.id">
                          <span [ngStyle]="{'color': x.fontColor, 'background': x.color}" style="padding:6px;border-radius: 5px;">{{x?.projectName }}</span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
      
                <!-- Remove Button -->
                <div class="col-2" style="margin:auto">
                  <mat-icon *ngIf="i!=0" (click)="RemoveCondition(i)">delete</mat-icon>
                </div>

              </div>
              <div class="row">
                <div class="col-12">
                  <label for="note">Add Comment</label>
                  <mat-form-field appearance="outline" class="w-100">
                    <textarea matInput formControlName="comment" id="note" rows="3" placeholder="Enter note"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Add Time Entry Button -->
           <div class="row mt-2 mb-3 mx-2" >
            <div class="col-8" style="text-align: start;">
              <span (click)="Addcondition()" style="text-decoration: underline; cursor: pointer; color: cornflowerblue">+ Add Entry</span>
            </div>
            <div class="col-4" style="text-align: end;">
              
            </div>
           </div>
           <hr style="border-bottom: 2px solid darkgreen;">
           <div class="row mb-3">
            <div class="col-12" style="text-align: start;">
              <button (click)="addEntry(data?.Mode)" mat-raised-button [ngClass]="!timeEntryForm.valid? 'g-blue':'b-blue'"[disabled]="!timeEntryForm.valid"
              >{{"Save"|translate}}</button>
              <button class="mx-2" mat-raised-button mat-dialog-close>{{"Cancel"|translate}}</button>
            </div>
           </div>
        </form>
    </ng-container>
   <ng-container *ngIf="data?.Mode == 'HoursPerDay'">
    <div class="row mt-3">
      <div class="col-12">
        <span>{{formattedDate}}</span>
      </div>
    </div>
      <hr>
      <form [formGroup]="hourEntryForm">
        <div formArrayName="Conditions">
          <!-- Loop through form array items -->
          <div *ngFor="let condition of Conditions1().controls; let i = index" [formGroupName]="i">
            <div class="row">
              <!-- Start Time Field -->
              <div class="col-3">
                <div class="form-group">
                  <label for="startTime">{{"Hours Worked" | translate}}</label>
                  <input type="text" class="form-control" formControlName="hours">
                </div>
              </div>

              <!-- Project Select Field -->
              <div class="col-4">
                <div class="form-group">
                  <label for="project">{{"Select Project" | translate}}</label>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-select id="project" formControlName="project" placeholder="Choose Project">
                      <mat-option *ngFor="let x of Projects?.data" [value]="x.id">
                        <span [ngStyle]="{'color': x.fontColor, 'background': x.color}" style="padding:6px;border-radius: 5px;">{{x?.projectName }}</span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
    
              <!-- Remove Button -->
              <div class="col-2" style="margin:auto">
                <mat-icon *ngIf="i!=0" (click)="RemoveCondition1(i)">delete</mat-icon>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="note">Add Comment</label>
                <mat-form-field appearance="outline" class="w-100">
                  <textarea matInput formControlName="comment" id="note" rows="3" placeholder="Enter note"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Add Time Entry Button -->
         <div class="row mt-2 mb-3 mx-2" >
          <div class="col-8" style="text-align: start;">
            <span (click)="Addcondition1()" style="text-decoration: underline; cursor: pointer; color: cornflowerblue">+ Add Entry</span>
          </div>
          <div class="col-4" style="text-align: end;">
           
          </div>
         </div>
         <hr style="border-bottom: 2px solid darkgreen;">
         <div class="row mb-3">
          <div class="col-12" style="text-align: start;">
            <button (click)="addEntry(data?.Mode)" mat-raised-button [ngClass]="!hourEntryForm.valid? 'g-blue':'b-blue'"[disabled]="!hourEntryForm.valid"
            >{{"Save"|translate}}</button>
            <button class="mx-2" mat-raised-button mat-dialog-close>{{"Cancel"|translate}}</button>
          </div>
         </div>
      </form>
   </ng-container>
      
   </ng-container>
   <ng-container *ngIf="data.type == 'ClockOut'">
    <form [formGroup]="ConfrimForm">
      <div class="row mt-2">
        <div class="col-12">
          <div class="form-group">
            <label for="project">{{"Select Project" | translate}}</label>
            <mat-form-field appearance="outline" class="w-100">
              <mat-select id="project" formControlName="project" placeholder="Choose Project">
                <mat-option *ngFor="let x of Projects?.data" [value]="x.id">
                  <span [ngStyle]="{'color': x.fontColor, 'background': x.color}" style="padding:6px;border-radius: 5px;">{{x?.projectName }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12">
          <label for="note">Add Comment</label>
          <mat-form-field appearance="outline" class="w-100">
            <textarea matInput formControlName="comment" id="note" rows="3" placeholder="Enter note"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div class="row mb-3">
      <div class="col-12" style="text-align: end;">
        <button (click)="AddComment()" mat-raised-button [ngClass]="!ConfrimForm.valid? 'g-blue':'b-blue'"[disabled]="!ConfrimForm.valid"
        >{{"Save"|translate}}</button>
        <button class="mx-2" mat-raised-button mat-dialog-close>{{"Cancel"|translate}}</button>
      </div>
     </div>
   </ng-container>
   <ng-container *ngIf="data.type == 'ClockIn'">
    <form [formGroup]="CancelForm">
      <div class="row mt-2">
        <div class="col-12">
          <label for="note">Add Comment</label>
          <mat-form-field appearance="outline" class="w-100">
            <textarea matInput formControlName="comment" id="note" rows="3" placeholder="Enter note"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div class="row mb-3">
      <div class="col-12" style="text-align: end;">
        <button (click)="AddComment()" mat-raised-button [ngClass]="!CancelForm.valid? 'g-blue':'b-blue'"
        >{{"Save"|translate}}</button>
        <button class="mx-2" mat-raised-button mat-dialog-close>{{"Cancel"|translate}}</button>
      </div>
     </div>
   </ng-container>
  </mat-dialog-content>

