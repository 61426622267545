<div class="skeleton-loader">
    <div class="skeleton-header"></div>
    <div class="skeleton-item" *ngFor="let item of skeletonItems">
      <div class="skeleton-avatar"></div>
      <div class="skeleton-content">
        <div class="skeleton-line skeleton-line-title"></div>
        <div class="skeleton-line skeleton-line-description"></div>
        <div class="skeleton-line skeleton-line-small"></div>
      </div>
    </div>
  </div>
  