import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-time-reporting',
  templateUrl: './time-reporting.component.html',
  styleUrls: ['./time-reporting.component.scss']
})
export class TimeReportingComponent implements OnInit {


  shiftsAvailable = [2, 1, 1, 2, 2];
  dates = ['Jan 31', 'Feb 01', 'Feb 02', 'Feb 03', 'Feb 04'];

  metricsData = [
    { hours: '298h/185h', additionalHours: '5165c', hoursColor: 'rgb(254, 103, 103)', additionalHoursColor: 'orange' },
    { hours: '298h/185h', additionalHours: '5165c', hoursColor: 'rgb(254, 103, 103)', additionalHoursColor: 'orange' },
    { hours: '298h/185h', additionalHours: '5165c', hoursColor: 'rgb(254, 103, 103)', additionalHoursColor: 'orange' },
    { hours: '298h/185h', additionalHours: '5165c', hoursColor: 'rgb(254, 103, 103)', additionalHoursColor: 'orange' },
    { hours: '298h/185h', additionalHours: '5165c', hoursColor: 'rgb(254, 103, 103)', additionalHoursColor: 'orange' },
    { hours: '298h/185h', additionalHours: '5165c', hoursColor: 'rgb(254, 103, 103)', additionalHoursColor: 'orange' },
  ];
    teamMembers: any[] = [
      {
          firstName: 'Alice',
          lastName: 'Johnson',
          role: 'Developer',
          hours: '298h/185h',
          NeededApproval: '2',
          additionalHours: '+10h',
          totalHours: '38hr',
          dailyEntries: [
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-1', color: '#0E934A' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-4', color: '#0E924D' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-5', color: '#0E2703' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
         
          ]
      },
      {
          firstName: 'Bob',
          lastName: 'Smith',
          role: 'Designer',
          NeededApproval: '1',
          hours: '250h/200h',
          additionalHours: '+8h',
          totalHours: '40hr',
          dailyEntries: [
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-1', color: '#0E934A' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-4', color: '#0E2703' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-5', color: '#0E924D' },
            
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
          ]
      },
      {
          firstName: 'Charlie',
          lastName: 'Davis',
          role: 'Project Manager',
          hours: '300h/180h',
          NeededApproval: '2',
          additionalHours: '+12h',
          totalHours: '42hr',
           dailyEntries: [
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-1', color: '#072760' },
           
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-5', color: '#0E2703' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#0E924D' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-6', color: '#3880FF' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#0E934A' },
          ]
      },
      {
          firstName: 'Diana',
          lastName: 'Wilson',
          role: 'QA Engineer',
          hours: '320h/150h',
          NeededApproval: '3',
          additionalHours: '+5h',
          totalHours: '35hr',
          dailyEntries: [
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-1', color: '#072760' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-4', color: '#0E924D' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#0E934A' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-5', color: '#3880FF' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
           
          ]
      },
      {
          firstName: 'Edward',
          lastName: 'Martinez',
          role: 'Data Scientist',
          hours: '270h/200h',
          NeededApproval: '5',
          additionalHours: '+6h',
          totalHours: '36hr',
           dailyEntries: [
           
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#3880FF' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-5', color: '#0E2703' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#0E934A' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-6', color: '#072760' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
          ]
      },
      {
          firstName: 'Fiona',
          lastName: 'Garcia',
          role: 'Frontend Developer',
          hours: '290h/190h',
          NeededApproval: '4',
          additionalHours: '+9h',
          totalHours: '37hr',
          dailyEntries: [
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-6', color: '#3880FF' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-1', color: '#0E934A' },
            
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-5', color: '#0E2703' },
         
          ]
      },
      {
          firstName: 'George',
          lastName: 'Brown',
          role: 'Backend Developer',
          hours: '310h/170h',
          NeededApproval: '3',
          additionalHours: '+11h',
          totalHours: '39hr',
          dailyEntries: [
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-1', color: '#0E934A' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-5', color: '#0E2703' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-4', color: '#0E924D' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
          
          ]
      },
      {
          firstName: 'Hannah',
          lastName: 'Smith',
          role: 'UI/UX Designer',
          hours: '280h/160h',
          NeededApproval: '1',
          additionalHours: '+7h',
          totalHours: '38hr',
           dailyEntries: [
          
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-6', color: '#3880FF' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-5', color: '#0E2703' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
          
          ]
      },
      {
          firstName: 'Ian',
          lastName: 'Johnson',
          role: 'System Analyst',
          hours: '250h/210h',
          NeededApproval: '4',
          additionalHours: '+4h',
          totalHours: '34hr',
           dailyEntries: [
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-5', color: '#0E2703' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-6', color: '#3880FF' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
         
          ]
      },
      {
          firstName: 'Julia',
          lastName: 'Martinez',
          role: 'DevOps Engineer',
          hours: '300h/180h',
          NeededApproval: '2',
          additionalHours: '+10h',
          totalHours: '39hr',
           dailyEntries: [
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-1', color: '#0E934A' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-4', color: '#0E924D' },
            
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-6', color: '#3880FF' },
            { time: '09:00 - 18:00', hours: '8h', lunch: '1h', taskName: 'Room-2', color: '#072760' },
          ]
      }
  ];

  ManagerApproval: FormGroup


  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.ManagerApproval = this.fb.group({
      ApproveAll: [false],
      ApproveSingle: [false],
      ApproveDate: [false]
    });
  }

}
