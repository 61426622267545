import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ManageContactsService } from 'src/app/core/services/manage-contacts.service';
import { ManageProductsService } from 'src/app/core/services/manage-products.service';
import { ManageSitesService } from 'src/app/core/services/manage-sites.service';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';

@Component({
  selector: 'app-advance-search-in-activity-log',
  templateUrl: './advance-search-in-activity-log.component.html',
  styleUrls: ['./advance-search-in-activity-log.component.scss']
})
export class AdvanceSearchInActivityLogComponent implements OnInit {

  ActivitySearch!: FormGroup
  filteredContacts: any;
  contacts$: any;
  filteredSites: any;
  sites$: any;
  products$: any;
  filteredProducts: any;
  filteredusers: any;
  users$: any;
  AllOption = [{ id: 1, name: 'Users' }, { id: 2, name: 'Contacts' }, { id: 3, name: 'Sites' },
    { id: 4, name: 'Products' }, { id: 5, name: 'Transactions' }, { id: 6, name: 'Tasks' }, { id: 7, name: 'Initiatives' }];
    FileFormat = [{id:1, name: 'Png'}, {id: 2, name: 'Svg'}, {id: 2, name: 'docx'}, {id: 3, name: 'jpg'},
      {id: 4, name: 'xlsx'},  {id: 5, name: 'avi'}, {id: 6, name: 'pdf'},{id: 7, name: 'powerpoint'},
      {id: 8, name: 'html'}, {id: 9, name: 'csv'}
    ]
  constructor(
    private formBuilder: FormBuilder,
    private injector: Injector,
  ) { }

  ngOnInit(): void {
    this.ActivitySearch = this.formBuilder.group({
      Entity:[null],
      Name: [''],
      StartDate: [],
      EndDate: [],
      UsersId:[''],
      ContactsId: [''],
      SitesId: [''],
      ProductsId: [''],
      fileFormat: [null]
    });

    this.getallusers()
    this.getallContacts()
    this.getallSites()
    this.getallProducts()
  }

  getallusers(){
    // get all users
    this.injector.get(ManageUsersService).findAllUsersDropdown().subscribe((result) => {
      if (result) {
        this.users$ = result;
        this.filteredusers = result;
      }
    });
  } 

  getallContacts(){
    // get all contacts
    this.injector.get(ManageContactsService).findAllContactsDropdown().subscribe((result) => {
      if (result) {
        this.contacts$ = result;
        this.filteredContacts = result;
      }
    });
  } 

  getallSites(){
    // get all sites
    this.injector.get(ManageSitesService).findAllSitesDropdown().subscribe((result) => {
      if (result) {
        this.sites$ = result;
        this.filteredSites = result;
      }
    });
  } 
  getallProducts(){
    // get all sites
    this.injector.get(ManageProductsService).findAllProductsDropdown().subscribe((result) => {
      if (result) {
        this.products$ = result;
        this.filteredProducts = result;
      }
    });
  } 
  filterOptions(value: string, type: string): void {
    console.log(type);
      this.filteredContacts = this.contacts$.filter((option:any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
      this.filteredSites = this.sites$.filter((option:any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
      this.filteredProducts = this.products$.filter((option:any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
      this.filteredusers = this.users$.filter((option:any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
  }

  submit(){

  }

}
