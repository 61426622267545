<div class="d-flex flex-row align-items-center modal-header">
    <h2  mat-dialog-title class="m-0 dialog-title text-center w-100">
      <img src="../../../../assets/images/iconsForsvg/remainder.svg"> {{'View Reminder'|translate}}
    </h2>
    <mat-icon mat-dialog-close >close</mat-icon>
  </div>

  <mat-dialog-content id="create-strategy" class="m-0">
    <div class="row w-100">
        <div class="col-10 mb-4 mt-3" style="text-align: center;">
            <span *ngIf="reminder.reminderTypeName=='Email'"><mat-icon style="font-size: 50px;"> email</mat-icon></span>
                 <span *ngIf="reminder.reminderTypeName=='Notification'"><mat-icon style="font-size: 50px;">campaign</mat-icon></span>
        </div>
        <div class="col-2 mt-4" style="text-align: center;">
          <button [matMenuTriggerFor]="beforeMenu" style="display: flex;" class="mr-1 b-color">
            <mat-icon class="icon-options">more_vert</mat-icon>
        </button>
        <mat-menu #beforeMenu="matMenu" xPosition="before">
          <button class="fw-600" mat-menu-item
              (click)="updatereminderDialog(reminder, 'updateReminder')">
              <mat-icon>edit</mat-icon> {{'Edit'|translate}}
          </button>
          <button class="fw-600" mat-menu-item
          (click)="deletereminderDialog(reminder.id, 'deleteReminder')">
              <mat-icon>delete</mat-icon> {{'Delete'|translate}}
          </button>
          </mat-menu>
        </div>
        <div class="row m-0">
          <div class="col-12" style="text-align: center;">
            <span class="heading-view">{{reminder.value}} {{reminder.periodName}} {{reminder.isAfter ? 'After' : 'before'}} the  {{this.data.type}} {{reminder.conditionParameter}} date and time</span>
          </div>
        </div>
    </div>
    <div class="row mt-4 mb-3">
      <mat-list-item>
        <div class="fw-600 mt-1">
            <mat-icon class="vertical-bottom" style="color: red;">person</mat-icon>
            {{'Users'|translate}} :
        </div>
        <span class="ml-3 fw-600 color_grey mt-2 pointer"
            [ngClass]="{'color_grey': reminder?.users.length > 0,'name_red': reminder?.users.length == 0 }"
            (click)="findReminderLinked(reminder?.users.length)">
            {{reminder?.users.length}} {{reminder?.users.length > 1 ? 'Users' : 'User'}}</span>
           
    </mat-list-item>
    </div>
  <div class="col-10 m-b-10  " *ngIf="showReminder"
  [ngClass]="{'auto_height': reminder?.users.length > 4,'overflow': reminder?.users.length < 4 }">
  <div class="row m-t-10" *ngFor="let user of reminder?.users ; let i = index">
      <div>
          <span><img style="border-radius: 50%; cursor: pointer;" width="30" height="30"
                  (click)="ShowUserDetails(user, 'user')"
                  [src]="user.profilePicture ? user.profilePicture :'assets/avatar.svg'"></span>
          <span class="p-l-10 fw-600 color_grey" style="cursor: pointer;"
              (click)="ShowUserDetails(user, 'user')">
              {{user.firstName|titlecase}} {{user.lastName |titlecase}}</span>
      </div>
  </div>
</div>
   

  </mat-dialog-content>
