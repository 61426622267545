import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent implements OnInit {

  skeletonItems = [1, 2, 3, 4];  // Number of skeleton items to simulate
  
  constructor() { }

  ngOnInit(): void {
  }

}
