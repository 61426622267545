<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span *ngIf="screenType=='CreatePermission'">{{"Create Permission"|translate}}</span>
        <span *ngIf="screenType=='UpdatePermission'">{{"Update Permission"|translate}}</span>
    </h2>
    <mat-icon mat-dialog-close (click)="closedialogbox()">close</mat-icon>
</div>

<mat-dialog-content class=" pading permissiondialog" style="padding: 0px  !important; margin: 0px ;">
    <form [formGroup]="permission">
        <ng-container *ngIf="screenType=='CreatePermission'">
            <div class="row mt-2">
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6"> 
                    <mat-form-field class="w-100">
                        <mat-label>{{"Apply to other users"|translate}}</mat-label>
                        <mat-select multiple formControlName="SelactIds">
                            <mat-option [value]="list.id" *ngFor="let list of getAllSingle"
                                (click)="Applytootherusers()">
                                <img class="avatar b-r-20"
                                    [src]="list?.profilePicture ? list.profilePicture:'assets/avatar.svg'"
                                    onError="this.src='assets/avatar.svg'">
                                {{list.firstName| titlecase}} {{list.lastName| titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field class="w-100">
                        <mat-label>{{"Apply to other Groups"|translate}}</mat-label>
                        <mat-select multiple formControlName="GroupIds">
                            <mat-option [value]="group.id" *ngFor="let group of getAllGroups"
                                (click)="ApplytootherGroups()">
                                <mat-icon
                                    [ngStyle]="{'color':(group.dynamicGroupId ==null)?'red':'blue'}">group</mat-icon>
                                <span> {{group.name| titlecase}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="screenType=='UpdatePermission'">
            <div class="row mt-2 justify-content-start">
                <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
                    <mat-form-field class="w-100">
                        <mat-label>{{'Apply to other users'|translate}}</mat-label>
                        <mat-select disabled multiple formControlName="SelactIds" style="color: black;">
                            <mat-option [value]="list.id" *ngFor="let list of getAllSingle"
                                (click)="Applytootherusers()">
                                <img class="avatar b-r-20"
                                    [src]="list?.profilePicture ? list.profilePicture:'assets/avatar.svg'"
                                    onError="this.src='assets/avatar.svg'">
                                {{list.firstName| titlecase}} {{list.lastName| titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
        <div class="row w-100">
            <div class="col-6 col-sm-6  col-md-6 col-lg-8 col-xl-8">
                <mat-form-field class="w-100">
                    <mat-label> {{"Parameters"|translate}}</mat-label>
                    <mat-select multiple formControlName="parameters">
                        <mat-option *ngFor="let list of permisiondata; let i = index" [value]="list">
                            {{list.name|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-6  col-md-6 col-lg-4 col-xl-4" *ngIf="type != 'Transactions'">
                <h3 style="margin-top: 10px;">Selected {{type|titlecase}} : {{uniqueUserList.length}} </h3>
            </div>
        </div>
        <div class="row justify-content-start" *ngIf="type !='Transactions'">
            <div class="col-8 col-sm-8  col-md-8 col-lg-6 col-xl-6">
                <mat-form-field class="w-100">
                    <mat-label>{{"Search"|translate}}</mat-label>
                    <input matInput [(ngModel)]="SearchValue" placeholder="Search"
                        [ngModelOptions]="{standalone: true}">
                    <mat-icon matSuffix class="pointer searchicon " (click)="applysearch(SearchValue)">
                        search
                    </mat-icon>
                </mat-form-field>
            </div>
            <div class="col-4 col-sm-4  col-md-4 col-lg-4 col-xl-4">
                <button matPrefix class="mr-1 mt-2 w-100 pointer height-32 removefilter" *ngIf="showicon == true"
                    (click)="searchclose()">{{"Remove Filter"|translate}}</button>
            </div>
        </div>

        <div class="row w-100" *ngIf="type!='Transactions'">
            <div class="col-10">
                <mat-radio-group formControlName="SelactGroups" aria-label="Select an option">
                    <mat-radio-button [value]="false">{{"Single"|translate}}</mat-radio-button>
                    <mat-radio-button [value]="true">{{"Groups"|translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <ng-container *ngIf="getgroupvalue()==false">
            <div class="row mt-2" *ngIf="type != 'Transactions'">
                <div class="col-6">
                    <mat-checkbox class="m-t-22 f-weight" (change)="SelactAllSingle($event.checked)">{{"Select All"|translate}}
                    </mat-checkbox>
                </div>
            </div>
            <div class="row height-200">
                <div class="row mt-2">
                    <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6" *ngFor="let list of AllLinks; let i = index">
                        <!-- Users  -->
                        <ng-container *ngIf="type == 'Users'">
                            <mat-checkbox class="m-t-22 f-weight" [checked]="list.isChecked"
                                (change)="getlinkeduserid(list.id ,$event.checked)">
                                <div id="main">
                                    <div> <img class="avatar b-r-20 " onError="this.src='assets/avatar.svg'"
                                            [src]="list?.profilePicture ? list.profilePicture:'assets/avatar.svg'">
                                    </div>
                                    <div>
                                        <p style="white-space: normal !important;"> {{list.firstName| titlecase}}
                                            {{list.lastName| titlecase}}</p>
                                    </div>
                                </div>
                            </mat-checkbox><br><br>
                        </ng-container>


                        <!-- Contacts  -->
                        <ng-container *ngIf="type == 'Contacts'">
                            <mat-checkbox class="m-t-22 f-weight" [checked]="list.isChecked"
                                (change)="getlinkeduserid(list.id,$event.checked)">
                                <div id="main">
                                    <div> <img class="avatar b-r-20 " onError="this.src='assets/avatar.svg'"
                                            [src]="list?.profilePicture ? list.profilePicture:'assets/avatar.svg'">
                                    </div>
                                    <div>
                                        <p style="white-space: normal !important;"> {{list.firstName| titlecase}}
                                            {{list.lastName| titlecase}}</p>
                                    </div>
                                </div>
                            </mat-checkbox><br><br>
                        </ng-container>


                        <!-- Sites  -->
                        <ng-container *ngIf="type == 'Sites'">
                            <mat-checkbox class="m-t-22 f-weight" [checked]="list.isChecked"
                                (change)="getlinkeduserid(list.id,$event.checked)">
                                <div id="main">
                                    <div> <img src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
                                    </div>
                                    <div>
                                        <p style="white-space: normal !important;"> {{list.firstname |titlecase}}</p>
                                    </div>
                                </div>
                            </mat-checkbox><br><br>
                        </ng-container>


                        <!-- Products  -->
                        <ng-container *ngIf="type == 'Products'">
                            <mat-checkbox class="m-t-22 f-weight" [checked]="list.isChecked"
                                (change)="getlinkeduserid(list.id,$event.checked)">
                                <div id="main">
                                    <div><img class="avatar b-r-20" onError="this.src='assets/avatar.svg'"
                                            [src]="list?.profilePicture ? list.profilePicture:'assets/images/iconsForsvg/icon_product.svg'"
                                            onError="this.src='assets/images/iconsForsvg/icon_product.svg'"></div>
                                    <div>
                                        <p style="white-space: normal !important;"> {{list.firstname|titlecase}}</p>
                                    </div>
                                </div>
                            </mat-checkbox><br><br>
                        </ng-container>
                    </div>
                </div>
                <!-- Transactions  -->
                <ng-container *ngIf="type =='Transactions'">
                    <div class="row mt-2">
                        <div class="col-2">
                            <mat-checkbox [checked]="saleType"
                                (change)="checkTransationTypeSale($event.checked)">{{"sale"|translate}}</mat-checkbox>
                        </div>
                        <div class="col-2">
                            <mat-checkbox [checked]="buyType"
                                (change)="checkTransationTypeBuy($event.checked)">{{"Buy"|translate}}</mat-checkbox>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="getgroupvalue()==true">
            <div class="row mt-2">
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-checkbox class="m-t-22 f-weight" (change)="SelactAllgroups($event.checked)">{{"Select All"|translate}}
                    </mat-checkbox>
                </div>
            </div>
            <div class="row mt-2" *ngIf="type == 'Users'||type == 'Contacts'||type == 'Sites'||type == 'Products'">
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6" *ngFor="let group of AllGroupsLinks; let i = index">
                    <span>
                        <mat-checkbox class="m-t-22 f-weight" [checked]="group.isChecked"
                            (change)="getlinkedGroupsid(group.id ,$event.checked)">
                            <mat-icon [ngStyle]="{'color':(group.dynamicGroupId ==null)?'red':'blue'}">group</mat-icon>
                            <span> {{group.firstname| titlecase}}</span>
                        </mat-checkbox><br><br>
                    </span>
                </div>
            </div>
        </ng-container>
    </form>
    <!-- ------------------  -->
</mat-dialog-content>
<div class="row justify-content-end mt-2" style="margin-bottom: 8px;">
    <div class="row mt-2 w-100 justify-content-center">
        <div class="col-4">
            <button mat-raised-button mat-dialog-close (click)="closedialogbox()">{{"Cancel"|translate}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button *ngIf="screenType=='CreatePermission'" class="b-blue"
            (click)="managegroupids()">{{"Apply"|translate}}</button>
        <button mat-raised-button *ngIf="screenType=='UpdatePermission'" class="b-blue"
            (click)="managegroupids()">{{'Update'|translate}}</button>
        </div>
    </div>
</div>