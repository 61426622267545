<div class="d-flex flex-row align-items-center modal-header">
  <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
    <h2 mat-dialog-title *ngIf="screenType=='Accpet'||screenType=='Comment'||screenType=='Accpet1'||screenType=='Reject'" class="m-0 dialog-title text-center w-100">Confirmation</h2>
    <h2 mat-dialog-title *ngIf="screenType=='Collaboration_Remove'||screenType=='Collaboration_Add'" class="m-0 dialog-title text-center w-100">Confirmation</h2>
    <h2 mat-dialog-title  *ngIf="screenType=='onDoubleClick'" class="m-0 dialog-title text-center w-100">Rename label</h2>
    <h2 mat-dialog-title  *ngIf="screenType=='checkmailsyncststus'" class="m-0 dialog-title text-center w-100">Request Email Sync for Trial Account</h2>
    <h2 mat-dialog-title  *ngIf="screenType=='RequestedMailpermission'" class="m-0 dialog-title text-center w-100">Please Wait for Validation</h2>
    <h2 mat-dialog-title  *ngIf="screenType=='ResetLabels'" class="m-0 dialog-title text-center w-100">Message</h2>
    <h2 mat-dialog-title  *ngIf="screenType=='Barclicked'||screenType=='Tableclicked'" class="m-0 dialog-title text-center w-100">Details</h2>
    <h2 mat-dialog-title  *ngIf="screenType=='templateupload'" class="m-0 dialog-title text-center w-100">Template Upload</h2>
    <h2 mat-dialog-title  *ngIf="screenType=='FromMediaLibrary'" class="m-0 dialog-title text-center w-100">
      Media Library</h2>
  </div>

<mat-dialog-content class="mr-0 ml-0">
    <!-- Accpet  -->
<ng-container *ngIf="screenType=='Accpet'" >
  <div class="row mt-4">
    <div class="col-12">
      <p style="font-size: 18px;">Are you sure you want to <img src="../../../assets/images/iconsForsvg/approval.svg" alt=""> the request for <br> <mat-icon style="color: green;">assignment_turned_in</mat-icon><b>{{entitydata?.entityName}}</b> ?</p>
    </div>
  </div>
  <div class="row mb-2 justify-content-center">
    <div class="col-4">
        <button mat-raised-button mat-dialog-close class="W-blue">{{"No"|translate}}</button>
    </div>
    <div class="col-4">
        <button mat-raised-button class="w-100 b-blue" (click)="onSubmit()">{{"Yes"|translate}}</button>
    </div>
</div>
</ng-container>
    <!-- Accpet1  -->
    <ng-container *ngIf="screenType=='Accpet1'" >
      <div class="row mt-4">
        <div class="col-12">
          <p style="font-size: 18px;">Are you sure you want to accept an invitation?</p>
        </div>
      </div>
      <div class="row mb-2 justify-content-center">
        <div class="col-4">
            <button mat-raised-button mat-dialog-close class="W-blue">{{"No"|translate}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button class="w-100 b-blue" (click)="onSubmit()">{{"Yes"|translate}}</button>
        </div>
    </div>
    </ng-container>
    <!-- Reject  -->
    <ng-container *ngIf="screenType=='Reject'" >
      <div class="row mt-4">
        <div class="col-12">
          <p style="font-size: 18px;">Are you sure you want to reject an invitation?</p>
        </div>
      </div>
      <div class="row mb-2 justify-content-center">
        <div class="col-4">
            <button mat-raised-button mat-dialog-close class="W-blue">{{"No"|translate}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button class="w-100 b-blue" (click)="onSubmit()">{{"Yes"|translate}}</button>
        </div>
    </div>
    </ng-container>
<!-- Comment  -->
<ng-container *ngIf="screenType=='Comment'" >
  <div class="row mt-4">
    <div class="col-12">
      <p style="font-size: 18px;">Are you sure you want to send a <mat-icon>message</mat-icon> regarding the <br><mat-icon style="color: green;">assignment_turned_in</mat-icon><b>{{entitydata?.entityName}}</b> ?</p>
    </div>
  </div>
  <div class="row w-100">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Message</mat-label>
        <textarea matInput [(ngModel)]="message" name="message" rows="4"></textarea>
      </mat-form-field>
</div>
<div class="row mb-2 justify-content-center">
  <div class="col-4">
      <button mat-raised-button mat-dialog-close class="W-blue">{{"No"|translate}}</button>
  </div>
  <div class="col-4">
      <button mat-raised-button class="w-100 b-blue" (click)="onSubmit()">{{"Yes"|translate}}</button>
  </div>
</div>
</ng-container>
<!-- onDoubleClick  -->
<ng-container *ngIf="screenType=='onDoubleClick'" >
  <div class="row mt-2 mb-2">
    <div class="col-10">
      <mat-form-field class="w-100 custom-form-field">
        <mat-label>New Lable Name</mat-label>
        <input matInput [(ngModel)]="NewlableName">
    </mat-form-field> 
    </div>
  </div>

  <div class="row mt-4 mb-4  justify-content-center">
    <div class="col-4">
        <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
    </div>
    <div class="col-4">
        <button mat-raised-button class="w-100 b-blue" (click)="onDoubleClickSubmit()">{{"Apply"|translate}}</button>
    </div>
  </div>
</ng-container>
<!-- Collaboration_Add  -->
<ng-container *ngIf="screenType=='Collaboration_Add'" >
  <div class="row mt-2 mb-2">
    <p>Would you like to add collaboration for your email address {{entitydata?.email}} ? </p>
  </div>

  <div class="row mt-4 mb-4  justify-content-center">
    <div class="col-4">
        <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
    </div>
    <div class="col-4">
        <button mat-raised-button class="w-100 b-blue" (click)="Collaborationbutton()">{{"Ok"|translate}}</button>
    </div>
  </div>
</ng-container>
<!-- Collaboration_Remove  -->
<ng-container *ngIf="screenType=='Collaboration_Remove'" >
  <div class="row mt-2 mb-2">
     <p>Would you like to delete the collaboration for your email address {{entitydata?.email}} ? </p>
  </div>

  <div class="row mt-4 mb-4  justify-content-center">
    <div class="col-4">
        <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
    </div>
    <div class="col-4">
        <button mat-raised-button class="w-100 b-blue" (click)="Collaborationbutton()">{{"Ok"|translate}}</button>
    </div>
  </div>
</ng-container>
<!-- checkmailsyncststus -->
 <ng-container *ngIf="screenType=='checkmailsyncststus'">
<p>Please enter the email address you would like to request for synchronization with {{data?.data?.type}}</p>
<div class="row w-100">
  <mat-form-field appearance="fill" class="w-100">
    <mat-label>Email</mat-label> 
    <input matInput [(ngModel)]="Email" name="Email" type="email">
  </mat-form-field>
</div>
<div class="row mt-2 mb-4  justify-content-center">
<div class="col-4">
  <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
</div>
<div class="col-4">
  <button mat-raised-button class="w-100 b-blue" (click)="onSubmit()">{{"Apply"|translate}}</button>
</div>
</div>
 </ng-container>
<!-- RequestedMailpermission  -->
<ng-container *ngIf="screenType=='RequestedMailpermission'" >
  <div class="row mt-4">
    <div class="col-12">
      <p style="font-size: 18px;">Your request is being processed. This may take a moment .</p>
    </div>
  </div>
  <div class="row mt-2 mb-4 justify-content-center">
    <div class="col-4">

    </div>
    <div class="col-4">
        <button mat-raised-button class="w-100 b-blue" (click)="onSubmit()">{{"Ok"|translate}}</button>
    </div>
</div>
</ng-container>
<!-- ResetLabels  -->
<ng-container *ngIf="screenType=='ResetLabels'" >
  <div class="notification mt-4">
    <span class="message">Reset label update done!</span>
</div>
<div class="row mt-2 mb-4 justify-content-center">
  <div class="col-8">
  </div>
  <div class="col-2">
      <button mat-raised-button class="w-100 b-blue" (click)="onSubmit()">{{"Ok"|translate}}</button>
  </div>
</div>
</ng-container>
<!-- Barclicked -->
 <ng-container *ngIf="screenType=='Barclicked'">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Email </th>
        <th scope="col">Date & time </th>
        <!-- <th scope="col">Click Map </th> -->
      </tr>
    </thead>
    <tbody>
     
      <tr *ngFor="let x of entitydata;let i = index"> 
        <th scope="row">{{i+1}}</th>
        <td>
          <span style="color: black;font-weight: 900;"><b>{{x?.recipientEmail}}</b></span><br>
          <span style="color: grey;font-weight: 900;">{{x?.emailTitle}}</span>
         </td>
        <td>{{x.sentTime| date:'dd-MMM-yyyy H:mm'}}</td>
        <!-- <td></td> -->
      </tr>
    </tbody>
  </table>
 </ng-container>
 <!-- Tableclicked -->
 <ng-container *ngIf="screenType=='Tableclicked'">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Email </th>
        <th scope="col">Date & time </th>
      </tr>
    </thead>
    <tbody>
     
      <tr *ngFor="let x of entitydata;let i = index"> 
        <th scope="row">{{i+1}}</th>
        <td>
          <span style="color: black;font-weight: 900;"><b>{{x?.recipientEmail}}</b></span><br>
          <!-- <span style="color: grey;font-weight: 900;">{{x?.emailTitle}}</span> -->
         </td>
        <td>{{x.sentTime| date:'dd-MMM-yyyy H:mm'}}</td>
      </tr>
    </tbody>
  </table>
 </ng-container>
 <!-- templateupload  -->
 <ng-container *ngIf="screenType=='templateupload'">

  <div class="row">
    <div class="col-10">
                  <!-- Keywords  -->
                  <div class="row w-100">
                    <mat-form-field class="example-chip-list" style="width: 100% !important;">
                        <mat-label>{{"keyword"|translate}}</mat-label><br>
                        <mat-chip-list #chipGridforkeyword aria-label="Fruit selection" class="keywordlist">
                            <mat-chip-row class="keywordrow" *ngFor="let keyword of keywordlist">
                                <button class="keywordbutton">
                                    <span class="keywordpostion">{{keyword|titlecase}}</span>
                                    <mat-icon class="keywordcancelicon" (click)="remove(keyword)">cancel</mat-icon>
                                </button>
                            </mat-chip-row> 
                        </mat-chip-list>
                        <input [matChipInputFor]="chipGridforkeyword" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add($event)" />
                    </mat-form-field>
                </div>
                <div class="col-10 mb-2">
                  <input  type="file" (change)="handleFileInput($event)" accept=".html" />
                </div>
                <div class="col-12 mt-2 mb-2">
                  <ng-container *ngIf="htmlContent!=null">
                    <div id="displayBox" class="display-box">
                      <iframe *ngIf="htmlContent" [srcdoc]="htmlContent" width="100%" height="100%"></iframe>
                    </div>
                  </ng-container>
                </div>
    </div>
  </div>

  <div class="row mt-2 mb-2 justify-content-center">
    <div class="col-4">
        <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
    </div>
    <div class="col-4">
        <button mat-raised-button class="w-100 b-blue" (click)="onSubmitforsavetemplate()">{{"Save"|translate}}</button>
    </div>
  </div>
 </ng-container>



<!-- FromMediaLibrary  -->
<ng-container *ngIf="screenType=='FromMediaLibrary'">
  <mat-dialog-content class="mr-0 ml-0">
<div class="row">
  <div class="container">
      <div class="row justify-content-start">
        <div class="col-3" *ngFor="let x of FromMediaLibrarylist" (click)="FromMediaLibrary(x)" style="cursor: pointer;">
        <div>
          <img style="width: 100%;height: 300px;object-fit: contain;overflow-y: auto;" 
          onError="this.src='assets/images/img/template.jpg'"
          [src]="x?.pngFileUrl  ? x.pngFileUrl :'assets/images/img/template.jpg'">
          <p style="width: 100%;text-align: start;margin-top: 5px;">{{x.templateName}}</p>
        </div>
        </div>
      </div>
      <mat-paginator  [pageSize]="10" [length]="totalTemplates" (page)="nextpage($event)"></mat-paginator>
  </div>

</div>
</mat-dialog-content>
</ng-container>


</mat-dialog-content>
