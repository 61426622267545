<div class="d-flex flex-row align-items-center modal-header">
  <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">{{'Update Category'|translate}}</h2>
  <mat-icon matDialogClose>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
<form [formGroup]="editForm">
  <mat-form-field appearance="legacy" class="w-100">
    <mat-label>{{'Category Name'|translate}}<span class="danger">* </span></mat-label>
    <input matInput type="text" placeholder="Category Name " formControlName="categoryName">
  </mat-form-field>
  <div class="col-md-5">
    <mat-label>{{'Category Color'|translate}}<span class="danger">* </span></mat-label>
    <input [style.background]="color1" [(colorPicker)]="color1"
      (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
      (colorPickerClose)="onEventLog('colorPickerClose', $event)" />

    <mat-label>{{'Text Color'|translate}} <span class="danger">* </span></mat-label>
    <input formControlName="fontColor" [style.background]="color2" [(colorPicker)]="color2"
      (colorPickerOpen)="onEventLog1('colorPickerOpen', $event)"
      (colorPickerClose)="onEventLog1('colorPickerClose', $event)" />
  </div>
</form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">
  <div class="row w-100">
    <div class="col-4">
      <button mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
    </div>
    <div class="col-4">
      <button mat-raised-button [mat-dialog-close]="data.catId" class="b-blue"
        (click)="updateCategory()">{{'Update'|translate}}</button>
    </div>
  </div>
</mat-dialog-actions>