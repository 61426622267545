<header>
    <div class="d-flex flex-row align-items-center modal-header">
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">Add New Tag</h2>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
</header>

<form [formGroup]="productForm">
    <mat-card>
        <mat-card-content class="mr-0 ml-0">
        <h4 style="text-align: center;"><b> Select Tag Type</b></h4>
         <mat-radio-group class="example-radio-group" aria-label="Select an option" formControlName="tagType"
             (click)="onChanges()">
                    <div class="row">
                        <div class="col-4">
                          <mat-radio-button class="example-radio-button" value="Checkbox">Check box</mat-radio-button>
                        </div>
                        <div class="col-4">
                           <mat-radio-button class="example-radio-button" value="Dropdown">Dropdown list</mat-radio-button>
                        </div>
                        <div class="col-4">
                           <mat-radio-button class="example-radio-button" value="Freefeild">Free Field</mat-radio-button>
                        </div>


                        <div class="col-4">
                            <mat-radio-button class="example-radio-button" value="email">Email</mat-radio-button>
                         </div>
                         <div class="col-4">
                            <mat-radio-button class="example-radio-button" value="PhoneNumber">Phone Number</mat-radio-button>
                         </div>
                         <div class="col-4">
                            <mat-radio-button class="example-radio-button" value="URL">URL</mat-radio-button>
                         </div>
                         <div class="col-4">
                            <mat-radio-button class="example-radio-button" value="date">Date</mat-radio-button>
                         </div>
                         <div class="col-4">
                            <mat-radio-button class="example-radio-button" value="percentage">percentage</mat-radio-button>
                         </div>
                         <div class="col-4">
                            <mat-radio-button class="example-radio-button" value="hour&minutes">Hour&minutes</mat-radio-button>
                         </div>
                         <div class="col-4">
                            <mat-radio-button class="example-radio-button" value="Squarefeet">Square feet</mat-radio-button>
                         </div>
                         <div class="col-4">
                            <mat-radio-button class="example-radio-button" value="Squaremeter">Square meter</mat-radio-button>
                         </div>
                         <div class="col-4">
                            <mat-radio-button class="example-radio-button" value="Days">Days</mat-radio-button>
                         </div>
                         <div class="col-4">
                            <mat-radio-button class="example-radio-button" value="Entity">Entity</mat-radio-button>
                         </div>
                         <div class="col-4">
                            <mat-radio-button class="example-radio-button" value="Files">Files</mat-radio-button>
                         </div>

                    </div>
                </mat-radio-group>
                <!-- Checkbox  -->
                <ng-container *ngIf="selectedradioval == 'Checkbox'">
                    <div class="row justify-content-start">
                        <div class="col-12">
                            <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                        </div><br>
                        <div class="col-8">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Title </mat-label>
                                <input matInput type="text" formControlName="title">
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
            <!-- Freefeild  -->
            <ng-container *ngIf="selectedradioval == 'Freefeild'">
                <div class="row w-100" >
                    <mat-radio-group class="example-radio-group" aria-label="Select an option" formControlName="numeralType"
                        (click)="checkChanges()">
                        <mat-radio-button class="example-radio-button" checked="true" value="Alphanumeric">Alphanumeric
                        </mat-radio-button>
                        <mat-radio-button class="example-radio-button" value="Numerals">Numerals Only</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Title </mat-label>
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
            <!-- Dropdown  -->
             <ng-container *ngIf="selectedradioval == 'Dropdown'">
                <div class="row justify-content-start" >
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="col-8">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Title </mat-label>
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <button mat-raised-button class="b-blue" (click)="addcreateBody()">Add value</button>
                    </div>
                    <div class="col-12">
                        <div class="row w-100" formArrayName="Adddropdownvalue">
                            <div class="row w-100" *ngFor="let conditionsBody of getfileBody().controls; let i=index"
                                [formGroupName]="i">
                                <div class="row w-100">
                                    <div class="col-11 p1">
                                        <mat-form-field class="w-100">
                                            <input matInput placeholder="value" formControlName="dropdownvalue">
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="i!=0" class="col-1">
                                        <mat-icon (click)="removeConditionalBody(i)">delete</mat-icon>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
             </ng-container>

               <!-- email  -->
               <ng-container *ngIf="selectedradioval == 'email'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Title </mat-label>
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
                           <!-- PhoneNumber  -->
           <ng-container *ngIf="selectedradioval == 'PhoneNumber'">
            <div class="row justify-content-start">
                <div class="col-12">
                    <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                </div><br>
                <div class="col-12">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Title </mat-label>
                        <input matInput type="text" formControlName="title">
                    </mat-form-field>
                </div>
            </div>
            </ng-container>
            <!-- URL  -->
            <ng-container *ngIf="selectedradioval == 'URL'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Title </mat-label>
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
                    </div>
                </div>
                </ng-container>
                            <!-- currency  -->
            <ng-container *ngIf="selectedradioval == 'currency'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Title </mat-label>
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
                    </div>
                </div>
                </ng-container>
                                            <!-- percentage  -->
            <ng-container *ngIf="selectedradioval == 'percentage'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Title </mat-label>
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
                    </div>
                </div>
                </ng-container>
                        <!-- date  -->
            <ng-container *ngIf="selectedradioval == 'date'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Title </mat-label>
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
                    </div>
                </div>
                </ng-container>
                     <!-- hour&minutes  -->
            <ng-container *ngIf="selectedradioval == 'hour&minutes'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Title </mat-label>
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
                    </div>
                </div>
                </ng-container>
                    <!-- Squarefeet  -->
            <ng-container *ngIf="selectedradioval == 'Squarefeet'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Title </mat-label>
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
                    </div>
                </div>
                </ng-container>
                            <!-- Squaremeter  -->
            <ng-container *ngIf="selectedradioval == 'Squaremeter'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Title </mat-label>
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
                    </div>
                </div>
                </ng-container>
                        <!-- days  -->
            <ng-container *ngIf="selectedradioval == 'Days'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Title </mat-label>
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
                    </div>
                </div>
                </ng-container>
                <!-- Entity  -->
                <ng-container *ngIf="selectedradioval == 'Entity'">
                    <div class="row w-100">
                        <div class="col-12">
                            <mat-form-field appearance="fill" style="width: 100% !important;" class="w-100">
                                <mat-label>{{"Select Entity"|translate}}</mat-label>
                                <mat-select formControlName="title">
                                    <mat-option [value]="type" *ngFor="let type of managementType" (click)="clickEntity(type)">
                                        {{type|translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
                <!-- Files  -->
                <ng-container *ngIf="selectedradioval == 'Files'">
                    <div class="row justify-content-start">
                        <div class="col-12">
                            <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                        </div><br>
                        <div class="col-12">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Title </mat-label>
                                <input matInput type="text" formControlName="title">
                            </mat-form-field>
                        </div>
                    </div>
                    </ng-container>
        </mat-card-content>

        <!-- end   -->


        <!-- actions   -->
        <mat-card-actions>
            <div class="row w-100">
                <div class="col-6"></div>
                <div class="col-6">
                    <button mat-button mat-dialog-close (click)="close()">Cancel</button>
                    <button mat-raised-button class="b-blue" (click)="applytag()">Create</button>
                </div>
            </div>
        </mat-card-actions>
    </mat-card>
</form>