<div class="modal-header">
    <div class="row w-100">
        <div class="col-2">
            <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
        </div>
        <div class="col-10">
            <h2 mat-dialog-title class="m-0 dialog-title  w-100">{{'Imported Data Result'|translate}}</h2>
        </div>
        <div class="col-2">
        </div>
    </div>
</div>

    <mat-dialog-content class="mr-0 ml-0 mb-5">
        <div class="mt-1" *ngFor="let x of impordataResult;let i = index">
            <mat-card>
                <mat-card-content>{{x.message|translate}}</mat-card-content>
              </mat-card>
        </div>
    </mat-dialog-content>
