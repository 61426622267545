    <div class="d-flex flex-row align-items-center modal-header">
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
            <img  style="cursor: pointer;" src="../../../assets/images/iconsForsvg/settings.svg" alt="">
           Settings
        </h2>
        <mat-icon  (click)="closepopup()">close</mat-icon>
    </div>
    <div class="row w-100">
        <mat-tab-group style="width: 100%;" mat-align-tabs="center" class="no-bg"  (selectedTabChange)="tabChange($event.index)"> 
            <!-- Collaboration  -->
            <mat-tab >
                <ng-template mat-tab-label>
                    <div >
                        <span>Collaboration</span>
                    </div>
                </ng-template> 
                <div class="row">
                    <div class="col-4" style="padding: 5px !important;">
                        <div class="row mt-2 mb-2" *ngFor="let x of collaborationlist">
                                <p [ngClass]="gethoverclass(x)">
                                    <!-- <p [ngClass]="gethoverclass(x)"> -->
                                    <mat-slide-toggle [(ngModel)]="x.isCollabrative"(change)="onToggleChange($event,x)" ></mat-slide-toggle>
                                    <span style="margin-left: 5px;" (click)="showmailusers(x)">{{x?.email}}</span>
                                </p>
                         </div>
                    </div>
                    <ng-container *ngIf="UpdateAccountAsColabrative">
                        <ng-container *ngIf="filterforuserlist.length==0">
                         <app-skeleton-loader></app-skeleton-loader>
                        </ng-container>
                        <ng-container *ngIf="filterforuserlist.length!=0">
                            <div class="col-8" style="padding: 5px !important;">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-form-field class="w-100">
                                            <input matInput  placeholder="Search">
                                            <mat-icon matSuffix class="pointer searchicon">search</mat-icon>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <button mat-raised-button *ngIf="applybuttonstatus" class="b-blue" (click)="onSubmiaccess()">{{"Apply"|translate}}</button>
                                    </div>
                                </div>
                                <div class="row w-100" >
                                    <div class="col-4" *ngFor="let x of filterforuserlist;let index=index;">
                                        <div style="display: flex;" class="displayflexstyle">
                                            <div>
                                                <mat-checkbox (click)="applybutton()" [(ngModel)]="x.isCollaborated"   >
                                                </mat-checkbox>
                                            </div>
                                            <div>
                                                <img class="avatar" style="border-radius: 50%;" *ngIf="x?.profilePicture!=''" onError="this.src='assets/avatar.svg'" 
                                                [src]="x?.profilePicture ? x?.profilePicture:'assets/avatar.svg'">
                                                <div *ngIf="x?.profilePicture==''" [style.background]="getColor(index)"  class="profile">{{getuserFLnames(x?.firstName+' '+x.lastName)}}</div>
                                            </div>  
                                            <div>
                                        <span style="margin-left: 5px;"><b>{{x?.firstName}} {{x.lastName}}</b></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
             </mat-tab>
    
            <!-- Access permission  -->
            <mat-tab >
                <ng-template mat-tab-label>
                    <div >
                        <span>Access</span><br>
                        <span>permission</span>
                    </div>
                </ng-template> 
                <div>
                    <div style="display: flex;padding: 20px;">
                        <div style="width: 33%;">
                            <div  *ngFor="let x of allmails" [ngClass]="siddemenu(x)" (click)="sidemenuclick(x)">
                                <p>{{x?.email}}</p>
                            </div>
                        </div>
                        <div style="width: 70%;">
                            <div class="row mb-4"  *ngFor="let User of AccesspermissionUsers;let i = index">
                                <div class="col-12">
                                   <div>
                                    <span style="position: relative;right: 10px;top: 10px;">
                                        <mat-slide-toggle (change)="onToggleChange2($event,User,i)"></mat-slide-toggle>
                                    </span> 
                                    <span  [style.background]="getColor(i)"
                                    style="color: white; padding: 8px;border-radius: 50%;position: relative;top: 5px;margin: 5px;right: 8px;">
                                    {{getuserFLnames(User?.userName)}}</span>
                                    <span>{{User?.userName|titlecase}}</span>
                                   </div>
                                   <div>
                                  <!-- -----  -->
                                   </div>
                                </div>
                                <ng-container>
                                    <div class="row mt-4" *ngIf="seletedUserindex==i">
                                        <ng-container *ngIf="CollaboratedUsersCategories.length!=0">
                                            <div style="display: flex;margin-top: 10px;" *ngFor="let Categories of CollaboratedUsersCategories">
                                                <div style="width: 20%;"></div>
                                                <div>
                                                    <mat-checkbox [(ngModel)]="Categories.isPermitted"></mat-checkbox>
                                                </div>
                                                <div style="margin-left: 5px;">
                                                    <button style="border: none;" [ngStyle]="{ 'background-color': Categories?.bgColorCode, 'color': Categories?.textColorCode, 'border-radius': '10px','text-align': 'center','width': '100px' }">
                                                        {{Categories?.name}}
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
    
            </mat-tab>
    
                <!-- Categories  -->
            <mat-tab >
                <ng-template mat-tab-label>
                    <div >
                        <span>Categories</span>
                    </div>
                </ng-template> 
                <div>
                    <div class="row justify-content-end">
                        <div class="col-2">
                            <mat-icon  (click)="addCategory()">add_box</mat-icon>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-stripedmb-4">
                                <thead>
                                  <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Category</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let x of categorieslist">
                                    <td>
                                        <mat-icon [matMenuTriggerFor]="beforeMenu" class="icon-options">more_vert</mat-icon>
                                        <mat-menu #beforeMenu="matMenu" xPosition="before">
                                            <button class="fw-600" mat-menu-item (click)="Categorydetails(x)">
                                                <mat-icon >visibility</mat-icon>
                                                <span class="fw-600 color_black">View</span>
                                            </button>
                                            <button class="fw-600" mat-menu-item (click)="Categoryedit(x)" >
                                                <mat-icon>edit</mat-icon> Edit
                                            </button>
                                            <button class="fw-600" mat-menu-item (click)="Categorydelete(x)">
                                                <mat-icon>delete</mat-icon> Delete
                                            </button>
                                        </mat-menu>
                                    </td>
                                    <td>
                                        {{x?.description}}
                                    </td>
                                    <td>
                                    <button style="border: none;font-weight: 900;"  [ngStyle]="{ 'background-color': x?.bgColorCode, 'color': x?.textColorCode, 'border-radius': '10px','text-align': 'center','width': '150px' }">
                                        {{x?.name}}
                                    </button>
                                    </td>
                                    </tr>
                                    </tbody>
                             </table>
                        </div>
                    </div>
                </div>
                </mat-tab>
                <!-- Template Library  -->
                <mat-tab >
                    <ng-template mat-tab-label>
                        <div >
                            <span>Template</span><br>
                            <span>Library</span>
                        </div>
                    </ng-template> 
                    <div>
                        <div style="display: flex;justify-content: end;padding: 10px 50px;">
                            <div style="padding: 10px;">
                                <img style="width:40px;height: 40px;" (click)="UploadTemplate()"
                                 src="../../../../assets/images/iconsForsvg/upload_file.svg">
                                <!-- <button mat-raised-button class="b-blue" >Upload</button> -->
                            </div>
                            <div style="padding: 10px;">
                                <img style="width:40px;height: 40px;" (click)="CreateTemplate()"
                                src="../../../../assets/images/iconsForsvg/add_circle.svg">
                                <!-- <button mat-raised-button class="b-blue" (click)="CreateTemplate()">Create</button> -->
                            </div>
                        </div>
                        <div class="row mb-4" >
                            <div class="col-3" *ngFor="let x of listoftemplate">
                                <mat-card appearance="outlined">
                                    <mat-card-content>
                                        <div>
                                            <img class="templatefile"  [src]="x?.pngFileUrl">
                                            
                                            <div style="display: flex;">
                                                <div>
                                                    <mat-icon style="position: relative;top: 10px;" [matMenuTriggerFor]="beforeMenu"  class="icon-options">more_vert</mat-icon>
                                                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                                                                <button class="fw-600" mat-menu-item (click)="viewtemplate(x?.fileUrl)">
                                                            <mat-icon class="icon-options">visibility</mat-icon>
                                                            <span class="fw-600 color_black">{{'View'|translate}}</span>
                                                        </button>
                                                        <button class="fw-600" mat-menu-item (click)="DeleteTemplate(x)">
                                                            <mat-icon>delete</mat-icon>
                                                            <span class="fw-600 color_black">{{'Delete'|translate}}</span>
                                                        </button>
                                                        <button class="fw-600" mat-menu-item (click)="downloadTemplate(x?.fileUrl)">
                                                            <mat-icon>cloud_download</mat-icon>
                                                            <span class="fw-600 color_black">{{'Download'|translate}}</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                                <div>
                                                    <div>
                                                        <h3 style="text-align: center;">{{x.templateName}}</h3>
                                                    </div>
                                                    <ng-container *ngIf="x.keyWords!=null">
                                                        <div  style="display: flex;justify-content: center;">
                                                            <div *ngFor="let key of getkeywords(x.keyWords)">
                                                              <button class="normalkeyword">
                                                                  <span>{{key}}</span>
                                                              </button>
                                                            </div>
                                                          </div>
                                                    </ng-container>
                                                </div>
                                            </div>                                    
                                        </div>
                                    </mat-card-content>
                                  </mat-card>
                            </div>
                        </div>
                       <mat-paginator  [pageSize]="8" [length]="totalTemplates" (page)="nextpage($event)"></mat-paginator>
                    </div>
                    </mat-tab>
                     <!-- Analytics  -->
                <mat-tab >
                    <ng-template mat-tab-label>
                        <div >
                            <span>Analytics</span>
                        </div>
                    </ng-template> 
                    <div>

                        <!-- one  -->
                         <div class="row mt-2">
                            <form [formGroup]="Analytics" class="w-100">
                                <div class="row w-100">
                                    <div class="col-4">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>{{"Accounts"|translate}}</mat-label>
                                            <mat-select formControlName="accountid">
                                                <mat-option *ngFor="let Account of AllSynchMailAccounts" [value]="Account.id">
                                                    {{Account.email|titlecase}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-3">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>{{"Start Date"|translate}}</mat-label>
                                            <input matInput formControlName="StartDate" [matDatepicker]="startdatepicker1" style="color: black;">
                                            <mat-datepicker-toggle matSuffix [for]="startdatepicker1"></mat-datepicker-toggle>
                                            <mat-datepicker #startdatepicker1></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-3">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>{{"End Date"|translate}}</mat-label>
                                            <input matInput formControlName="EndDate" [matDatepicker]="enddatepicker1" style="color: black;">
                                            <mat-datepicker-toggle matSuffix [for]="enddatepicker1"></mat-datepicker-toggle>
                                            <mat-datepicker #enddatepicker1></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-2">
                                        <button mat-raised-button  
                                        class="b-blue" (click)="onSubmit()">{{"Apply"|translate}}</button>
                                    </div>
                                </div> 
                            </form>
                         </div>
                        <!-- ------------------  -->
                        <!-- chart -->
                         <div class="row mt-2 chart-container justify-content-center">
                            <ng-container *ngIf="data.length!=0">
                                <div class="col-10">
                                    <ngx-charts-bar-vertical-2d 
                                    [results]="data" 
                                    [scheme]="colorScheme" 
                                    [xAxis]="true" 
                                    [yAxis]="true" 
                                    [view]="graphDimensions"
                                    [barPadding]="10" [groupPadding]="2"
                                    [legend]="true" 
                                    [legendPosition]="'below'"
                                    [gradient]="false" 
                                    [showGridLines]="false"
                                    (select)="onBarClick($event)">
                                  </ngx-charts-bar-vertical-2d>   
                                  </div> 
                            </ng-container> 
                         </div>
                         <!-- ---------------  -->
                          <!-- table  -->
                         <div class="row justify-content-center">
                            <div class="col-11">
                                <h1 style="text-align: center;">Grouping Email Status</h1>
                                <table class="table table-striped">
                                   <thead>
                                     <tr>
                                       <th style="text-align: center;" scope="col">#</th>
                                       <th style="text-align: center;" scope="col">Email Title </th>
                                       <th style="text-align: center;" scope="col">Receivers Count</th>
                                       <th style="text-align: center;" scope="col">Open Count</th>
                                       <th style="text-align: center;" scope="col">Click Count</th>
                                       <th style="text-align: center;" scope="col">Respons Count</th>
                                       <th style="text-align: center;" scope="col">Date</th>
                                     </tr>
                                   </thead>
                                   <tbody>
                                     <tr *ngFor="let x of Groupmaildata;let i = index" (click)="showtablerowdata(x,false,true,false)">
                                       <th scope="row">{{i+1}}</th>
                                       <td>
                                        <span style="color: black;font-weight: 900;"><b>{{x?.emailTitle}}</b></span><br>
                                       </td>
                                       <td style="text-align: center;">{{x?.totalReceiversCount}}</td>
                                       <td style="text-align: center;">{{x?.totalOpenCount}}</td>
                                       <td style="text-align: center;">{{x?.totalClickCount}}</td>
                                       <td style="text-align: center;">{{x.totalSentCount}}</td>
                                       <td style="text-align: center;">{{x.sentDate| date:'dd-MMM-yyyy'}}</td>
                                     </tr>
                                   </tbody>
                                 </table>
                       <mat-paginator  [pageSize]="10" [length]="totalGoupmails" (page)="nextpage1($event)"></mat-paginator>

                            </div>
                         </div>
                          <!-- --------------------  -->
                         <!-- map  -->
                          <div class="row mt-2 chart-container justify-content-center">
                         <div class="col-10">
                            <agm-map
                            [latitude]="lat"  [longitude]="lng"
                             [zoom]="zoom" [zoomControl]="true"
                             (zoomChange)="onZoomChange($event)"
                             (mapClick)="onMapClick($event)">
                             <agm-marker-cluster imagePath='assets/images/cluster-icons/m'   #markerCluster  >
                               <agm-marker *ngFor="let m of markerEvents; let i = index" style="position: fixed;" 
                                  [latitude]="m.lat" [longitude]="m.lng"
                                 [label]="m.label" [markerDraggable]="false"  [iconUrl]="iconURL(m)">
                               </agm-marker>
                             </agm-marker-cluster>
                           </agm-map>
                         </div>
                          </div>
                    </div>
                </mat-tab>
          </mat-tab-group>
          
    </div>






