<div class="row  modal-header">
    <div class="col-11">
        <div class="row mt-2">
            <div class="col-12">
                <span *ngIf="entity == 'Users'||entity == 'Contacts'">
                    <img class="avatarimage"
                        [src]="entityDetails?.profilePicture ? entityDetails.profilePicture:'assets/avatar.svg'"
                        onError="this.src='assets/avatar.svg'" />
                    <span *ngIf="entity == 'Users'"
                        style="color: rgb(252, 251, 251);font-size: 20px;font-weight: bold;">
                        {{''+entityDetails?.firstName|titlecase}} {{entityDetails?.lastName|titlecase}}</span>
                    <span *ngIf="entity == 'Contacts'"
                        style="color: rgb(252, 251, 251);font-size: 20px;font-weight: bold;">
                        {{' '+entityDetails?.firstName|titlecase}} {{entityDetails?.lastName|titlecase}}</span>
                </span>
                <span *ngIf="entity == 'Products'">
                    <img class="avatarimage"
                        [src]="entityDetails?.profilePicture ? entityDetails.profilePicture:'assets/images/iconsForsvg/icon_product.svg'"
                        onError="this.src='assets/images/iconsForsvg/icon_product.svg'" />
                    <span style="color: rgb(252, 251, 251);font-size: 20px;font-weight: bold;">{{'
                        '+entityDetails?.productName|titlecase}}</span>
                </span>
                <span *ngIf="entity == 'Sites'">
                    <img src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
                    <span style="color: rgb(252, 251, 251);font-size: 20px;font-weight: bold;">
                        {{' '+entityDetails?.companyName|titlecase}}</span>
                </span>
            </div>
        </div>
    </div>
    <div class="col-1  mt-2 ">
        <mat-icon (click)="RemovePlanningFilter()">close</mat-icon>
    </div>
</div>

<mat-dialog-content class="mr-0 ml-0">
    <div class="row w-100">
        <div class="col-8">
            <mat-form-field class="w-100">
                <mat-label>Search</mat-label>
                <input matInput placeholder="Placeholder">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
        <div *ngIf="showicon == true" class="col-3">
            <button class="mr-1 pointer removefilter">{{"Remove Filter"|translate}}</button>
        </div>
    </div>
    <div class="row w-100">
        <div class="col-6 p-2" *ngFor="let group of Allgroupdata">
            <mat-checkbox class="example-margin" (change)="selactGroupId($event,group)">
                <mat-icon>group</mat-icon>
                <span class="ml-3">{{group.name|titlecase}}</span>
            </mat-checkbox>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">
    <div class="row justify-content-center">
        <div class="col-5"> <button mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
        </div>
        <div class="col-5"> <button mat-raised-button class="b-blue" (click)="onSubmit()">{{'Apply'|translate}}</button>
        </div>
    </div>
</mat-dialog-actions>