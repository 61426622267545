<div class="d-flex flex-row align-items-center modal-header">
  <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">{{'Create Category'|translate}}</h2>
  <mat-icon (click)="closepopup()">close</mat-icon>
</div>
 
  <mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="categoriesForm" id="categories-form">

    <mat-form-field appearance="legacy" class="w-100">
      <mat-label>{{'Category Name'|translate}}<span class="danger"> * </span></mat-label>
      <input matInput type="text"  formControlName="categoryName">
    </mat-form-field>
    <mat-form-field appearance="legacy" class="w-100" *ngIf="data?.type == 'TimeOff'">
      <mat-label>{{'Description'|translate}}<span class="danger"> * </span></mat-label>
      <input matInput type="text"  formControlName="Description">
    </mat-form-field>

    <div class="col-md-5">
      <mat-label>{{'Category Color'|translate}} <span class="danger">* </span></mat-label>
      <input formControlName="color" [style.background]="color1" style="border:none" [(colorPicker)]="color1"
        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />

      <mat-label>{{'Text Color'|translate}} <span class="danger">* </span></mat-label> 
      <input formControlName="fontColor" [style.background]="color2" style="border:none" [(colorPicker)]="color2"
        (colorPickerOpen)="onEventLog1('colorPickerOpen', $event)"
        (colorPickerClose)="onEventLog1('colorPickerClose', $event)" />
    </div>
  </form>

  </mat-dialog-content>


<mat-dialog-actions align="end" class="my-1">
  <div class="row w-100">
    <div class="col-4">
      <button mat-button mat-dialog-close>{{'Cancel'|translate}}</button>

    </div>
    <div class="col-4">
      <button mat-raised-button class="b-blue" (click)="createcategory()">{{'Create'|translate}}</button>

    </div>
  </div>
</mat-dialog-actions>