import { Component, Inject, Injector, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { StrategyService } from 'src/app/core/services/strategy.service';
import { CreateStrategyComponent } from '../../../core/components/create-strategy/create-strategy.component';
import { PageEvent } from '@angular/material/paginator';
import { MatTabGroup } from '@angular/material/tabs';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { MessageService } from 'src/app/core/services/message.service';
import { DeleteCommomComponent } from '../../commonForAll/delete-commom/delete-commom.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { trigger } from '@angular/animations';
import { CalendarService } from 'src/app/core/services/calendar.service';
@Component({
  selector: 'app-reminder-create',
  templateUrl: './reminder-create.component.html',
  styleUrls: ['./reminder-create.component.scss']
})
export class ReminderCreateComponent implements OnInit {
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  thirdFormGroup!: FormGroup;
  @ViewChild('tabs') tabs!: MatTabGroup;
  @Input() selectedIndex = 0;
  @Input() disabled!: boolean;
  selactValues4 = ["Events", "Users",  "Contacts",  "Sites", ];
  selactValues5 = ["Transactions", "Users",  "Contacts", "Sites","Products" ];
  ParameterValuies:any=['Start','End']
  ComparisonValues:any=[ 'Before', 'After']
  stringoptions = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }];
  numberoptions = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }, { v1: '>', v2: 'greater than' }, { v1: '=>', v2: 'greater than or equal' }, { v1: '<', v2: 'less than ' }]
  textoptions = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }]
  booleanoptions = [{ v1: '=', v2: 'equal' }]
  AllOption = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }, { v1: '>', v2: 'greater than' },
  { v1: '<', v2: 'less than ' }, { v1: '=>', v2: 'greater than or equal' }, { v1: '<=', v2: 'less than or equal' }];
  values2 = [];
  selected = -1;
  selected1 = -1;
  selected2 = -1;
  events = [];
  type = [];
  radiovalue!: number;
  zipcode!: number;
  additionalFieldLines: any[] = [];
  lineCounter: number = 0;
  strategyarray: any[] = [];
  events1 = [];
  newvalue: any;
  keywords: string[] = [];
  ScreenType = ''
  durationtype:any=['Minutes','Hours','Days']
  triggertype:any=['Notification','Email', 'Email And Notification']
  Entitytype=''
  users$:any = [];
  filteredusers:any = [];
  ReminderUsers: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<CreateStrategyComponent>,
    private formBuilder: FormBuilder,
    private strategyservice: StrategyService,
    private router: Router,
    private messageService: MessageService,
    private translate: TranslateService ,
    private manageUserService: ManageUsersService,
    private injector: Injector,
  ) {
    if(sessionStorage.getItem('lang')){
      const lang:any=sessionStorage.getItem('lang')
      this.translate.use(lang);
    }else{
      this.translate.use('English');
    }
    
    this.firstFormGroup = this.formBuilder.group({
      reminderConditions: this.formBuilder.array([]),
    });

  }


  ngOnInit(): void {
    console.log(this.data);
    console.log(this.data.selectedUsers);
    this.ScreenType = this.data.screenType;
    if (this.ScreenType === 'updateReminder') {
      this.populateFormForEdit(this.data.data); 
    } else {
      this.Addcondictuon(); 
    }
    this.getdatafordroupdown(); // Fetch dropdown data
    
    this.Entitytype = this.data?.type;
    this.selectedIndex = 0;
    this.isSelected(this.selectedIndex);
  
    // Set initial value for the multi-select control with selected user IDs
    
  }
  isSelected(index: number): boolean {
    if (this.selectedIndex === index) {
      return false;
    } else {
      return true;
    }
  }
  onNext(e: Event, index:any): void {
    console.log(this.tabs._tabs);
    this.selectedIndex = index;
    this.isSelected(index);
  }

  dismiss(): void {
    this.matDialogRef.close();
  }


  populateFormForEdit(reminderData: any): void {
    console.log(reminderData);
    this.Addcondictuon();
  
    // Set the values for other form controls
    this.reminderConditions().at(0).get('Activate').setValue(true);
    this.reminderConditions().at(0).get('triggerMoment').setValue(reminderData.isAfter ? 'After' : 'Before');
    this.reminderConditions().at(0).get('durationtype').setValue(reminderData.periodName == 'Minute' ? 'Minutes' : reminderData.periodName == 'Hour' ? 'Hours' :  'Days');
    this.reminderConditions().at(0).get('value').setValue(reminderData.value);
    this.reminderConditions().at(0).get('Parameter').setValue(reminderData.conditionParameter);
    this.reminderConditions().at(0).get('triggertype').setValue(reminderData.reminderTypeName);
    const userIds = reminderData.users.map(user => user.id);
    this.reminderConditions().at(0).get('UserId').setValue(userIds);
  }
  

  extractUserIds(users: any[]): string[] {
    return users.map(user => user.id);
  }


  getcheckdetails() {
    this.Addcondictuon()
  }

  CreateReminder() {
    if(this.ScreenType === 'updateReminder'){
      const formValues = this.reminderConditions().at(0).value;
      // Construct the API payload
      const payload = {
        reminderId: this.data.data.id,
        reminderType: formValues.triggertype == 'Notification' ? 0 : formValues.triggertype == 'Emails' ? 1 : 2,
        conditionParameter: formValues.Parameter,
        isAfter: formValues.triggerMoment == 'After' ? true : false,
        value: formValues.value,
        period: formValues.durationtype == 'Minutes' ? 0 : formValues.durationtype == 'Hours' ? 1 : 2,  
        reminderUsersIds: formValues.UserId
      };
      if (this.data.type == 'Event') {
        payload['eventId'] = this.data.EventId;
      } else if (this.data.type == 'Initiatives') {
        payload['initiativeId'] = this.data.EventId;
      } else if (this.data.type == 'Task') {
        payload['taskId'] = this.data.EventId;
      }
      console.log(payload);
      this.injector.get(CalendarService).updateReminders(payload).subscribe((reminderRes: any) => {
        console.log('Reminders Updated successfully:', reminderRes);
        if(reminderRes){
          this.matDialogRef.close(reminderRes);
        }
      }, error => {
        console.error('Error Updating reminders:', error);
      });
    
    } else {
      this.matDialogRef.close(this.firstFormGroup.value);
    }
  }



  reminderConditions(): FormArray {
    return this.firstFormGroup.get("reminderConditions") as FormArray
  }


  newFile(): FormGroup {
    return this.formBuilder.group({
      Activate:[true],
      triggerMoment: ['Before'],
      durationtype: ["Minutes"],
      value: [30],
      Parameter: ["Start"],
      triggertype:['Notification'],
      UserId: [''],
    })
  }

  Addcondictuon() {
    this.reminderConditions().push(this.newFile());
  }
  Removecondictuon(i: number) {
    this.reminderConditions().removeAt(i);
  }

  getdatafordroupdown() {
    this.manageUserService.findAllUsersDropdown().subscribe((result) => {
      if (result) {
        if (this.data.selectedUsers && this.data.selectedUsers.length > 0) {
          this.filteredusers = result.filter((user: any) =>
            this.data.selectedUsers.includes(user.id)
          );
        } else {
          this.filteredusers = [];
        }
      }
    });
  }
  
  // Filter the already filtered users when typing in the search box
  filterOptions(value: string, type: string): void {
      this.filteredusers = this.filteredusers.filter((option: any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
  }
  


 

}