import { Component, OnInit } from '@angular/core';
import grapesjs from 'grapesjs';  // Import GrapesJS
@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.scss']
})
export class CreateTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
     this.initializeGrapesJS();
    }
    initializeGrapesJS() {
      const editor = grapesjs.init({
        container: '#editor',  // Element where GrapesJS will be initialized
        fromElement: true,
        height: '100vh',
        width: 'auto',
        storageManager: {
          autoload: true,
        },
      });
   // Open the Blocks panel by targeting the panel directly
   const blocksPanel = editor.Panels.getPanel('panel-blocks');
   if (blocksPanel) {
     blocksPanel.set('visible', true);
   }
    // Open the Layer Manager
    editor.Commands.run('open-layers');

     // Open the Style Manager using its panel ID
     const styleManager = editor.Panels.getPanel('panel-styles');
     if (styleManager) {
       styleManager.set('visible', true);
     }

     
    }
}
