<div class="d-flex flex-row align-items-center modal-header">
    <h2 *ngIf="this.data.type == 'CreateSenerio'" mat-dialog-title
      class="m-0 dialog-title text-center w-100">
      {{'Create Scenerio'}}
    </h2>
    <h2 *ngIf="this.data.type == 'Delay'" mat-dialog-title
    class="m-0 dialog-title text-center w-100">
    {{this.data.showTitle}}
  </h2>
  <h2 *ngIf="this.data.type == 'CreateTrigger'" mat-dialog-title
    class="m-0 dialog-title text-center w-100">
    {{'Add Trigger'}}
  </h2>
  <h2 *ngIf="this.data.type == 'CreateCondition'" mat-dialog-title
  class="m-0 dialog-title text-center w-100">
  {{'Add Condition'}}
</h2>
<h2 *ngIf="this.data.action == 'Editcondition'" mat-dialog-title
class="m-0 dialog-title text-center w-100">
{{'Edit Condition'}}
</h2>
  <h2 *ngIf="this.data.type == 'dragandDrop'" mat-dialog-title
  class="m-0 dialog-title text-center w-100">
  {{this.data.showTitle}}
</h2>
    <mat-icon class="pointer" mat-dialog-close>close</mat-icon>
  </div>
  <mat-dialog-content class="mr-0 ml-0" *ngIf="this.data.type == 'CreateSenerio'">
    <form [formGroup]="ListSearch">
        <div class="row m-4">
            <mat-radio-group formControlName="targetStatus"
                (change)="gettargetStatus($event.value)">
                <mat-radio-button value="Private">
                    <mat-icon
                        style="font-size: 20px;position: relative;top:3px;">lock</mat-icon>
                    {{'Private'|translate}}</mat-radio-button>
                <mat-radio-button value="Public">
                    <img
                        src="../../../../../assets/images/iconsForsvg/Unlock.svg"
                        alt>
                    {{'Public'|translate}}</mat-radio-button>
            </mat-radio-group>
        </div> 
        <div class="row justify-content-center" *ngIf="this.ListSearch.get('isPublic')?.value == false">
            <div class="mt-2 w-100" >
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"User"|translate}}</mat-label>
                    <mat-select [(value)]="selected" (openedChange)="filterOptions('','users')" #select multiple formControlName="PermittedUsers">
                      <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'users')" placeholder="Filter" />
                      <mat-option *ngFor="let user of filterList" [value]="user.id">
                        <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                            [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                            onError="this.src='assets/avatar.svg'">
                        {{ user.firstName| titlecase}} {{user.lastName|titlecase}}
                    </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
        </div>
        <div class="row justify-content-center mt-3">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>{{"Automation Name"}}</mat-label>
                    <input matInput type="text"  formControlName="Name">
                </mat-form-field>
            </div>
        </div>
        <div class="row justify-content-center mt-3">
            <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-label>{{"Description"}}</mat-label>
                    <input matInput type="text"  formControlName="description">
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field  class="w-100">
                    <mat-label>{{"Status"}}</mat-label>
                    <mat-select formControlName="Status">
                        <mat-option value="0">Active</mat-option>
                        <mat-option value="1">InActive</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row w-100">
            <div class="col-12">
                <mat-form-field class="example-chip-list" style="width: 100% !important;">
                    <mat-label>{{"keyword"}}</mat-label><br>
                    <mat-chip-list #chipGridforkeyword aria-label="Fruit selection" class="keywordlist">
                        <mat-chip-row class="keywordrow" *ngFor="let keyword of keywordlist">
                            <button class="keywordbutton">
                                <span class="keywordpostion">{{keyword|titlecase}}</span>
                                <mat-icon class="keywordcancelicon" (click)="remove(keyword)">cancel</mat-icon>
                            </button>
                        </mat-chip-row> 
                    </mat-chip-list>
                    <input [matChipInputFor]="chipGridforkeyword" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event)" />
                </mat-form-field>
            </div>
            
        </div>
        <!-- <div class="row justify-content-center mt-3">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>{{"Keywords"}}</mat-label>
                    <input matInput type="text"  formControlName="keywords">
                </mat-form-field>
            </div>
        </div> -->

    </form>
    <mat-dialog-actions align="end" class="mb-24">
        <div class="row mt-2 mb-4 justify-content-center" >
            <!-- <div class="col-4">
                <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"}}</button>
            </div> -->
            <div class="col-4">
                <button mat-raised-button class="b-blue" [disabled]="!ListSearch.valid" (click)="ApplyAutomation()">{{"Next"}}</button>
            </div>
        </div>
    </mat-dialog-actions>
  </mat-dialog-content>

  <mat-dialog-content class="mr-0 ml-0" *ngIf="this.data.type == 'Delay'">
    <form [formGroup]="ConditionForm">
        <div class="row justify-content-center mt-3">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>{{"Name"}}</mat-label>
                    <input matInput type="text"  formControlName="Name">
                </mat-form-field>
            </div>
        </div>
        <div class="row justify-content-center mt-3">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>{{"Description"}}</mat-label>
                    <input matInput type="text"  formControlName="description">
                </mat-form-field>
            </div>
        </div>
        <!-- <div class="row p-2 justify-content-around"
        style="color: white; background-color:#f6272e; font-weight: 600;">
        <div class="col-6">{{' Trigger Conditions'}} </div>
        <div class="col-2">
            <mat-icon style="color: white;" (click)="Addcondictuon()">add_box</mat-icon>
        </div>
    </div> -->
    <div style="border: 1px solid gray;padding:10px;" class="row w-100">
        <!-- <div class="col-12">
            <mat-form-field class="w-100 ">
                <mat-label>{{'Choose Entity'}}</mat-label>
                <mat-select formControlName="Trigger">
                    <mat-option [value]="type" *ngFor="let type of managementType">
                        {{type}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div formArrayName="Conditions">
                <div *ngFor="let file of Conditions().controls; let i=index" [formGroupName]="i">
                    <div class="row p-2 justify-content-around"
                        style="color: white; background-color:#818582; font-weight: 600;">
                        <div class="col-6"> {{'Condition'}}-{{i+1}}</div>
                        <div class="col-2">
                            <mat-icon *ngIf="i!=0" (click)="Removecondictuon(i)">delete</mat-icon>
                        </div>
                    </div>
                    <div class="row strategycondictions" style="border: 1px solid gray;padding:10px;">
                        <div class="col-4">
                            
                            <ng-container *ngIf="getentityvalue1()=='Users' || getentityvalue1()==null">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="condition">
                                        <mat-option [value]="x.columnName" *ngFor="let x of UsersValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </ng-container>
                           
                            <ng-container *ngIf="getentityvalue1()=='Contacts'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="condition">
                                        <mat-option [value]="x.columnName" *ngFor="let x of ContactsValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                           
                            <ng-container *ngIf="getentityvalue1()=='Products'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="condition">
                                        <mat-option [value]="x.columnName" *ngFor="let x of ProductsValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container *ngIf="getentityvalue1()=='Sites'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="condition">
                                        <mat-option [value]="x.columnName" *ngFor="let x of SitesValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container *ngIf="getentityvalue1()=='Transactions'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="condition">
                                        <mat-option [value]="x.columnName" *ngFor="let x of TransationValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                           
                            <ng-container *ngIf="getentityvalue1()=='Events'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="condition">
                                        <mat-option [value]="x.columnName" *ngFor="let x of EventsValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>

                        </div>
                        <div class="col-4">
                           
                            <ng-container
                                *ngIf="getdataType(i)!='string'&&getdataType(i)!='String' && getdataType(i)!='number'&&getdataType(i)!='Number'
                      && getdataType(i)!='bool' &&getdataType(i)!='boolean'&&getdataType(i)!='Boolean'&&getdataType(i)!='Bool' &&getdataType(i)!='text' &&getdataType(i)!='Text'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Comparison'}}</mat-label>
                                    <mat-select formControlName="Comparison">
                                        <mat-option [value]="x.v1" *ngFor="let x of AllOption">
                                            {{x.v2}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container
                                *ngIf="getdataType(i)=='string' || getdataType(i)=='String' || getdataType(i)=='text'|| getdataType(i)=='Text'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Comparison'}}</mat-label>
                                    <mat-select formControlName="Comparison">
                                        <mat-option [value]="x.v1" *ngFor="let x of stringoptions">
                                            {{x.v2}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container *ngIf="getdataType(i)=='number' || getdataType(i)=='Number'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Comparison'}}</mat-label>
                                    <mat-select formControlName="Comparison">
                                        <mat-option [value]="x.v1" *ngFor="let x of numberoptions">
                                            {{x.v2}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container
                                *ngIf="getdataType(i)=='bool' || getdataType(i)=='boolean'||getdataType(i)=='Bool'||getdataType(i)=='Boolean'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Comparison'}}</mat-label>
                                    <mat-select formControlName="Comparison">
                                        <mat-option [value]="x.v1" *ngFor="let x of booleanoptions">
                                            {{x.v2}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </div>
                        <div class="col-4">
                            <mat-form-field style="width:80%;">
                                <input matInput type="tel" placeholder="value" formControlName="value">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
        </div> -->
        <div class="row mt-2">
            <div class="col-3 fontalign" style="margin-top:15px">
                <mat-label>{{'Wait Delay'}}</mat-label>
            </div>
            <div class="col-3">
                <ng-container>
                    <mat-form-field class="w-100">
                        <mat-label>{{'Delay'}}</mat-label>
                        <mat-select formControlName="period" (selectionChange)="updateValidation($event.value)">
                            <mat-option *ngFor="let delay of timeOptions" [value]="delay.v1">
                                {{delay.v2}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
            <div class="col-3 fontalign" style="text-align: center;margin-top:15px">
                <mat-label>{{'Value'}}</mat-label>
            </div>
            <div class="col-3">
                <ng-container>
                    <mat-form-field class="w-100">
                      <input matInput formControlName="duration"  type="number" min="0" [max]="maxValue" (input)="onInputChange($event)">
                    </mat-form-field>
                  </ng-container>
            </div>
        </div>

        <div class="row w-100">
            <div class="col-6 fontalign" style="margin-top:15px">
                <mat-checkbox>{{"Scheduled Date & Time"}}</mat-checkbox>
            </div>
            <div class="col-6">
                <mat-form-field class="w-100">
                    <input matInput type="datetime-local" [min]="minDate" formControlName="scheduledDateAndTime">
                </mat-form-field>
            </div>
        </div>
    </div>
    </form>
    <mat-dialog-actions align="end" class="mb-24">
        <div class="row mt-2 mb-4 justify-content-center" >
            <div class="col-4">
                <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"}}</button>
            </div>
            <div class="col-4">
                <button mat-raised-button class="b-blue" [disabled]="!ConditionForm.valid" (click)="AddCondition()">{{"Add"}}</button>
            </div>
        </div>
    </mat-dialog-actions>
</mat-dialog-content>

<mat-dialog-content class="mr-0 ml-0" *ngIf="this.data.type == 'CreateCondition' || this.data.action == 'Editcondition'">
    <form [formGroup]="AddConditionForm">
        <div class="row justify-content-center mt-3">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>{{"Name"}}</mat-label>
                    <input matInput type="text"  formControlName="Name">
                </mat-form-field>
            </div>
        </div>
        <div class="row justify-content-center mt-3">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>{{"Description"}}</mat-label>
                    <input matInput type="text"  formControlName="description">
                </mat-form-field>
            </div>
        </div>
        <div class="row p-2 mt-3 justify-content-around"
        style="color: white; background-color:#f6272e; font-weight: 600;">
        <div class="col-6">{{' Trigger Conditions'}} </div>
        <div class="col-2">
            <mat-icon style="color: white;" (click)="Addcondition()">add_box</mat-icon>
        </div>
    </div>
    <div style="border: 1px solid gray;padding:10px;" class="row w-100">
        <div class="col-12">
            <mat-form-field class="w-100 ">
                <mat-label>{{'Choose Entity'}}</mat-label>
                <mat-select formControlName="Trigger">
                    <mat-option [value]="type" *ngFor="let type of managementType">
                        {{type}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div formArrayName="Conditions">
                <div *ngFor="let file of Conditions1().controls; let i=index" [formGroupName]="i">
                    <div class="row p-2 justify-content-around"
                        style="color: white; background-color:#818582; font-weight: 600;">
                        <div class="col-6"> {{'Condition'}}-{{i+1}}</div>
                        <div class="col-2">
                            <mat-icon *ngIf="i!=0" (click)="RemoveCondition(i)">delete</mat-icon>
                        </div>
                    </div>
                    <div class="row strategycondictions" style="border: 1px solid gray;padding:10px;">
                        <div class="col-4">
                            
                            <ng-container *ngIf="getentityvalue2()=='Users' || getentityvalue2()==null">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x.columnName" *ngFor="let x of UsersValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </ng-container>
                           
                            <ng-container *ngIf="getentityvalue2()=='Contacts'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x.columnName" *ngFor="let x of ContactsValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                           
                            <ng-container *ngIf="getentityvalue2()=='Products'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x.columnName" *ngFor="let x of ProductsValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container *ngIf="getentityvalue2()=='Sites'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x.columnName" *ngFor="let x of SitesValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container *ngIf="getentityvalue2()=='Transactions'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x.columnName" *ngFor="let x of TransationValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="getentityvalue2()=='Orders'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x.columnName" *ngFor="let x of OrderValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="getentityvalue2()=='Tasks'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x.columnName" *ngFor="let x of TaskValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="getentityvalue2()=='Initiatives'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x.columnName" *ngFor="let x of InitiativeValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                           
                           
                            <ng-container *ngIf="getentityvalue2()=='Events'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x.columnName" *ngFor="let x of EventsValue"
                                            (click)="selactdatatype(x,i)">
                                            {{x.columnName|titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="getentityvalue2()=='Email'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option *ngFor="let condition of Mailconditions" [value]="condition"
                                        (click)="selactdatatype(condition,i)">
                                        <span>
                                            <img *ngIf="condition == 'Sent'" class="email-icon" src="../../../../../../assets/images/iconsForsvg/send-email.svg" alt="Send Email">
                                            <img *ngIf="condition == 'Open'" class="email-icon" src="../../../../../../assets/images/iconsForsvg/open-email.svg" alt="Send Email">
                                            <img *ngIf="condition == 'Click'" class="email-icon" src="../../../../../../assets/images/iconsForsvg/click-email.svg" alt="Send Email">
                                            <img *ngIf="condition == 'Respond'" class="email-icon" src="../../../../../../assets/images/iconsForsvg/reply-email.svg" alt="Send Email">
                                            <img *ngIf="condition == 'Failed'" class="email-icon" src="../../../../../../assets/images/iconsForsvg/failed-email.svg" alt="Send Email">
                                          </span>
                                            {{ condition }}
                                          </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </div>
                        <div class="col-4" *ngIf="getentityvalue2()!='Email'">
                           
                            <ng-container
                                *ngIf=" getdataType1(i)!='string'&&getdataType1(i)!='String' && getdataType1(i)!='number'&&getdataType1(i)!='Number'
                      && getdataType1(i)!='bool' &&getdataType1(i)!='boolean'&&getdataType1(i)!='Boolean'&&getdataType1(i)!='Bool' &&getdataType1(i)!='text' &&getdataType1(i)!='Text'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Comparison'}}</mat-label>
                                    <mat-select formControlName="conditionComparison">
                                        <mat-option [value]="x.v1" *ngFor="let x of AllOption">
                                            {{x.v2}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container
                                *ngIf="getdataType1(i)=='string' || getdataType1(i)=='String' || getdataType1(i)=='text'|| getdataType1(i)=='Text'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Comparison'}}</mat-label>
                                    <mat-select formControlName="conditionComparison">
                                        <mat-option [value]="x.v1" *ngFor="let x of stringoptions">
                                            {{x.v2}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container *ngIf="getdataType1(i)=='number' || getdataType1(i)=='Number'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Comparison'}}</mat-label>
                                    <mat-select formControlName="conditionComparison">
                                        <mat-option [value]="x.v1" *ngFor="let x of numberoptions">
                                            {{x.v2}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container
                                *ngIf="getdataType1(i)=='bool' || getdataType1(i)=='boolean'||getdataType1(i)=='Bool'||getdataType1(i)=='Boolean'">
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Comparison'}}</mat-label>
                                    <mat-select formControlName="conditionComparison">
                                        <mat-option [value]="x.v1" *ngFor="let x of booleanoptions">
                                            {{x.v2}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </div>
                        <div class="col-4" *ngIf="getentityvalue2()=='Email'">
                            <ng-container *ngIf="MailDataType == 'Sent'">
                            <mat-form-field class="w-100">
                                <mat-label>{{'Comparison'}}</mat-label>
                                <mat-select formControlName="conditionComparison">
                                    <mat-option [value]="x.v1" *ngFor="let x of mailoptions">
                                        {{x.v2}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="MailDataType == 'Failed'">
                            <mat-form-field class="w-100">
                                <mat-label>{{'Comparison'}}</mat-label>
                                <mat-select formControlName="conditionComparison">
                                    <mat-option [value]="x.v1" *ngFor="let x of FailedOptions">
                                        {{x.v2}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="MailDataType == 'Open' || MailDataType == 'Click' || MailDataType == 'Respond'">
                            <mat-form-field class="w-100">
                                <mat-label>{{'Comparison'}}</mat-label>
                                <mat-select formControlName="conditionComparison">
                                    <mat-option [value]="x.v1" *ngFor="let x of AllOption">
                                        {{x.v2}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        </div>
                        <div class="col-4" *ngIf="MailDataType != 'Sent' && MailDataType != 'Failed'">
                            <mat-form-field style="width:80%;">
                                <input matInput type="tel" placeholder="value" formControlName="value">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
        </div>
        <div class="row mt-2">
            <div class="col-3 fontalign" style="margin-top:15px">
                <mat-label>{{'Wait Delay'}}</mat-label>
            </div>
            <div class="col-3">
                <ng-container>
                    <mat-form-field class="w-100">
                        <mat-label>{{'Delay'}}</mat-label>
                        <mat-select formControlName="period" (selectionChange)="updateValidation($event.value)">
                            <mat-option *ngFor="let delay of timeOptions" [value]="delay.v1">
                                {{delay.v2}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
            <div class="col-3 fontalign" style="text-align: center;margin-top:15px">
                <mat-label>{{'Value'}}</mat-label>
            </div>
            <div class="col-3">
                <ng-container>
                    <mat-form-field class="w-100">
                      <input matInput formControlName="duration"  type="number" min="0" [max]="maxValue" (input)="onInputChange($event)">
                    </mat-form-field>
                  </ng-container>
            </div>
        </div>

        <div class="row w-100">
            <div class="col-6 fontalign" style="margin-top:15px">
                <span>Scheduled Date & Time</span>
            </div>
            <div class="col-6">
                <mat-form-field class="w-100">
                    <input matInput type="datetime-local" [min]="minDate" formControlName="scheduledDateAndTime">
                </mat-form-field>
            </div>
        </div>
    </div>
    </form>
    <mat-dialog-actions align="end" class="mb-24">
        <div class="row mt-2 mb-4 justify-content-center" >
            <div class="col-4">
                <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"}}</button>
            </div>
            <div class="col-4" *ngIf="this.data.action == 'Editcondition'">
                <button mat-raised-button class="b-blue" (click)="AddConditionSubmit()">{{"Update"}}</button>
            </div>
            <div class="col-4" *ngIf="this.data.action != 'Editcondition'">
                <button mat-raised-button class="b-blue" (click)="AddConditionSubmit()">{{"Add"}}</button>
            </div>
        </div>
    </mat-dialog-actions>
</mat-dialog-content>

<mat-dialog-content class="mr-0 ml-0" *ngIf="this.data.type == 'CreateTrigger'">
    <form [formGroup]="TriggerForm">
        <div class="row justify-content-center mt-3">
            <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Choose Entity</mat-label>
                  <mat-select formControlName="entity" (selectionChange)="onEntityChange($event.value)">
                    <mat-select-trigger>
                      <mat-icon *ngIf="entityControl?.value?.entity === 'User'">person</mat-icon>
                      <mat-icon style="color: rgb(100, 149, 237)" *ngIf="entityControl?.value?.entity === 'Contact'">contacts</mat-icon>
                      <mat-icon *ngIf="entityControl?.value?.entity === 'Product'">business_center</mat-icon>
                      <mat-icon style="color:green" *ngIf="entityControl?.value?.entity === 'Site'">business</mat-icon>
                      <mat-icon style="color:rgb(100, 149, 237)" *ngIf="entityControl?.value?.entity === 'Transaction'">shopping_cart</mat-icon>
                      <mat-icon style="color:#efc447" *ngIf="entityControl?.value?.entity === 'Order'">assignment</mat-icon>
                      <mat-icon *ngIf="entityControl?.value?.entity === 'Event'">event</mat-icon>
                      <mat-icon style="color:green" *ngIf="entityControl?.value?.entity === 'Task'">assignment_turned_in</mat-icon>
                      <mat-icon *ngIf="entityControl?.value?.entity === 'Initiative'">ballot</mat-icon>
                      <mat-icon *ngIf="entityControl?.value?.entity === 'Notification'">campaign</mat-icon>
                      <mat-icon *ngIf="entityControl?.value?.entity === 'Email'">mail</mat-icon>
                      <mat-icon style="color:rgb(100, 149, 237)" *ngIf="entityControl?.value?.entity === 'File'">file_copy</mat-icon>
                      <mat-icon style="color:#ffc16f" *ngIf="entityControl?.value?.entity === 'Note'">speaker_notes</mat-icon>
                      <mat-icon style="color:green" *ngIf="entityControl?.value?.entity === 'Report'">bar_chart</mat-icon>
                      <img *ngIf="entityControl?.value?.entity === 'Automation'" style="vertical-align: middle; width: 25px; height: 25px;" src="assets/images/iconsForsvg/setting-automation.svg" alt="Automation">
                      {{ entityControl?.value?.entity }}
                    </mat-select-trigger>
              
                    <mat-option *ngFor="let entity of EntityValue" [value]="entity">
                      <mat-icon *ngIf="entity.entity === 'User'">person</mat-icon>
                      <mat-icon style="color: rgb(100, 149, 237)" *ngIf="entity.entity === 'Contact'">contacts</mat-icon>
                      <mat-icon *ngIf="entity.entity === 'Product'">business_center</mat-icon>
                      <mat-icon style="color:green" *ngIf="entity.entity === 'Site'">business</mat-icon>
                      <mat-icon style="color:rgb(100, 149, 237)" *ngIf="entity.entity === 'Transaction'">shopping_cart</mat-icon>
                      <mat-icon style="color:#efc447" *ngIf="entity.entity === 'Order'">assignment</mat-icon>
                      <mat-icon *ngIf="entity.entity === 'Event'">event</mat-icon>
                      <mat-icon style="color:green" *ngIf="entity.entity === 'Task'">assignment_turned_in</mat-icon>
                      <mat-icon *ngIf="entity.entity === 'Initiative'">ballot</mat-icon>
                      <mat-icon *ngIf="entity.entity === 'Notification'">campaign</mat-icon>
                      <mat-icon *ngIf="entity.entity === 'Email'">mail</mat-icon>
                      <mat-icon style="color:rgb(100, 149, 237)" *ngIf="entity.entity === 'File'">file_copy</mat-icon>
                      <mat-icon style="color:#ffc16f" *ngIf="entity.entity === 'Note'">speaker_notes</mat-icon>
                      <mat-icon style="color:green" *ngIf="entity.entity === 'Report'">bar_chart</mat-icon>
                      <img *ngIf="entity.entity === 'Automation'" style="vertical-align: middle; width: 25px; height: 25px;" src="assets/images/iconsForsvg/setting-automation.svg" alt="Automation">
                      {{ entity.entity }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
          </div>
          
          
          <div class="row justify-content-center mt-3" *ngIf="this.filteredEvents.length > 0">
            <div class="col-12">
              <label style="font-weight: bold;margin-bottom: 10px;">{{ "Select Trigger" }}</label>
              <mat-radio-group formControlName="event" class="w-100">
                <div *ngFor="let event of filteredEvents">
                  <mat-radio-button [value]="event" style="margin-bottom: 10px;">
                    {{ event.name }}
                  </mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
          </div>     
    </form>
    <mat-dialog-actions align="end" class="mb-24">
        <div class="row mt-2 mb-4 justify-content-center" >
            <div class="col-4">
                <button mat-raised-button class="b-blue" (click)="AddTrigger()">{{"Add"}}</button>
            </div>
        </div>
    </mat-dialog-actions>
</mat-dialog-content>

<mat-dialog-content class="mr-0 ml-0" *ngIf="this.data.type == 'dragandDrop' && this.data.showTitle != 'Create Delay'">
    <form [formGroup]="DragForm">
        <div class="row justify-content-center mt-3">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>{{"Name"}}</mat-label>
                    <input matInput type="text"  formControlName="Name">
                </mat-form-field>
            </div>
        </div>
        <div class="row justify-content-center mt-3">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>{{"Description"}}</mat-label>
                    <input matInput type="text"  formControlName="description">
                </mat-form-field>
            </div>
        </div>
    </form>
    <mat-dialog-actions align="end" class="mb-24">
        <div class="row mt-2 mb-4 justify-content-center" >
            <!-- <div class="col-4">
                <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"}}</button>
            </div> -->
            <div class="col-4">
                <button mat-raised-button class="b-blue" [disabled]="!DragForm.valid" (click)="AddScenerio()">{{"Add"}}</button>
            </div>
        </div>
    </mat-dialog-actions>
</mat-dialog-content>
