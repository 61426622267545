import { Component, Inject, Injector, OnInit } from '@angular/core';
import { ManageUsersService } from '../../services/manage-users.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarService } from '../../services/calendar.service';
import { ManageContactsService } from '../../services/manage-contacts.service';
import { ManageSitesService } from '../../services/manage-sites.service';
import { ManageProductsService } from '../../services/manage-products.service';

@Component({
  selector: 'app-select-pipeline',
  templateUrl: './select-pipeline.component.html',
  styleUrls: ['./select-pipeline.component.scss'],
})
export class SelectPipelineComponent implements OnInit {
  listofFindPipelines: any;
  initiativeForm!: FormGroup;
  taskForm!: FormGroup;
  screenType = '';
  filteredusers: any;
  users$: any;
  Condition: any = [
    { v1: '=', v2: 'equal' },
    { v1: '!=', v2: 'not equal' },
    { v1: '>', v2: 'greater than' },
    { v1: '=>', v2: 'greater than or equal' },
    { v1: '<', v2: 'less than' },
    { v1: '<=', v2: 'less than or equal' },
  ];
  AllOption = [
    { v1: '=', v2: 'equal' },
    { v1: '!=', v2: 'not equal' },
    { v1: '>', v2: 'greater than' },
    { v1: '<', v2: 'less than ' },
    { v1: '=>', v2: 'greater than or equal' },
    { v1: '<=', v2: 'less than or equal' },
  ];
  stepName: any;
  categories: any;
  totalitems: any;
  CriticityId$: any;
  typeOfScreen: string;
  tags: Object;
  sites$: any;
  filteredSites: any;
  products$: any;
  filteredProducts: any;
  contacts$: any;
  filteredContacts: any;
  constructor(
    private userService: ManageUsersService,
    private formBuilder: FormBuilder,
    private injector: Injector,
    private dialogRef: MatDialogRef<SelectPipelineComponent>,
    @Inject(MAT_DIALOG_DATA) public row: any
  ) {
    this.initiativeForm = this.formBuilder.group({
      Title: [null],
      StartDate: [null],
      EndDate: [null],
      budget: [null],
      CurrencyId: [null],
      InitiativeStatus: [null],
      IsPublic: this.row.type == 'initiativeKanbanscreen' ? [false] : [true],
      IsPrivate: [false],
      Condition: [],
      Keyword: [''],
      StepIds: [],
      PipelineId: [this.row?.selectedOption],
      TotalBudget: [null],
      ConditionForTotalBudget: [],
      LinkedUserIds: [null],
      ContactsId: [''],
      SitesId: [''],
      ProductsId: [''],
      strategyConditions: this.formBuilder.array([]),
    });
    this.Addcondictuon();
    this.taskForm = this.formBuilder.group({
      AdvancedSearch: [null],
      Title: [null],
      StartDate: [null],
      EndDate: [null],
      Hours: [null],
      Minutes: [null],
      IsPredecessortask: [false],
      IsSuccessortask: [false],
      Status: [null],
      IsPublic: this.row.type == 'taskKanbanScreen' ? [false] : [true],
      IsPrivate: [false],
      UsersId: [null],
      Ischild: [false],
      Isparent: [false],
      Condition: [],
      Keyword: [''],
      CategoryIds: [''],
      CriticLevelIds: [''],
      StepIds: [],
      ContactsId: [''],
      SitesId: [''],
      ProductsId: [''],
      PipelineId: [this.row?.selectedOption],
      tagConditions: this.formBuilder.array([]),
    });
    this.Addcondition();
  }

  ngOnInit(): void {
    console.log(this.row);
    this.screenType = this.row.type;
    if (this.screenType == 'taskScreen') {
      this.taskForm.get('AdvancedSearch')?.setValue('Tasks');
    }

    this.getallusers();
    this.getallCategory();
    this.getStepsByPipeLineId();
    this.getallContacts()
    this.getallSites()
    this.getallProducts()
    this.getTags();
  }

  getTags() {
    this.typeOfScreen =
      this.row.type == 'taskScreen'
        ? 'Tasks'
        : this.row.type == 'taskKanbanScreen'
        ? 'Tasks'
        : 'Initiatives';
    this.injector
      .get(ManageUsersService)
      .FindAllTags(this.typeOfScreen)
      .subscribe((res) => {
        this.tags = res;
        console.log(this.tags)
      });
  }

  filterOptions(value: string, type: string): void {
    console.log(type);
      this.filteredContacts = this.contacts$.filter((option:any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
      this.filteredSites = this.sites$.filter((option:any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
      this.filteredProducts = this.products$.filter((option:any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
      this.filteredusers = this.users$.filter((option:any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
  }

  Addcondition() {
    this.tagConditions().push(this.newFile1());
  }
  Removecondition(i: number) {
    this.tagConditions().removeAt(i);
  }

  selactdatatype1(event: any, index: number) {
    this.tagConditions().at(index).get('dataType')?.setValue(event.dataType);
    this.tagConditions().at(index).get('isTag')?.setValue(event.isTag);
  }
  getdataType1(index: number) {
    const value = this.tagConditions().at(index).get('dataType')?.value;
    return value;
  }
  tagConditions(): FormArray {
    return this.taskForm.get('tagConditions') as FormArray;
  }

  getconditionDropDownListvalue1(index: number) {
    return this.tagConditions().at(index).get('conditionDropDownList')?.value;
  }
  newFile1(): FormGroup {
    return this.formBuilder.group({
      tagColumnId: [],
      condition: [''],
      value: [''],
    });
  }

  Addcondictuon() {
    this.strategyConditions().push(this.newFile());
  }
  Removecondictuon(i: number) {
    this.strategyConditions().removeAt(i);
  }

  selactdatatype(event: any, index: number) {
    this.strategyConditions()
      .at(index)
      .get('dataType')
      ?.setValue(event.dataType);
    this.strategyConditions().at(index).get('isTag')?.setValue(event.isTag);
  }
  getdataType(index: number) {
    const value = this.strategyConditions().at(index).get('dataType')?.value;
    return value;
  }
  strategyConditions(): FormArray {
    return this.initiativeForm.get('strategyConditions') as FormArray;
  }

  getconditionDropDownListvalue(index: number) {
    return this.strategyConditions().at(index).get('conditionDropDownList')
      ?.value;
  }
  newFile(): FormGroup {
    return this.formBuilder.group({
      tagColumnId: [],
      condition: [''],
      value: [''],
    });
  }

  getStepsByPipeLineId() {
    this.stepName = this.row.stepsByPipe;
  }

  getallCategory() {
    // fetchCategoryService
    this.injector
      .get(CalendarService)
      .fetchCategoryService(100, 1)
      .subscribe((res) => {
        this.categories = res;
        this.totalitems = res.length;
      });
    this.CriticityId$ = this.injector.get(CalendarService).findCriticity();
  }

  getallusers() {
    // get all users
    this.userService.findAllUsersDropdown().subscribe((result) => {
      if (result) {
        this.users$ = result;
        this.filteredusers = result;
      }
    });
  }

  getallContacts(){
    // get all contacts
    this.injector.get(ManageContactsService).findAllContactsDropdown().subscribe((result) => {
      if (result) {
        this.contacts$ = result;
        this.filteredContacts = result;
      }
    });
  } 

  getallSites(){
    // get all sites
    this.injector.get(ManageSitesService).findAllSitesDropdown().subscribe((result) => {
      if (result) {
        this.sites$ = result;
        this.filteredSites = result;
      }
    });
  } 
  getallProducts(){
    // get all sites
    this.injector.get(ManageProductsService).findAllProductsDropdown().subscribe((result) => {
      if (result) {
        this.products$ = result;
        this.filteredProducts = result;
      }
    });
  } 

  submit() {
    if (
      this.screenType == 'taskScreen' ||
      this.screenType == 'taskKanbanScreen'
    ) {
      const formValues = this.taskForm.value;
      const validStrategyConditions = formValues.tagConditions.filter(
        (condition: any) => {
          return (
            condition.tagColumnId || condition.condition || condition.value
          );
        }
      );

      if (validStrategyConditions.length > 0) {
        validStrategyConditions.forEach((condition: any, index: number) => {
          formValues[`TagFilter[${index}].TagColumnId`] = condition.tagColumnId;
          formValues[`TagFilter[${index}].Condition`] = condition.condition;
          formValues[`TagFilter[${index}].Value`] = condition.value;
        });
      }
      const payload: any = {};

      for (const key in formValues) {
        if (
          formValues[key] !== null &&
          formValues[key] !== '' &&
          key !== 'tagConditions'
        ) {
          payload[key] = formValues[key];
        }
      }
      this.dialogRef.close(payload);
    } else if (
      this.screenType == 'initiativescreen' ||
      this.screenType == 'initiativeKanbanscreen'
    ) {
      const formValues = this.initiativeForm.value;
      const validStrategyConditions = formValues.strategyConditions.filter(
        (condition: any) => {
          return (
            condition.tagColumnId || condition.condition || condition.value
          );
        }
      );

      if (validStrategyConditions.length > 0) {
        validStrategyConditions.forEach((condition: any, index: number) => {
          formValues[`TagFilter[${index}].TagColumnId`] = condition.tagColumnId;
          formValues[`TagFilter[${index}].Condition`] = condition.condition;
          formValues[`TagFilter[${index}].Value`] = condition.value;
        });
      }

      const payload: any = {};
      for (const key in formValues) {
        if (
          formValues[key] !== null &&
          formValues[key] !== '' &&
          key !== 'strategyConditions'
        ) {
          payload[key] = formValues[key];
        }
      }
      this.dialogRef.close(payload);
    }
  }
}
