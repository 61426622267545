<div class="d-flex flex-row align-items-center modal-header">
  <h2 *ngIf="ScreenType == 'CreateReminder'" mat-dialog-title class="m-0 dialog-title text-center w-100">
    {{'Add Reminder'|translate}}
  </h2>
  <h2 *ngIf="ScreenType == 'updateReminder'" mat-dialog-title class="m-0 dialog-title text-center w-100">
    {{'Update Reminder'|translate}}
  </h2>
  <mat-icon mat-dialog-close >close</mat-icon>
</div>



<mat-dialog-content id="create-strategy" style="padding: 0 !important;">
  <form [formGroup]="firstFormGroup">
    <div formArrayName="reminderConditions" style="width: 100%;"> 
      <div style="padding:10px; width: 100%;" class="w-100" *ngFor="let file of reminderConditions().controls; let i=index" [formGroupName]="i">
        <!-- <div class="row p-1 justify-content-around w-100" style="color: white; background-color:#818582; font-weight: 600;">
          <div class="col-8">
            <mat-slide-toggle formControlName="Activate">{{'Activate'|translate}} (Yes/No)</mat-slide-toggle>
          </div>
        <div class="col-2">
          <mat-icon *ngIf="i!=0" (click)="Removecondictuon(i)">delete</mat-icon>
        </div>
    </div> -->
        <div class="row mt-2 strategycondictions">
          <div class="col-6">
            <!-- UsersValue -->
              <mat-form-field  class="w-100">
                  <mat-label>{{'Trigger'|translate}}</mat-label>
                  <mat-select formControlName="triggertype">
                      <mat-option [value]="x" *ngFor="let x of triggertype">
                          {{x|titlecase}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field  class="w-100">
              <mat-select formControlName="triggerMoment">
                  <mat-option [value]="x" *ngFor="let x of ComparisonValues">
                      {{x|translate}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          </div>
        </div>
        <div class="row mt-2 strategycondictions">
          <div class="col-4">
            <mat-form-field  class="w-100">
              <mat-label>{{'Parameter'|translate}}</mat-label>
              <mat-select formControlName="Parameter">
                  <mat-option [value]="x" *ngFor="let x of ParameterValuies">
                      {{x|translate}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field style="width:100%;">
              <input matInput type="number"  formControlName="value">
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field  class="w-100">
              <mat-select formControlName="durationtype">
                  <mat-option [value]="x" *ngFor="let x of durationtype">
                      {{x|translate}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          </div>
        </div>
        <div class="row mt-2 strategycondictions" *ngIf="data.type != 'TimeTrack'">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{ "Users" | translate }} <span style="color: red;">*</span></mat-label>
            <mat-select multiple (openedChange)="filterOptions('','users')" #select formControlName="UserId">
              <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'users')" placeholder="Filter" />
              <mat-option *ngFor="let user of filteredusers" [value]="user.id">
                <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                  [src]="user?.profilePicture ? user.profilePicture : 'assets/avatar.svg'" onError="this.src='assets/avatar.svg'">
                {{ user.firstName | titlecase }} {{ user.lastName | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <div class="row mt-2 mb-2">
    <div class="col-2">
      <!-- <mat-icon  (click)="Addcondictuon()">add_box</mat-icon> -->
    </div>
    <div class="col-4">

    </div>
    <div class="col-3">
  </div>
  <div class="col-3">
    <button mat-raised-button class="b-blue" *ngIf="ScreenType == 'CreateReminder'"
    (click)="CreateReminder()">{{'Add'|translate}}</button>
  <button mat-raised-button class="b-blue" *ngIf="ScreenType == 'updateReminder'" (click)="CreateReminder()">
    {{'Update'|translate}}</button>       
 </div>
  </div>
</mat-dialog-content>
