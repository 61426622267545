import { Component, Inject, OnInit } from '@angular/core';
import { GoogleServicesService } from 'src/app/core/services/SYNC/google-services.service';
import { MicrosoftServicesService } from 'src/app/core/services/SYNC/microsoft-services.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupCommonComponent } from 'src/app/pages/commonForAll/popup-common/popup-common.component';
import { GoogleApiService } from '../../services/SYNC/google-api.service';
@Component({
  selector: 'app-sync-options-list',
  templateUrl: './sync-options-list.component.html',
  styleUrls: ['./sync-options-list.component.scss']
})
export class SyncOptionsListComponent implements OnInit {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  spinner=false;
  screentype=''
  readonly Gmail = false
  readonly Drive = false
  readonly GoogleCalenders = false
  readonly OneDrive = false
  readonly OutLook = false
  readonly OutLookCalender = false
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<SyncOptionsListComponent>,
    private Google:GoogleServicesService,
    private dialog: MatDialog,
    private Microsoft: MicrosoftServicesService,
    private GoogleApiService:GoogleApiService,
  ) { }

  ngOnInit(): void {
    this.screentype=this.data.screentype
  }

  selectservice(type){
    this.SynchronisationComponent1(type)
  }

  



  SynchronisationComponent1(type) {
    this.dialog.open(SyncOptionsListComponent, {
      data:{screentype:type},
      autoFocus: false,
      disableClose: true,
    });
  }

  Sync(){
    if(this.screentype=='Googleservice'){
      if(this.Gmail&&!this.Drive&&!this.GoogleCalenders){
         const redirectUrlWithPrompt= this.Google.mailloginpage()
       this.checkmailsyncststus(redirectUrlWithPrompt)
      }else if(!this.Gmail&&this.Drive&&!this.GoogleCalenders){
         const redirectUrlWithPrompt= this.Google.driveloginpage()
       this.checkmailsyncststus(redirectUrlWithPrompt)
      }else if (!this.Gmail&&!this.Drive&&this.GoogleCalenders){
         const redirectUrlWithPrompt= this.Google.calenderloginpage()
       this.checkmailsyncststus(redirectUrlWithPrompt)
      }else if(this.Gmail&&this.Drive&&!this.GoogleCalenders){
         const redirectUrlWithPrompt= this.Google.mail_drive_loginpage()
       this.checkmailsyncststus(redirectUrlWithPrompt)
      }else if ( this.Gmail&&this.Drive&&this.GoogleCalenders){
         const redirectUrlWithPrompt= this.Google.mail_drive_Calendar_loginpage()
       this.checkmailsyncststus(redirectUrlWithPrompt)
      }else if (this.Gmail&&!this.Drive&&this.GoogleCalenders){
         const redirectUrlWithPrompt= this.Google.mail_Calendar_loginpage()
       this.checkmailsyncststus(redirectUrlWithPrompt)
      }
    }else if( this.screentype=='Mircosaftservice'){
      if(this.OutLook&&!this.Drive&&!this.GoogleCalenders){
        const redirectUrlWithPrompt=this.Microsoft.outlookloginpage()
       this.checkmailsyncststus(redirectUrlWithPrompt)
      }else if(!this.OutLook&&this.OneDrive&&!this.OutLookCalender){
        const redirectUrlWithPrompt=this.Microsoft.onedriveloginpage()
       this.checkmailsyncststus(redirectUrlWithPrompt)
      }else if (!this.OutLook&&!this.OneDrive&&this.OutLookCalender){
        const redirectUrlWithPrompt=this.Microsoft.Calendarloginpage()
       this.checkmailsyncststus(redirectUrlWithPrompt)
      }else if(this.OutLook&&this.OneDrive&&!this.OutLookCalender){
       const redirectUrlWithPrompt=this.Microsoft.outlook_Calendar_loginpage()
       this.checkmailsyncststus(redirectUrlWithPrompt)
      }else if ( this.OutLook&&this.OneDrive&&this.OutLookCalender){
      const redirectUrlWithPrompt=this.Microsoft.outlook_onedrive_Calendar_loginpage()
      this.checkmailsyncststus(redirectUrlWithPrompt)

      }else if (this.OutLook&&!this.OneDrive&&this.OutLookCalender){
       const redirectUrlWithPrompt=this.Microsoft.outlook_onedrive_loginpage()
       this.checkmailsyncststus(redirectUrlWithPrompt)
      }
    }

  }


  checkmailsyncststus(redirectUrl:string){
    const checkmailsyncststus = this.dialog.open(PopupCommonComponent, {
      data: {screenType:'checkmailsyncststus',type:this.screentype },
      disableClose: true,
      width: '500px',
    });
    checkmailsyncststus.afterClosed().subscribe((result) => {
      console.log(result)
      const loginHint = encodeURIComponent(result)
      const URL=redirectUrl+`&login_hint=${loginHint}`
      const data={
        "email": result,
        "isGmail": this.screentype =='Googleservice'?true:false,
        "redirectUrl": URL
      }
      this.GoogleApiService.CreatePermissionRequest(data).subscribe({next: (response:any) => {
            if (response) {
             if(response?.message=='Requested for get permission'){
              const checkmailsyncststus = this.dialog.open(PopupCommonComponent, {
                data: {screenType:'RequestedMailpermission',type:this.screentype },
                disableClose: true,
                width: '500px',
              });
              checkmailsyncststus.afterClosed().subscribe((result) => {
              })
             }else{
              window.location.href=URL
             }
            }
        },
        error: (error) => {
            console.error('Error:', error);
        }
    });
    
    })

  }




}
