<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">{{"Choose Task Creation"|translate}}</h2>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
  
  

<ng-container *ngIf="!Existingtask">
    <div class="container">
        <div class="row justify-content-start">
            <div class="col-12 p-2">
                <button class="fw-600" mat-menu-item (click)="radioChange('Task')">
                    <mat-icon style="color:green;" > assignment_turned_in</mat-icon>
                  Create New Task 
                </button>
                <button class="fw-600" mat-menu-item (click)="radioChange('Milestone')">
                    <img style="margin-right: 12px;"src="/assets/images/iconsForsvg/nearby_FILL0_wght400_GRAD0_opsz24.svg">
                  Create New Milestone 
                </button>
                <button class="fw-600" mat-menu-item (click)="radioChange('Existing')">
                  Select Existing Task (or) Milestone
                </button>
                <button class="fw-600" mat-menu-item (click)="radioChange('template')">
                    <mat-icon > receipt</mat-icon>
                    use template
                </button>
            </div>
        </div>
    </div>

</ng-container>
<ng-container *ngIf="Existingtask">

    <div class="container">
        <form [formGroup]="taskForm">
        <div class="row mt-2">
            <mat-form-field matInput appearance="fill" class="w-100">
                <mat-label>{{"Select Existing Tasks"|translate}} </mat-label>
                <mat-select multiple #select formControlName="taskLink">
                    <mat-option *ngFor="let tasks of listofTasks" [value]="tasks">
                        <mat-icon style="color:green;" *ngIf="tasks.isMilesStone==false" > assignment_turned_in</mat-icon>
                        <img *ngIf="tasks.isMilesStone" style="margin-right: 12px;" src="../../../../../assets/images/iconsForsvg/nearby_FILL0_wght400_GRAD0_opsz24.svg">
                        {{ tasks.title| titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
    <div class="row mt-2 mb-4 justify-content-center" style="margin-bottom: 10px !important;">
        <div class="col-4">
            <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button class="b-blue" (click)="onSubmit()">{{"Apply"|translate}}</button>
        </div>
    </div>
    </div>

</ng-container>
