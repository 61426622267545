<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <mat-icon style="position: relative;right: 10px;"> person</mat-icon>
        {{'Update User Group'|translate}}
    </h2>
    <mat-icon matDialogClose>close</mat-icon>
</div>
<form [formGroup]="editUserForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content class="mr-0 ml-0">

        <div class="row w-100">
            <mat-form-field class="w-100">
                <mat-label>{{'User Group Name'|translate}}</mat-label>
                <input matInput type="text"  formControlName="userGroupName">
                <mat-error *ngIf="editUserForm.controls['userGroupName'].hasError('required')">
                    {{'User group'|translate}} name is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row w-100">
            <mat-form-field class="w-100">
                <mat-label>{{'Description'|translate}}</mat-label>
                <input matInput type="text"  formControlName="description">
            </mat-form-field>
        </div>

        <div class="row ">
            <mat-form-field class="example-chip-list" style="width: 100% !important;">
                <mat-label>{{'keyword'|translate}}</mat-label><br>
                <mat-chip-list #chipGridforkeyword aria-label="Fruit selection" class="keywordlist">
                    <mat-chip-row class="keywordrow" *ngFor="let keyword of keywordlist">
                        <button class="keywordbutton">
                            <span class="keywordpostion">{{keyword|titlecase}}</span>
                            <mat-icon class="keywordcancelicon" (click)="remove(keyword)">cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                </mat-chip-list>
                <input  [matChipInputFor]="chipGridforkeyword"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)" />
            </mat-form-field>
        </div>


        <div class="row w-100">
            <mat-form-field class="w-100">
                <mat-label> {{'Users'|translate}}</mat-label>
                <mat-select multiple formControlName="userId">
                    <mat-option *ngFor="let user of users$ | async" [value]="user.id" (click)="onChange(user)">
                        {{ user.firstName + ' '+user.lastName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-list>
            <div mat-subheader>{{'Users'|translate}}</div>
            <div *ngFor="let user of users">
                <mat-list-item>
                    <div class="w-100 d-flex flex-row justify-content-between">
                        <span class="d-flex flex-row align-items-center">
                            <div class="avatar">
                                <!-- <img src="assets/avatar.svg" /> -->
                                <img class="avatar" [src]="user?.profile ? user.profile:'assets/avatar.svg'" />
                            </div>
                            <span>{{user.firstName}}</span>
                        </span>
                        <button mat-icon-button (click)="removeUser(user)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
            </div>
        </mat-list>
    </mat-dialog-content>

    <mat-dialog-actions align="end" class="mb-1 pr-5">
        <button mat-raised-button mat-dialog-close>{{'Cancel'|translate}}</button>
        <button mat-raised-button [disabled]="!editUserForm.valid" class="b-blue">{{'Update'|translate}}</button>
    </mat-dialog-actions>

</form>