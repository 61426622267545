<div class="d-flex flex-row align-items-center modal-header">
  <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
    {{'Time Track'|translate}}
  </h2>
  <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
  <mat-tab-group mat-align-tabs="center" class="no-bg" [(selectedIndex)]="tabnumber"
    (selectedTabChange)="tabChange($event.index)">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tabclas">
          <span>{{'Tracking Mode'|translate}}</span>
        </div>
      </ng-template>
      <div class="mt-5">
        <form [formGroup]="timeTrackForm">
          <h1>Choose Tracking Method</h1>
          <mat-radio-group formControlName="timeOption" (change)="onOptionChange($event.value)">
            <div class="radio-option">
              <mat-radio-button value="1">Clock In – Clock Out</mat-radio-button>
            </div>
            <div class="radio-option">
              <mat-radio-button value="2">Start Time – End Time</mat-radio-button>
            </div>
            <div class="radio-option">
              <mat-radio-button value="3">No of Hrs (for a day)</mat-radio-button>
            </div>
          </mat-radio-group>
        </form>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tabclas">
          <span>{{'Tracking Options'|translate}}</span>
        </div>
      </ng-template>
      <div class="mt-5">
        <form [formGroup]="trackOptionsForm">
          <div class="row align-items-center mb-3">
            <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>
                  {{ "Project Name" | translate }}
                </mat-label>
              
                <mat-select>
                  <mat-option *ngFor="let x of Projects?.data" [disabled]="true">
                    <div class="project-item">
                      <span
                        [ngStyle]="{'color': x.fontColor, 'background': x.color}"
                        style="padding: 0px 6px; border-radius: 12px;display: inline-block;"
                      >
                        {{ x?.projectName }}
                      </span>
                      <button mat-icon-button [matMenuTriggerFor]="projectMenu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #projectMenu="matMenu">
                        <button mat-menu-item (click)="AddProject(x, 'Edit')">
                          <mat-icon>edit</mat-icon> Edit</button>
                        <button mat-menu-item (click)="deleteProject(x)">
                          <mat-icon>delete</mat-icon>Delete</button>
                      </mat-menu>
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center" style="margin-top:-15px">
             <img (click)="AddProject('', 'Create')" src="../../../../assets/images/iconsForsvg/add_circle.svg">
            </div>
          </div>
          <div class="row align-items-center mb-3" *ngIf="timeTrackForm.get('timeOption').value == '1'">
            <div class="col-12">
              <mat-slide-toggle
                formControlName="eventMatching"
                (change)="onToggleChange($event, 'EventMatching')"
                class="event-matching-toggle"
              >
                {{ "Event Matching" }}
              </mat-slide-toggle>
            </div>
          </div>
          <mat-list>
            <div class="d-flex justify-content-between">
              <h4 style="color: grey;margin-left: 10px;">
                <img src=".../../../../assets/images/iconsForsvg/remainders_Add.svg" alt="" style="margin-right: 3px;">
                <b>{{"Reminders"|translate}}</b>
              </h4>
              <mat-chip-list>
                <mat-chip (click)="showreminderspopup()">{{"Add"|translate}}</mat-chip>
              </mat-chip-list>
            </div>
          </mat-list>
          <ng-container>
            <div class="row mt-2 mb-3" *ngFor="let x of ReminderListDummy;let i = index">
              <span style="margin-left: 7px;">
                <span *ngIf="x.triggertype=='Email' || x.reminderTypeName == 'Email'" style="padding: 5px;"><mat-icon>
                    email</mat-icon></span>
                <span *ngIf="x.triggertype=='Notification' || x.reminderTypeName == 'Notification'"
                  style="padding: 5px;"><mat-icon>campaign</mat-icon></span>
                <span>{{x.value}} {{x.durationtype || x.periodName}} {{x.triggerMoment || x.isAfter ? 'After':
                  'Before'}} the Event {{x.Parameter || x.conditionParameter}} date and time</span>

                <span style="margin-left: 5px;cursor: pointer;"
                  (click)="removereminderDummy(i)"><mat-icon>delete</mat-icon></span>
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="getreminderststus()">
            <div class="row mt-2 mb-3" *ngFor="let x of ReminderList;let i = index">
              <span style="margin-left: 7px;">
                <span *ngIf="x.triggertype=='Email' || x.reminderTypeName == 'Email'" style="padding: 5px;"><mat-icon>
                    email</mat-icon></span>
                <span *ngIf="x.triggertype=='Notification' || x.reminderTypeName == 'Notification'"
                  style="padding: 5px;"><mat-icon>campaign</mat-icon></span>
                <span>{{x.value}} {{x.durationtype || x.periodName}} {{x.triggerMoment || x.isAfter ? 'After':
                  'Before'}} the Event {{x.Parameter || x.conditionParameter}} date and time</span>

                <span style="margin-left: 5px;cursor: pointer;"
                  (click)="removereminder(i)"><mat-icon>delete</mat-icon></span>
              </span>
            </div>
          </ng-container>
          <div class="row mb-3">
            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <mat-label>
                {{ "Settings Approval" | translate }}
              </mat-label>
            </div>
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <mat-slide-toggle formControlName="toggleOption" class="toggle-button">
              </mat-slide-toggle>
            </div>
          </div>

          <div class="row mt-2 mb-3" *ngIf="trackOptionsForm.get('toggleOption').value == true">
            <mat-radio-group formControlName="selectdatetype" (change)="getdateStatus($event.value)">
              <mat-radio-button value="2">
                {{'Daily'|translate}}</mat-radio-button>
              <mat-radio-button value="1">
                {{'Weekly'|translate}}</mat-radio-button>
              <mat-radio-button value="3">
                {{'Monthly'|translate}}</mat-radio-button>
              <mat-radio-button value="4">
                {{'Custom Days'|translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="row mt-2" *ngIf="trackOptionsForm.get('selectdatetype').value == '4'">
            <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
              <mat-form-field class="w-100">
                <mat-label>{{"Custom Days"}}</mat-label>
                <input matInput type="number" formControlName="customDays">
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
      <div class="col-12 mb-3" style="text-align:end">
        <button mat-raised-button [ngClass]="!trackOptionsForm.valid? 'g-blue':'b-blue'"
          [disabled]="!trackOptionsForm.valid" (click)="NextMethod2()">{{"Add"|translate}}</button>
      </div>
    </mat-tab>
    <mat-tab *ngIf="(AdminStatus || ManagerStatus)">
      <ng-template mat-tab-label>
        <div class="tabclas">
          <span>{{'Approvals'|translate}}</span>
        </div>
      </ng-template>
      <div class="card mt-2">
        <form [formGroup]="ManagerApproval">
          <div class="row mt-3" style="align-items: center;">
            <div class="col-4">
              <button mat-button class="approve-button p-1" (click)="approveAllRequests()">All Pending Request Approval</button>
            </div>
            <div class="col-3">
            </div>
            <div class="col-3">
              <mat-form-field appearance="fill" class="w-100">
                <mat-date-range-input [rangePicker]="dateRangePicker">
                  <input matStartDate placeholder="Start date" formControlName="startDate">
                  <input matEndDate placeholder="End date" formControlName="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="col-2" style="margin-top:-15px">
              <button  mat-button class="approve-button mx-2" (click)="showResult()">Show</button>
            </div>
          </div>
        </form>
          <!-- Scrollable Table Container -->
          <div class="table-container">
            <table *ngIf="transformedData && transformedData.length > 0" style="width:-webkit-fill-available">
              <thead>
                <tr style="background-color: rgb(220, 243, 251);">
                  <th>
                    <input type="text" class="search-input" placeholder="Search..." />
                  </th>
                  <!-- Display each unique date as a column header -->
                  <ng-container *ngFor="let date of uniqueDates">
                    <th class="align_item">
                      <form [formGroup]="AllDateApproveForm">
                        <mat-checkbox (click)="approveDateWise(date.requestIds, 'ApproveDateWise')" formControlName="ApproveAllDate" class="event-matching-checkbox"
                          style="margin-left: -10px; margin-right: 10px;"></mat-checkbox>
                        {{ formatMonthDate(date.fullDayName) }}
                      </form>
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <!-- Row 1: Pending Approvals for each date -->
                <tr style="background-color: rgb(221, 251, 221);">
                  <td class="align_item">
                    <mat-label>{{ "Needed Approvals" | translate }}</mat-label>
                  </td>
                  <ng-container *ngFor="let date of uniqueDates">
                    <td class="align_item">
                      <div class="card" style="color: gray; width: 20px; height: 20px; text-align: center; margin: auto;">
                        {{ date.pendingApprovalCount }}
                      </div>
                    </td>
                  </ng-container>
                </tr>
            
                <!-- Row 2: Total Duration for each date -->
                <tr style="background-color: rgb(255, 207, 118);">
                  <td></td>
                  <ng-container *ngFor="let date of uniqueDates">
                    <td class="align_item">
                      <div class="row align_item">
                        <div class="card" [ngStyle]="{'background-color': 'rgb(254, 103, 103)', 'width': '80px', 'height': '25px', 'padding': '3px', 'margin': 'auto'}">
                          {{ FormatTotalDuration(date.totalDuration) }}
                        </div>
                      </div>
                    </td>
                  </ng-container>
                </tr>
            
                <!-- Dynamic User Rows -->
                <ng-container *ngFor="let user of transformedData; let i = index">
                  <tr>
                    <!-- User Details Column -->
                    <td>
                      <div class="row" style="align-items: center;">
                        <img *ngIf="user.User?.creatorProfile" [src]="user.User.creatorProfile" alt=""
                          style="width: 30px; height: 30px; border-radius: 50%; margin-right: 8px;">
                        <span *ngIf="!user.User.creatorProfile" [style.background]="getColor(i)"
                          style="color: white; padding: 10px; border-radius: 50%; margin: 5px; width: 40px; height: 40px;">
                          {{ getuserFLnames(user.User.creatorFirstName + ' ' + user.User.creatorLastName) }}
                        </span>
                        <div class="d-flex flex-column">
                          <span style="font-weight: bold;">{{ user.User.creatorFirstName }} {{ user.User.creatorLastName }}</span>
                          <span style="font-size: 12px; color: gray;">{{ user.User.creatorPosition }}</span>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="d-flex flex-row">
                          <span class="card" style="background-color: rgb(254, 103, 103); width: 80px; height: 25px; text-align: center;">
                            {{ FormatTotalDuration(user.User.totalDuration) }}
                          </span>
                          <span class="card mx-2" style="background-color: rgb(146, 146, 146); color: white; width: 40px; height: 25px; text-align: center;">
                            {{ user.User.pendingApprovalCount }}
                          </span>
                        </div>
                      </div>
                    </td>
            
                    <!-- Date Entries for Each User -->
                    <ng-container *ngIf="uniqueDates && uniqueDates.length > 0">
                      <ng-container *ngFor="let date of uniqueDates">
                        <td>
                          <div class="card" style="background-color: #f5fdff; text-align: center; border-radius: 5px;">
                            <ng-container *ngIf="user?.User?.creatorId" >
                              <!-- Retrieve the entries for the user and date as an array -->
                              <ng-container  *ngIf="getDateEntry(user.User.creatorId, date.date) as dateEntries">
                                <ng-container *ngFor="let dateEntry of dateEntries">
                                  <div class="row d-flex flex-column" style="padding: 5px">
                                    <form [formGroup]="SingleApproveForm">
                                      <span style="font-weight: bold;">
                                        <mat-checkbox
                                          (click)="approveRequests(dateEntry.timeEntryId, 'singleApprove')"
                                          formControlName="ApproveSingle"
                                          class="event-matching-checkbox"
                                          style="margin-left: -10px; margin-right: 10px;">
                                        </mat-checkbox>
                                        {{ dateEntry.startIn12Hours }} - {{ dateEntry.endIn12Hours }}
                                      </span>
                                    </form>
                                    <span>{{ FormatTotalDuration(dateEntry.duration) }}</span>
                                    <span
                                      class="mb-2"
                                      [ngStyle]="{'background-color': dateEntry.projectColor, 'color': dateEntry.projectFontColor}"
                                      style="padding: 5px 40px; border-radius: 3px; margin: 8px;">
                                      {{ dateEntry.projectName }}
                                    </span>
                                  </div>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                          </div>
                        </td>
                      </ng-container>
                    </ng-container>
                    
                    
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div *ngIf="transformedData && transformedData.length == 0" style="text-align:center;margin-top: 50px;font-weight:bold">
              <h2>No Pending Approvals for this Date Range</h2>
            </div>
            
            
            
            
          </div>
      
        
      </div>
      
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>