<div class="User-details">
    <div class="d-flex flex-row align-items-center modal-header">
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
            {{'Category Details'}}
        </h2>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <mat-dialog-content  class="mr-0 ml-0">

        <div class="row mt-2 mb-4 justify-content-center">
            <div class="col-4 justify-content-center">
                <button style="border: none;" [ngStyle]="{ 'background-color': Categorydetails?.bgColorCode, 'color': Categorydetails?.textColorCode, 'border-radius': '10px','text-align': 'center','width': '150px' }">
                    {{Categorydetails?.name}}
                </button>
            </div>
        </div>

        <div class="row mt-4 mb-4 justify-content-center">
            <div class="col-10">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Condition</th>
                        <th scope="col">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let x of Categorydetails?.conditions">
                        <td>{{x?.fieldName}}</td>
                        <td>{{x?.condition}}</td>
                        <td>{{x?.value}}</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
        </div>


    </mat-dialog-content>
</div>