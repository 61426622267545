<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title *ngIf="data.type != 'ViewUsers'"
      class="m-0 dialog-title text-center w-100">
      {{'Select Value'}}
    </h2>
    <h2 mat-dialog-title *ngIf="data.type == 'ViewUsers'"
      class="m-0 dialog-title text-center w-100">
      {{'List Of Users'}}
    </h2>
    <mat-icon class="pointer" mat-dialog-close>close</mat-icon>
  </div>
<mat-dialog-content class="mr-0 ml-0" *ngIf="data.type == 'ViewUsers'">
  <div class="row  mt-3 mb-3 fw-600 color_grey" *ngFor="let user of getuser ; let i = index">
    <span class="m-l-28">
      <img *ngIf="user?.profilePicture != null" style="border-radius: 50%; cursor: pointer;" width="30" height="30" (click)="ShowUserDEtails(user, 'user')"
        [src]="user.profilePicture">
      <span *ngIf="user?.profilePicture == null && user.firstName != null" [style.background]="getColor(i)"
        style="color: white; padding: 8px;border-radius: 50%;position: relative;top: 0px;font-size:13px">{{getuserFLnames(user.firstName
        + ' ' +user.lastName)}}
      </span>
    </span>
    <span class="p-l-10 mt-1 fw-600 color_grey" style="cursor: pointer;" (click)="ShowUserDEtails(user, 'user')">
      {{user.firstName|titlecase}} {{user.lastName|titlecase}}</span>
  </div>
</mat-dialog-content>

<mat-dialog-content class="mr-0 ml-0" *ngIf="data.type != 'ViewUsers'">
  <div class="row w-100">
      <div class="col-12" *ngFor="let item of ColoumnList">
        <mat-card class="mt-2" style="background: lightgray;padding: 10px;width: fit-content;cursor:pointer" (click)="selectItem(item.columnName)">
          <mat-card-content>
            <p style="color: black;margin:-3px">{{ EntityName === 'Task' ? 'TaskTable.' + item.columnName : EntityName + '.' + item.columnName }}</p>
          </mat-card-content>
        </mat-card>
      </div>
  </div>

</mat-dialog-content>
