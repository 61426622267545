<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <mat-icon style="position: relative;right: 10px;"> person</mat-icon>
        {{'Create User Group'|translate}}
    </h2>
    <mat-icon matDialogClose>close</mat-icon>
</div>

<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row w-100">
            <mat-form-field class="w-100">
                <mat-label>{{'User Group Name'|translate}}</mat-label>
                <input matInput type="text" formControlName="userGroupName">
                <mat-error *ngIf="form.controls['userGroupName'].hasError('required')">
                    {{'User group'|translate}} name is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row w-100">
            <mat-form-field class="w-100">
                <mat-label>{{'Description'|translate}}</mat-label>
                <input matInput type="text" placeholder="Description" formControlName="description">
            </mat-form-field>
        </div>
        <div class="row w-100">
            <mat-form-field class="example-chip-list" style="width: 100% !important;">
                <mat-label>{{'keyword'|translate}}</mat-label><br>
                <mat-chip-list #chipGridforkeyword aria-label="Fruit selection" class="keywordlist">
                    <mat-chip-row class="keywordrow" *ngFor="let keyword of keywordlist">
                        <button class="keywordbutton">
                            <span class="keywordpostion">{{keyword|titlecase}}</span>
                            <mat-icon class="keywordcancelicon" (click)="remove(keyword)">cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                </mat-chip-list>
                <input  [matChipInputFor]="chipGridforkeyword"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)" />
            </mat-form-field>
        </div>
        <div class="row w-100">
            <mat-form-field class="w-100">
                <mat-label>{{'Select Users'|translate}}</mat-label><br>
                <input type="text"  matInput
                    [matAutocomplete]="auto" [formControl]="userControl">
            </mat-form-field>
        </div>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" formControlName="userId">
            <mat-option *ngFor="let user of fliteredUsers | async" [value]="user.id">
                <div (click)="optionClicked($event, user)">
                    <mat-checkbox [checked]="user.selected" (change)="toggleSelection(user)"
                        (click)="$event.stopPropagation()">
                        <div class="avatar">
                            <img class="avatar"
                                [src]="user?. profilePicture ? user. profilePicture:'assets/avatar.svg'"> {{
                            user.firstName }}
                        </div>
                    </mat-checkbox>
                </div>
            </mat-option>
        </mat-autocomplete>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="mb-1 pr-5">
    <button mat-raised-button mat-dialog-close  class="W-blue" >{{'Cancel'|translate}}</button>
    <button mat-raised-button type="submit" class="b-blue" (click)="onSubmit()">{{'Create'|translate}}</button>
</mat-dialog-actions>