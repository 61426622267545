export const environment:any = {
  production: true,
  // google_api_Key 
  google_api_Key: 'AIzaSyC6aP1kQgXDXbroJouE110syf0W9wVjIUc',
  // DeveloperKey 
  DeveloperKey: 'AIzaSyD1azakHWpC9tK_HkDHz_LyMUx0Bpn0AQE',
  // googleclientid
  googleclientid: '710649339803-02mvprlfprm4ic70e1oj0h2slt28bntu.apps.googleusercontent.com',
  // mircosoftclintId 
  mircosoftclintId:'22ed7c20-a74b-4901-a8fa-f209a2c4dec4',
  // publicApiKey 
  publicApiKey: '5bf52a16rO0LXIYObzblkMiYfD5HRD96c8cafdwjiVvC2UuU9hyVUmz',
  // onSeenUrl 
  onSeenUrl: 'http://onssendata.eu-west-3.elasticbeanstalk.com',


  // live only 00000000
  baseUrl: 'https://swagger.planningandme.com',
  stripe_key:'pk_live_51NyF84FjZcgrwBggpfS4cSh2BJ8Mxb41ZleBFIW9yXjGGztoKrNNAn0iA3DOmzyivSueDUvzLjBPEjypJK6NiD3t00c5uCaBp8',
  gooleredirectUri:'https://planningandme.com/GoogleAuth',
  MicrosoftredirectUri:'https://planningandme.com/MicrosoftAuth',

  // test only 11111111
  // baseUrl:'https://demo.swagger.planningandme.com',
  // stripe_key: 'pk_test_NvHfZByEOwQXHrlPIJsASUVt00vfYBRGrW',
  // gooleredirectUri: 'http://planningandme.test.s3-website.ap-south-1.amazonaws.com/GoogleAuth',
  // MicrosoftredirectUri: 'http://planningandme.test.s3-website.ap-south-1.amazonaws.com/MicrosoftAuth',

  // local test 222222222
  // baseUrl:'https://demo.swagger.planningandme.com',
  // stripe_key: 'pk_test_NvHfZByEOwQXHrlPIJsASUVt00vfYBRGrW',
  // gooleredirectUri: 'http://localhost:4200/GoogleAuth',
  // MicrosoftredirectUri: 'http://localhost:4200/MicrosoftAuth',


};
