<!-- search box  -->
<div class="d-flex justify-content-between align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <mat-icon style="position: relative;right: 10px;">
            search</mat-icon>{{'Advance Search'|translate}}
    </h2>
    <mat-icon class="pointer" (click)="closedialogbox()">close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="AdvanceSearchForm">
        <div class="row mt-3 p-0">
            <div class="col-12 col-sm-12  col-md-12 col-lg-4 col-xl-4 ">
                <mat-checkbox class="example-margin" formControlName="Entity">
                    {{EntityType}}</mat-checkbox>
            </div>
            <div class="col-12 col-sm-12  col-md-12 col-lg-4 col-xl-4">
                <mat-checkbox class="example-margin" formControlName="IsGroup">
                  {{EntityType}} Groups
                </mat-checkbox>
            </div>
        </div><hr>
        <div class="row w-100">
            <div class="col-12 col-sm-12  col-md-12 col-lg-3 col-xl-3 ">
                <mat-checkbox class="example-margin"
                    formControlName="IsPublic"><img style="margin-bottom:7px;"
                        src="../../../../../assets/images/iconsForsvg/Unlock.svg"
                        alt>
                    {{'Public'|translate}}</mat-checkbox>
            </div>
            <div class="col-12 col-sm-12  col-md-12 col-lg-3 col-xl-3">
                <mat-checkbox class="example-margin"
                    formControlName="IsPrivate">
                    <mat-icon
                        style="font-size: 18px;">lock</mat-icon>{{'Private'|translate}}</mat-checkbox>
            </div>
            <div class="col-12 col-sm-12  col-md-12 col-lg-3 col-xl-3">
                <mat-checkbox class="example-margin"
                    formControlName="IsDelegate"><mat-icon
                        style="font-size: 18px;">device_hub</mat-icon>{{'Deligation'|translate}}</mat-checkbox>
            </div>
        </div><hr>
        <div class="row mt-2 mb-2">
            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                <mat-checkbox class="example-margin"
                    formControlName="IsAchieved">{{'Target Achieved'|translate}}</mat-checkbox>
            </div>
            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                <mat-checkbox class="example-margin"
                    formControlName="IsNotAchieved">
                    {{'Target Not Achieved'|translate}}</mat-checkbox>
            </div>
        </div> 
        <!-- <div class="row mt-3">
            <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{'Creator Name'|translate}}</mat-label>
                    <mat-select formControlName="CreatorIds">
                        <mat-select-filter [placeholder]="'Filter'"
                            [displayMember]="'firstName'"
                            [array]="targetOfCreatorNameList"
                            (filteredReturn)="targetOfCreatorNameList=$event"></mat-select-filter>
                        <mat-option [value]="creater.id"
                            *ngFor=" let creater of targetOfCreatorNameList">
                            <img style="border-radius: 50%; margin-right: 6px;"
                                width="30" height="30"
                                [src]="creater?.profilePicture ? creater.profilePicture:'assets/avatar.svg'"
                                onError="this.src='assets/avatar.svg'">
                            {{creater.firstName+' '+creater.lastName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div> -->
        <div class="row firstAndLastName">
            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Target Type</mat-label>
                    <mat-select  #select formControlName="TargetType">
                      <mat-option  [value]="1">Orders</mat-option>
                      <mat-option  [value]="2">Events</mat-option>
                      <mat-option  [value]="3">Tasks</mat-option>
                      <mat-option  [value]="4">Initiatives</mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Status Of Transaction"|translate}}</mat-label>
                    <mat-select formControlName="StatusOfTransaction">
                        <mat-option
                            value="0">{{"Pending"|translate}}</mat-option>
                        <mat-option
                            value="1">{{"Validated"|translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
       
        <!-- <div class="row w-100">
            <div class="col-12">
                <mat-form-field class="example-chip-list"
                    style="width: 100% !important;">
                    <mat-label>{{"keyword"|translate}}</mat-label><br>
                    <mat-chip-list #chipGridforkeyword
                        aria-label="Fruit selection" class="keywordlist">
                        <mat-chip-row class="keywordrow"
                            *ngFor="let keyword of keywordlist">
                            <button class="keywordbutton">
                                <span
                                    class="keywordpostion">{{keyword|titlecase}}</span>
                                <mat-icon class="keywordcancelicon"
                                    (click)="remove(keyword)">cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-list>
                    <mat-select multiple>
                        <mat-option (click)="Selectedkeywprd(x)"
                            *ngFor="let x of KeywordsFromOrder">{{x}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div> -->

        <div class="row firstAndLastName">
            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Start Date"|translate}}</mat-label>
                    <input matInput [matDatepicker]="startdatepicker1" style="color: black;"
                        formControlName="StartDate">
                    <mat-datepicker-toggle matSuffix [for]="startdatepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #startdatepicker1></mat-datepicker>
                    <mat-error *ngIf="AdvanceSearchForm.controls['StartDate'].hasError('required')">
                        {{"Start Date"|translate}} is <strong>{{"required"|translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"End Date"|translate}}</mat-label>
                    <input matInput [matDatepicker]="enddatepicker1" style="color: black;"
                        formControlName="EndDate">
                    <mat-datepicker-toggle matSuffix [for]="enddatepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #enddatepicker1></mat-datepicker>
                    <mat-error *ngIf="AdvanceSearchForm.controls['EndDate'].hasError('required')">
                        {{"End Date"|translate}} is <strong>{{"required"|translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </div> 
        </div>
        <div class="row w-100">
            <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Value Type"|translate}}</mat-label>
                    <mat-select formControlName="ValueType">
                        <mat-option
                            value="1">{{"Amount"|translate}}</mat-option>
                        <mat-option
                            value="2">{{"Count"|translate}}</mat-option>
                        <mat-option
                            value="3">{{"Duration"|translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{'Condition'|translate}}</mat-label>
                    <mat-select formControlName="Condition">
                        <mat-option *ngFor="let value of Condition"
                            [value]="value.v2">
                            {{value.v2|translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="w-100" appearance="fill">
                    <input matInput type="text" placeholder="Value"
                        formControlName="Value">
                </mat-form-field>
            </div>
           
        </div>
        <div class="row mt-2" *ngIf="AdvanceSearchForm.get('ValueType').value === '3'">
            <div class="col-12">
              <mat-form-field class="w-100" appearance="fill">
                <input matInput type="text" placeholder="Duration" formControlName="Duration">
              </mat-form-field>
            </div>
          </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="mb-1 pr-5">
    <div class="row mt-2 w-100 justify-content-center">
        <div class="col-4">
            <button mat-raised-button mat-dialog-close
                (click)="closedialogbox()">{{'Cancel'|translate}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button class="b-blue"
                (click)="AdvanceSearch()">{{'Apply'|translate}}</button>
        </div>
    </div>
</mat-dialog-actions>
