import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
const baseUrl = environment.baseUrl;


@Injectable({
  providedIn: 'root'
})
export class GoogleServicesService {
  // google 
  clientId=environment.googleclientid
  maillscope='https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.settings.basic https://www.googleapis.com/auth/gmail.settings.sharing'
  drivescope='https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
  calenderscope='https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
  constructor(
  ) { }

  mailloginpage() {
    const scope=this.maillscope
    const redirectUrlWithPrompt = `https://accounts.google.com/o/oauth2/auth?access_type=offline&scope=${scope}&response_type=code&redirect_uri=${encodeURIComponent(environment.gooleredirectUri)}&client_id=${this.clientId}&prompt=consent`
    return redirectUrlWithPrompt
  }
  driveloginpage() {
    const scope=this.drivescope
    const redirectUrlWithPrompt = `https://accounts.google.com/o/oauth2/auth?access_type=offline&scope=${scope}&response_type=code&redirect_uri=${encodeURIComponent(environment.gooleredirectUri)}&client_id=${this.clientId}&prompt=consent`
    return redirectUrlWithPrompt
  }
  calenderloginpage() {
    const scope=this.calenderscope
    const redirectUrlWithPrompt = `https://accounts.google.com/o/oauth2/auth?access_type=offline&scope=${scope}&response_type=code&redirect_uri=${encodeURIComponent(environment.gooleredirectUri)}&client_id=${this.clientId}&prompt=consent`
    return redirectUrlWithPrompt
  }

  mail_Calendar_loginpage(){
    
    const scope =this.maillscope+' '+this.calenderscope
    const redirectUrlWithPrompt = `https://accounts.google.com/o/oauth2/auth?access_type=offline&scope=${scope}&response_type=code&redirect_uri=${encodeURIComponent(environment.gooleredirectUri)}&client_id=${this.clientId}&prompt=consent`
    return redirectUrlWithPrompt
  }
  mail_drive_loginpage(){
    const scope =this.maillscope+' '+this.drivescope
     const redirectUrlWithPrompt = `https://accounts.google.com/o/oauth2/auth?access_type=offline&scope=${scope}&response_type=code&redirect_uri=${encodeURIComponent(environment.gooleredirectUri)}&client_id=${this.clientId}&prompt=consent`
    return redirectUrlWithPrompt
  }
  mail_drive_Calendar_loginpage(){
    const scope =this.maillscope+' '+this.drivescope+' '+this.calenderscope
     const redirectUrlWithPrompt = `https://accounts.google.com/o/oauth2/auth?access_type=offline&scope=${scope}&response_type=code&redirect_uri=${encodeURIComponent(environment.gooleredirectUri)}&client_id=${this.clientId}&prompt=consent`
    return redirectUrlWithPrompt
  }
}
