<div class="d-flex flex-row align-items-center modal-header">
    <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">{{"Search"|translate}}</h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <ng-container *ngIf="screenType=='taskScreen' || this.screenType=='taskKanbanScreen'">
        <form [formGroup]="taskForm">
            <!-- Title  -->
            <div class="row mt-2">
                <mat-form-field class="w-100">
                    <mat-label>{{"Title"|translate}}</mat-label>
                    <input matInput type="text" id="Title" formControlName="Title">
                </mat-form-field>
            </div>
            <!-- Start Date & End Date   -->
            <div class="row firstAndLastName">
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Begin Date"|translate}}</mat-label>
                        <input matInput [matDatepicker]="startdatepicker1" style="color: black;"
                            formControlName="StartDate">
                        <mat-datepicker-toggle matSuffix [for]="startdatepicker1"></mat-datepicker-toggle>
                        <mat-datepicker #startdatepicker1></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Deadline Date"|translate}}</mat-label>
                        <input matInput [matDatepicker]="enddatepicker1" style="color: black;"
                            formControlName="EndDate">
                        <mat-datepicker-toggle matSuffix [for]="enddatepicker1"></mat-datepicker-toggle>
                        <mat-datepicker #enddatepicker1></mat-datepicker>
                    </mat-form-field>
                </div>̉
            </div>
            <div class="row mt-2" *ngIf="screenType == 'taskKanbanScreen'">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Step Name"|translate}}</mat-label>
                    <mat-select formControlName="StepIds" multiple>
                        <mat-option *ngFor="let x of stepName" [value]="x">{{x.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row firstAndLastName">
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Status </mat-label>
                        <mat-select formControlName="Status">
                            <mat-option value="1">Assigned</mat-option>
                            <mat-option value="2">Not Assigned</mat-option>
                            <mat-option value="3">In Progress</mat-option>
                            <mat-option value="4">Completed</mat-option>
                            <mat-option value="5">Canceled</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{'Condition'|translate}}</mat-label>
                        <mat-select formControlName="Condition">
                            <mat-option *ngFor="let value of Condition"
                                [value]="value.v2">
                                {{value.v2|translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row p-0 m-0">
                <p class="p-0 m-0"><b>Select Duration :</b></p>
            </div>
            <div class="row firstAndLastName">
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field class="w-100">
                        <mat-label>{{"Hours"|translate}} </mat-label>
                        <input matInput type="number" id="Hours" formControlName="Hours">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field class="w-100">
                        <mat-label>Minutes </mat-label>
                        <mat-select formControlName="Minutes">
                            <mat-option value="0">
                                00 Minutes</mat-option>
                            <mat-option value="15">
                                15 Minutes</mat-option>
                            <mat-option value="30">
                                30 Minutes</mat-option>
                            <mat-option value="45">
                                45 Minutes</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <!-- Private  -->
            <div class="row firstAndLastName mb-3" *ngIf="screenType != 'taskKanbanScreen'">
                <div class="col-12 col-sm-12  col-md-12 col-lg-4 col-xl-4">
                    <mat-list-item>
                        <mat-checkbox formControlName="IsPrivate">
                            <mat-icon class="lock" style="position: relative;top:3px; left: 3px;">lock</mat-icon>
                            {{"Private"|translate}}</mat-checkbox>
                    </mat-list-item>
                </div>
                <div class="col-12 col-sm-12  col-md-12 col-lg-4 col-xl-4">
                    <mat-list-item>
                        <mat-checkbox formControlName="IsPublic">
                            <img  src="../../../../assets/images/iconsForsvg/Unlock.svg">
                            {{"Public"|translate}}</mat-checkbox>
                    </mat-list-item>
                </div>
            </div>

            

                    <!-- -----------  -->
            <!-- Users  -->
            <div class="row firstAndLastName justify-content-end">
                <div class="col-12 p-0 m-0">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>{{"Assingnee"|translate}} </mat-label>
                            <mat-select multiple (openedChange)="filterOptions('','users')" #select formControlName="UsersId">
                                <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'users')"
                                    placeholder="Filter" />
                                <mat-option *ngFor="let user of filteredusers" [value]="user">
                                    <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                        [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                                        onError="this.src='assets/avatar.svg'">
                                    {{ user.firstName| titlecase}} {{user.lastName|titlecase}}
                                </mat-option>
                            </mat-select> 
                        </mat-form-field>
                </div>
            </div>

            <div class="row w-100" *ngIf="screenType != 'taskKanbanScreen'">
                <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12 p-0 m-0">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Contacts "|translate}} </mat-label>
                        <mat-select multiple (openedChange)="filterOptions('','contacts')" #select
                            formControlName="ContactsId">
                            <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'contact')"
                                placeholder="Filter" />
                            <mat-option *ngFor="let contact of filteredContacts" [value]="contact">
                                <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                    [src]="contact?.profilePicture ? contact.profilePicture:'assets/avatar.svg'"
                                    onError="this.src='assets/avatar.svg'">
                                {{ contact.firstName| titlecase}} {{contact.lastName|titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row w-100" *ngIf="screenType != 'taskKanbanScreen'">
                <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12 p-0 m-0">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Sites"|translate}} </mat-label>
                        <mat-select multiple (openedChange)="filterOptions('','sites')" #select formControlName="SitesId">
                            <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'site')"
                                placeholder="Filter" />
                            <mat-option *ngFor="let site of filteredSites" [value]="site">
                                <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                    [src]="site?.profilePicture ? site.profilePicture : 'assets/images/iconsForsvg/Site_Icon.svg'"
                                    onError="this.src='assets/images/iconsForsvg/Site_Icon.svg'">
                                {{ site.companyName| titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row w-100" *ngIf="screenType != 'taskKanbanScreen'">
                <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12 p-0 m-0">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Products"|translate}} </mat-label>
                        <mat-select multiple (openedChange)="filterOptions('','contacts')" #select
                            formControlName="ProductsId">
                            <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'contact')"
                                placeholder="Filter" />
                            <mat-option *ngFor="let product of filteredProducts" [value]="product">
                                <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                    [src]="product?.profilePicture ? product.profilePicture:'assets/images/iconsForsvg/icon_product.svg'"
                                    onError="this.src='assets/images/iconsForsvg/icon_product.svg'">
                                {{ product.productName| titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row firstAndLastName mb-3" *ngIf="screenType != 'taskKanbanScreen'">
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Category"|translate}}</mat-label>
                        <mat-select id="CategoryId" formControlName="CategoryIds" multiple>
                            <mat-option (click)="removecategory()">Select Category</mat-option>
                            <mat-option (click)="updatecategory(category)" [value]="category"
                                *ngFor="let category of categories ">{{category.categoryName|titlecase}}
                            </mat-option>
                            <mat-option value="OffTime">{{"Off Time"|translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6 p-0">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Critic Level"|translate}}</mat-label>
                        <mat-select formControlName="CriticLevelIds" multiple>
                            <mat-option [value]="criticity" *ngFor="let criticity of CriticityId$ | async">
                                {{criticity.criticityName|translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
            </div>
            
            <div class="row firstAndLastName mb-3">
                <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Keywords"|translate}}</mat-label>
                        <input matInput type="text" id="Keywords" formControlName="Keyword">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-2 mb-2" *ngIf="screenType != 'taskKanbanScreen'">
                <div class="col-6">
                    <mat-checkbox formControlName="IsPredecessortask" class="example-margin">
                        <img  src="../../../../../assets/images/iconsForsvg/left-arrow.svg">
                        {{'Predecessor'|translate}}</mat-checkbox>
                </div>
                <div class="col-6">
                    <mat-checkbox formControlName="IsSuccessortask" class="example-margin">
                        <img  src="../../../../../assets/images/iconsForsvg/right-arrow.svg">
                        {{'Successor'|translate}}
                    </mat-checkbox>
                </div>
              </div>
              <div class="row mt-2 mb-2" *ngIf="screenType != 'taskKanbanScreen'">
                                <div class="col-6">
                    <mat-checkbox formControlName="Isparent" class="example-margin">
                        <img  src="../../../../../assets/images/iconsForsvg/left-arrow.svg">
                        {{'Parent'|translate}}</mat-checkbox>
                </div>
                <div class="col-6">
                    <mat-checkbox formControlName="Ischild" class="example-margin">
                        <img  src="../../../../../assets/images/iconsForsvg/right-arrow.svg">
                        {{'Child'|translate}}
                    </mat-checkbox>
                </div>
              </div>

              <div *ngIf="screenType != 'taskKanbanScreen'" class="row p-2 mt-3 justify-content-around" style="color: white; background-color:#f6272e; font-weight: 600;margin-left: 15px !important;margin-right:15px !important">
                <div class="col-6">{{'Tags'|translate}} </div>
                <div class="col-2">
                    <mat-icon style="color: white;" (click)="Addcondition()">add_box</mat-icon>
                </div>
            </div>
            <div *ngIf="screenType != 'taskKanbanScreen'" formArrayName="tagConditions" style="margin-left: 15px;margin-right:15px;margin-bottom:10px;"> 
                <div style="border: 1px solid gray;padding:10px;" *ngFor="let file of tagConditions().controls; let i=index" [formGroupName]="i">
                  <div class="row p-2 justify-content-around"
                  style="color: white; background-color:#818582; font-weight: 600;">
                  <div class="col-6"> {{'Tag'|translate}}-{{i+1}}</div>
                  <div class="col-2">
                    <mat-icon *ngIf="i!=0" (click)="Removecondition(i)">delete</mat-icon>
                  </div>
              </div>
                  <div class="row strategycondictions">
                    <div class="col-4">
                      <!-- UsersValue -->
                      <ng-container>
                        <mat-form-field  class="w-100">
                            <mat-label>{{'Parameter'|translate}}</mat-label>
                            <mat-select formControlName="tagColumnId">
                                <mat-option [value]="x" *ngFor="let x of tags"
                                    (click)="selactdatatype(x,i)">
                                    {{x.fieldName|titlecase}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
          
                    </ng-container>
                    </div>
                    <div class="col-4">
                         <!-- AllOption  -->
                         <ng-container>
                         <mat-form-field  class="w-100">
                             <mat-label>{{'Comparison'|translate}}</mat-label>
                             <mat-select formControlName="condition">
                                 <mat-option [value]="x.v2" *ngFor="let x of AllOption">
                                     {{x.v2|translate}}
                                 </mat-option>
                             </mat-select>
                         </mat-form-field>
                     </ng-container>
                    </div>
                    <div class="col-4" >
                      <mat-form-field style="width: 90% !important;">
                        <input matInput type="tel" placeholder="value" formControlName="value">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
        </form>
    </ng-container>


    <ng-container *ngIf="screenType=='initiativescreen' || screenType == 'initiativeKanbanscreen'">
        <form [formGroup]="initiativeForm">
            <!-- Title  -->
            <div class="row mt-2">
                <mat-form-field class="w-100">
                    <mat-label>{{"Title"|translate}}</mat-label>
                    <input matInput type="text" id="Title" formControlName="Title">
                </mat-form-field>
            </div>
            <!-- Start Date & End Date   -->
            <div class="row firstAndLastName">
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Begin Date"|translate}}</mat-label>
                        <input matInput [matDatepicker]="startdatepicker1" style="color: black;"
                            formControlName="StartDate">
                        <mat-datepicker-toggle matSuffix [for]="startdatepicker1"></mat-datepicker-toggle>
                        <mat-datepicker #startdatepicker1></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Deadline Date"|translate}}</mat-label>
                        <input matInput [matDatepicker]="enddatepicker1" style="color: black;"
                            formControlName="EndDate">
                        <mat-datepicker-toggle matSuffix [for]="enddatepicker1"></mat-datepicker-toggle>
                        <mat-datepicker #enddatepicker1></mat-datepicker>
                    </mat-form-field>
                </div>̉
            </div>
            <div class="row mt-2" *ngIf="screenType == 'initiativeKanbanscreen'">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Step Name"|translate}}</mat-label>
                    <mat-select formControlName="StepIds" multiple>
                        <mat-option *ngFor="let x of stepName" value="{{x.id}}">{{x.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row mb-3" *ngIf="screenType != 'initiativeKanbanscreen'">
                <div class="col-12 col-sm-12  col-md-12 col-lg-4 col-xl-4">
                    <mat-list-item>
                        <mat-checkbox formControlName="IsPrivate">
                            <mat-icon class="lock" style="position: relative;top:3px; left: 3px;">lock</mat-icon>
                            {{"Private"|translate}}</mat-checkbox>
                    </mat-list-item>
                </div>
                <div class="col-12 col-sm-12  col-md-12 col-lg-4 col-xl-4">
                    <mat-list-item>
                        <mat-checkbox formControlName="IsPublic">
                            <img  src="../../../../assets/images/iconsForsvg/Unlock.svg">
                            {{"Public"|translate}}</mat-checkbox>
                    </mat-list-item>
                </div>
            </div>
            <div class="row firstAndLastName">
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{'Condition'|translate}}</mat-label>
                        <mat-select formControlName="Condition">
                            <mat-option *ngFor="let value of Condition"
                                [value]="value.v2">
                                {{value.v2|translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill"  class="w-100">
                        <mat-label>{{"Budget"|translate}}</mat-label>
                        <input matInput type="number" placeholder="Budget " formControlName="budget">
                    </mat-form-field>
                </div>
                
            </div>
            <div class="row firstAndLastName">
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{'Condition'|translate}}</mat-label>
                        <mat-select formControlName="ConditionForTotalBudget">
                            <mat-option *ngFor="let value of Condition"
                                [value]="value.v2">
                                {{value.v2|translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill"  class="w-100">
                        <mat-label>{{"Total Budget"|translate}}</mat-label>
                        <input matInput type="number" placeholder="Budget " formControlName="TotalBudget">
                    </mat-form-field>
                </div>
                
            </div>
            <div class="row firstAndLastName">
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill"  class="w-100">
                        <mat-label>Status </mat-label>
                        <mat-select formControlName="InitiativeStatus">
                            <mat-option value="Not Started">Not Started</mat-option>
                            <mat-option value="In Progress">In Progress</mat-option>
                            <mat-option value="Completed">Completed</mat-option>
                            <mat-option value="Late">Late</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Category"|translate}}</mat-label>
                        <mat-select id="CategoryId" formControlName="CategoryId">
                            <mat-option (click)="removecategory()">Select Category</mat-option>
                            <mat-option (click)="updatecategory(category)" [value]="category.id"
                                *ngFor="let category of categories ">{{category.categoryName|titlecase}}
                            </mat-option>
                            <mat-option value="OffTime">{{"Off Time"|translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
                <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Keywords"|translate}}</mat-label>
                        <input matInput type="text" id="Keywords" formControlName="Keyword">
                    </mat-form-field>
                </div>
            </div>
            <div class="row w-100">
                <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12 p-0 m-0">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Assingnee"|translate}} </mat-label>
                        <mat-select multiple (openedChange)="filterOptions('','users')" #select formControlName="LinkedUserIds">
                            <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'users')"
                                placeholder="Filter" />
                            <mat-option *ngFor="let user of filteredusers" [value]="user.id">
                                <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                    [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                                    onError="this.src='assets/avatar.svg'">
                                {{ user.firstName| titlecase}} {{user.lastName|titlecase}}
                            </mat-option>
                        </mat-select> 
                    </mat-form-field>
            </div>
            </div>
            
            <div class="row w-100" *ngIf="screenType != 'initiativeKanbanscreen'">
                <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12 p-0 m-0">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Contacts "|translate}} </mat-label>
                        <mat-select multiple (openedChange)="filterOptions('','contacts')" #select
                            formControlName="ContactsId">
                            <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'contact')"
                                placeholder="Filter" />
                            <mat-option *ngFor="let contact of filteredContacts" [value]="contact">
                                <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                    [src]="contact?.profilePicture ? contact.profilePicture:'assets/avatar.svg'"
                                    onError="this.src='assets/avatar.svg'">
                                {{ contact.firstName| titlecase}} {{contact.lastName|titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row w-100" *ngIf="screenType != 'initiativeKanbanscreen'">
                <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12 p-0 m-0">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Sites"|translate}} </mat-label>
                        <mat-select multiple (openedChange)="filterOptions('','sites')" #select formControlName="SitesId">
                            <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'site')"
                                placeholder="Filter" />
                            <mat-option *ngFor="let site of filteredSites" [value]="site">
                                <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                    [src]="site?.profilePicture ? site.profilePicture : 'assets/images/iconsForsvg/Site_Icon.svg'"
                                    onError="this.src='assets/images/iconsForsvg/Site_Icon.svg'">
                                {{ site.companyName| titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row w-100" *ngIf="screenType != 'initiativeKanbanscreen'">
                <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12 p-0 m-0">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Products"|translate}} </mat-label>
                        <mat-select multiple (openedChange)="filterOptions('','contacts')" #select
                            formControlName="ProductsId">
                            <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'contact')"
                                placeholder="Filter" />
                            <mat-option *ngFor="let product of filteredProducts" [value]="product">
                                <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                    [src]="product?.profilePicture ? product.profilePicture:'assets/images/iconsForsvg/icon_product.svg'"
                                    onError="this.src='assets/images/iconsForsvg/icon_product.svg'">
                                {{ product.productName| titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
                
            <div class="row firstAndLastName mb-3" >
              
            </div>
            <div *ngIf="screenType != 'initiativeKanbanscreen'" class="row p-2 justify-content-around" style="color: white; background-color:#f6272e; font-weight: 600;margin-left: 15px !important;margin-right:15px !important">
                <div class="col-6">{{'Tags'|translate}} </div>
                <div class="col-2">
                    <mat-icon style="color: white;" (click)="Addcondictuon()">add_box</mat-icon>
                </div>
            </div>
            <div *ngIf="screenType != 'initiativeKanbanscreen'" formArrayName="strategyConditions" style="margin-left: 15px;margin-right:15px;margin-bottom:10px;"> 
                <div style="border: 1px solid gray;padding:10px;" *ngFor="let file of strategyConditions().controls; let i=index" [formGroupName]="i">
                  <div class="row p-2 justify-content-around"
                  style="color: white; background-color:#818582; font-weight: 600;">
                  <div class="col-6"> {{'Tag'|translate}}-{{i+1}}</div>
                  <div class="col-2">
                    <mat-icon *ngIf="i!=0" (click)="Removecondictuon(i)">delete</mat-icon>
                  </div>
              </div>
                  <div class="row strategycondictions">
                    <div class="col-4">
                      <!-- UsersValue -->
                      <ng-container>
                        <mat-form-field  class="w-100">
                            <mat-label>{{'Parameter'|translate}}</mat-label>
                            <mat-select formControlName="tagColumnId">
                                <mat-option [value]="x" *ngFor="let x of tags"
                                    (click)="selactdatatype1(x,i)">
                                    {{x.fieldName|titlecase}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
          
                    </ng-container>
                    </div>
                    <div class="col-4">
                         <!-- AllOption  -->
                         <ng-container>
                         <mat-form-field  class="w-100">
                             <mat-label>{{'Comparison'|translate}}</mat-label>
                             <mat-select formControlName="condition">
                                 <mat-option [value]="x.v2" *ngFor="let x of AllOption">
                                     {{x.v2|translate}}
                                 </mat-option>
                             </mat-select>
                         </mat-form-field>
                     </ng-container>
                    </div>
                    <div class="col-4" >
                      <mat-form-field style="width: 90% !important;">
                        <input matInput type="tel" placeholder="value" formControlName="value">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            <!-- Private  -->
           
        </form>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">
    <div class="row mt-2 mb-4 justify-content-center" style="margin-bottom: 10px !important;">
        <div class="col-4">
            <button mat-raised-button mat-dialog-close>{{"Cancel"|translate}}</button>
        </div>
        <div class="col-4">
            <button class="b-blue" (click)="submit()" mat-raised-button>{{"Apply"|translate}}</button>
        </div>
    </div>
</mat-dialog-actions>