import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventPopUpDialog } from 'src/app/core/components/event-lists/event-lists.component';
import { ProfilePickFormatService } from 'src/app/core/services/profile-pick-format.service';
import { GoogleApiService } from 'src/app/core/services/SYNC/google-api.service';
import { ViewTaskComponent } from '../../management/manage-tasks/view-task/view-task.component';
import { AccordianViewComponent } from '../../management/lead-automation/components/accordian-view/accordian-view.component';
import { ScheduleAddComponent } from '../../emaildashboard/schedule-add/schedule-add.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { ManageContactsService } from 'src/app/core/services/manage-contacts.service';
import { DatePipe } from '@angular/common';
import { PopupCommonComponent } from '../../commonForAll/popup-common/popup-common.component';
import { ShowTemplateViewComponent } from '../../emaildashboard/show-template-view/show-template-view.component';

@Component({
  selector: 'app-email-popups',
  templateUrl: './email-popups.component.html',
  styleUrls: ['./email-popups.component.scss']
})
export class EmailPopupsComponent implements OnInit {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  spinner=false;
  screentype=''
  seletedmails:any=[]
  timeOptions = [{ v1: 0, v2: 'None' }, { v1: 1, v2: 'Minute' }, { v1: 2, v2: 'Hour' }, { v1: 3, v2: 'Day' }, { v1: 4, v2: 'Week' }, { v1: 5, v2: 'Month' }, { v1: 6, v2: 'Year' }];
  readonly=false
  EmailSendForm: FormGroup;
  user:any;
  eventtaskdetails:any;
  date=new Date()
  mailids: any;
  EmailAccountId: any;
  isGmail: any;
  MailType: any;
  MailAccountId: any;
  users$:any;
  contacts$:any;
  sites$:any;
  products$:any;
  userGroups$:any;
  contactGroups$:any;
  siteGroups$:any;
  productGroups$:any;
  filteredList1:any;
  filteredList2:any;
  filteredList3:any;
  filteredList4:any;
  filteredList5:any;
  filteredList6:any;
  filteredList7:any; 
  filteredList8:any;
    // auto chip for keyword
    separatorKeysCodes1: number[] = [ENTER, COMMA];
    separatorKeysCodes2: number[] = [ENTER, COMMA];
    separatorKeysCodes3: number[] = [ENTER, COMMA];
    keywordlistTO: any = [];
    keywordlistCC: any = [];
    keywordlistBCC: any = [];
    selectedMgmt:any=''
    singleSearchValue = null;
    showicon!: boolean;
  tabnumber: number=0;
  Scheduleoption=false;
  Scheduledata:any
  chooseoptionFile: boolean = false;
  fileToUpload: any;
  file: any;
  filedata: any;
  htmlContent: string = null;
  CustomTempValues=[]
  showCC=false
  showBCC=false
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<EmailPopupsComponent>,
    private GoogleApiService:GoogleApiService,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private injector: Injector,
    private dialog: MatDialog,
    private manageUsersService: ManageUsersService,
    private manageContactsService: ManageContactsService,
    public datepipe: DatePipe,   

  ) { 
    this.EmailSendForm =this.formBuilder.group({
      AccountId: [''],
      ToMail: [''],
      IsGmail: [],
      ccMail: [''],
      bccMail: [''],
      subject: [''],
      UserId: [''],
      ContactId: [''],
      UserGroupsId: [''],
      ContactGroupsId: [''],
      bodycontent: [''],
      scheduledDateAndTime: [''],
      duration: [''],
      period: [''],
      Name: [null],
      ParentId: [null],
      ChildId: [null],
      previousStep:  [null],
      step: [null],
      showIcon:[null],
      showColor: [null],
      positions: [null],
      HasEntitys:[false],
      HasContacts:[false],
      HasUsers:[false],
      HasImportTemplate:[false],
      HasSchedule:[false],
      Hastracking:[true],
      isDraft:[false],
      Template: [],
      TemplateMediaItemId: []
    })
  }
  useBlueStyle: boolean = false;

  onCheckboxChange(event: any) {
    this.useBlueStyle = event.checked; // Change the value based on your condition
  }
  getHasSchedule(){
     return this.EmailSendForm.value.HasSchedule
  }
  getHasImportTemplate(){
    return this.EmailSendForm.value.HasImportTemplate

  }
  showCCoption(){
    this.showCC=true
  }
  showBCCoption(){
    console.log(this.showBCC)
    this.showBCC=true
  }
  ngOnInit(): void {
    console.log(this.data)
    this.getSyncAccounts()
    this.GetAllDropdown()
    this.seletedmails=this.data?.seletedmails
    this.screentype=this.data?.screentype
    if(this.screentype=='Replyemail'||this.screentype=='ReplyAllemail'){
      this.EmailSendForm.get('ToMail').setValue(this.data?.maildata?.senderEmail?this.data?.maildata?.senderEmail:this.data?.maildata?.fromEmail)
      this.EmailSendForm.get('subject').setValue(this.data?.maildata?.subject)
      this.readonly=true
    }else  if(this.screentype=='Forwardemail'){
      this.EmailSendForm.get('bodycontent').setValue(this.data?.maildata?.body?.content)
    }else  if(this.screentype=='groupUserDetails'){
      this.user=this.data.maildata
    }else  if(this.screentype=='TaskDetails'||this.screentype=='EventDetails'){
      this.eventtaskdetails=this.data.maildata
    } else if (this.screentype == 'EditSendEmailAutomation') {
      if (this.data.type) {
        this.EditMailForm(this.data.type)
        this.showCC = true
        this.showBCC = true
      }
    }



  }

  EditMailForm(data){
    this.EmailSendForm.get('AccountId')?.setValue(data.accountId);
    this.EmailSendForm.get('subject').setValue(data.subject)
    this.EmailSendForm.get('bodycontent').setValue(data.content)
    this.keywordlistTO = this.setEmailList(data.toEmails);
    this.keywordlistCC = this.setEmailList(data.ccEmails);
    this.keywordlistBCC = this.setEmailList(data.bccEmails);
  }


  getststus(){
    if(this.getHasSchedule()&&this.Scheduleoption){
      return true
    }else if(this.getHasSchedule()||this.Scheduleoption){
           return false 
    }else if (!this.getHasSchedule()&&!this.Scheduleoption){
      return true
    }
  }

  getHasEntitysuser(){
    return this.EmailSendForm.value.HasUsers
  }
  getHasEntityscontact(){
    return this.EmailSendForm.value.HasContacts
  }

  getHasEntitysstatus(){
    return this.EmailSendForm.value.HasEntitys
  }
  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  } 


  showcontacts(type){
    const selectmailsforUser=this.dialog.open(EmailPopupsComponent, {
      width: '700px',
      data:{type:type,screentype:`Select${type}`},
      autoFocus: false,
      disableClose: true,
    });
    selectmailsforUser.afterClosed().subscribe((result:any)=>{
      if(result){
        console.log(result)
        result.forEach(element => {
          if(type=='TO'){
            this.keywordlistTO.push( {id:element.id,Email:element.email,GroupId:'',Entity:element.Entity}  )
          }else   if(type=='CC'){
            this.keywordlistCC.push({id:element.id,Email:element.email,GroupId:'',Entity:element.Entity})
          }else   if(type=='BCC'){
            this.keywordlistBCC.push({id:element.id,Email:element.email,GroupId:'',Entity:element.Entity})
          }
        });
      }
    })
  }


  Composemail(){
    console.log(this.seletedmails)
    if(this.seletedmails.length!=0){
      this.dialog.open(EmailPopupsComponent, {
        width: '600px',
        data:{seletedmails:this.seletedmails,screentype:'Composemail'},
        autoFocus: false,
        disableClose: true,
      });
    }
  }

  Schedule(){
    const dialogRef = this.dialog.open(ScheduleAddComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result)=>{
      if(result){
        console.log(result)
        this.Scheduleoption=true;
        this.Scheduledata=result
      }
    })
  }

  setEmailList(data:any){
    if (Array.isArray(data)) {
      return data; // Return the array as-is
    } else if (typeof data === 'string') {
      // Convert comma-separated string into an array of email objects
      return data.split(',').map((email) => ({
        id: '',
        Email: email.trim(),
        GroupId: '',
        Entity: ''
      }));
    }
   
  }


  FromMediaLibrary() {
    const FromMediaLibrary = this.dialog.open(PopupCommonComponent, {
      disableClose: true,
      width: '900px',
      data: {
        screenType: 'FromMediaLibrary',
      },
    });
    FromMediaLibrary.afterClosed().subscribe((result) => {
      console.log(result);
      if(result){
        this.chooseoptionFile=true
        this.fileToUpload=result.templateName
        this.filedata=result
      }
    });
  }

  ShowTemplateView() {
if(this.htmlContent!=null){
  const ShowTemplateView = this.dialog.open(ShowTemplateViewComponent, {
    width: '100%',
    disableClose: true,
    data: {
      htmlContent: this.htmlContent,
    },
  });
  ShowTemplateView.afterClosed().subscribe((result) => {
    console.log(result);
    this.CustomTempValues=[]
    this.CustomTempValues=result
  }); 
}
  }

  deleteFile(){
    this.chooseoptionFile = false;
    this.fileToUpload='';
    this.file='';
    this.filedata='';
    this.htmlContent='';
    this.CustomTempValues=[]
  }
  deleteSchedule(){
    this.Scheduledata={}
    this.Scheduleoption=false
  }


  Draft(){
    this.EmailSendForm.get('isDraft').setValue(true)
    this.onSend()
  }

  onSend(){
    console.log(this.EmailSendForm)
    if(this.screentype=='Composemail'){
      const formData = new FormData();
      formData.append('AccountId', this.data?.seletedmails[0].id);
      formData.append('IsGmail', this.data?.seletedmails[0]?.isGmail);
      formData.append('subject', this.EmailSendForm.value?.subject);
      formData.append('NeedTracking', this.EmailSendForm.value?.Hastracking);
      formData.append('IsSheduledCampaign', this.EmailSendForm.value?.HasSchedule);
      formData.append('isDraft', this.EmailSendForm.value?.isDraft);

      if(this.keywordlistTO.length!=0){
        this.keywordlistTO.forEach((element,index) => {
          formData.append('ToEmail[' + index + '].id', element.id);
          formData.append('ToEmail[' + index + '].Email', element.Email);
          formData.append('ToEmail[' + index + '].Entity', element.Entity);
        });
      }
      if(this.keywordlistCC.length!=0){
        this.keywordlistCC.forEach((element,index) => {
          formData.append('ccEmail[' + index + '].id', element.id);
          formData.append('ccEmail[' + index + '].Email', element.Email);
          formData.append('ccEmail[' + index + '].Entity', element.Entity);
        });
      }
      if(this.keywordlistBCC.length!=0){
        this.keywordlistBCC.forEach((element,index) => {
          formData.append('bccEmail[' + index + '].id', element.id);
          formData.append('bccEmail[' + index + '].Email', element.Email);
          formData.append('bccEmail[' + index + '].Entity', element.Entity);
        });
      }


      if(this.EmailSendForm.value.HasImportTemplate==true){
        if(this.filedata?.id){
          formData.append('TemplateId', this.filedata.id);
        }else{
          formData.append('Template', this.file);
        }
      }else{
        formData.append('body', this.EmailSendForm.value?.bodycontent);
      }
      if(this.EmailSendForm.value.HasSchedule==true){
        const Scheduledata: any = this.datepipe.transform(this.Scheduledata.date, 'yyyy-MM-dd') + 'T' +this.Scheduledata.time;
        formData.append('ScheduledTime',Scheduledata );
        }
      if(this.CustomTempValues.length!=0){
        this.CustomTempValues.forEach((element,index) => {
        formData.append('CustomTempValues['+index+'].ReplaceString', element.selectedText);
        formData.append('CustomTempValues['+index+'].ColumnName', element.Columnname);
        });
      }

      this.GoogleApiService.SendMail(formData).subscribe((result)=>{
        if(result){
          this._snackBar.open('Mail sent sucessfully', '', { duration: 2000 });
          this.matDialogRef.close()
        }
      },error=>{
        if(error.status==200){
          this._snackBar.open('Mail sent sucessfully', '', { duration: 2000 });
          this.matDialogRef.close()
        }
      })

    }else if(this.screentype=='Replyemail'||this.screentype=='ReplyAllemail'){
      const formData = new FormData();
      formData.append('AccountId', this.data?.maildata.accountId);
      formData.append('IsGmail', this.data?.maildata?.isGmail);
      formData.append('ToEmail', this.EmailSendForm.value?.ToEmail);
      formData.append('subject', this.EmailSendForm.value?.subject);
      formData.append('body', this.EmailSendForm.value?.bodycontent);
      if(this.EmailSendForm.value?.ccEmail!=null){
        formData.append('ccEmail', this.EmailSendForm.value?.ccEmail);
      }
      if(this.EmailSendForm.value?.bccEmail!=null){
        formData.append('bccEmail', this.EmailSendForm.value?.bccEmail);
      }
      this.GoogleApiService.ReplyMail(this.data?.maildata?.id,this.screentype=='ReplyAllemail'?true:false,formData).subscribe((result)=>{
        if(result){
          console.log(result)
          this._snackBar.open('Reply email sucessfully', '', { duration: 2000 });
          this.matDialogRef.close()
        }
      },error=>{
        if(error.status==200){
          this._snackBar.open('Reply email sucessfully', '', { duration: 2000 });
          this.matDialogRef.close()
        }
      })

    }else if(this.screentype=='Forwardemail'){
      const formData = new FormData();
      formData.append('AccountId', this.data?.maildata.accountId);
      formData.append('IsGmail', this.data?.maildata?.isGmail);
      formData.append('ToEmail', this.EmailSendForm.value?.ToEmail);
      formData.append('subject', this.EmailSendForm.value?.Subject);
      formData.append('body', this.EmailSendForm.value?.bodycontent);
      if(this.EmailSendForm.value?.ccEmail!=null){
        formData.append('ccEmail', this.EmailSendForm.value?.ccEmail);
      }
      if(this.EmailSendForm.value?.bccEmail!=null){
        formData.append('bccEmail', this.EmailSendForm.value?.bccEmail);
      }

      this.GoogleApiService.ForwardMail(this.data?.maildata?.id,formData).subscribe((result)=>{
        if(result){
          console.log(result)
          this._snackBar.open('Forward email sucessfully', '', { duration: 2000 });
          this.matDialogRef.close()
        }
      },error=>{
        if(error.status==200){
          this._snackBar.open('Forward email sucessfully', '', { duration: 2000 });
          this.matDialogRef.close()

        }
      })
    }

  }

  getSyncAccounts(){
    this.injector.get(GoogleApiService).GetAllSynchMailAccounts().subscribe((result:any)=>{
      if(result){
        this.mailids=result
        console.log(result)
      }
    })
  }

  selectChange(event:any){
    console.log(event.value.isGmail)
    this.MailType = event.value.isGmail
    this.MailAccountId = event.value.id
  }

  onSendEmail(){
    this.EmailSendForm.get('AccountId')?.setValue(this.MailAccountId ? this.MailAccountId : this.data?.type?.accountId);
    this.EmailSendForm.get('id')?.setValue(this.data?.type?.id);
    this.EmailSendForm.get('IsGmail')?.setValue(this.MailType ? this.MailType : this.data?.type?.isGmail);
    this.EmailSendForm.get('Name').setValue('Send Email');
    this.EmailSendForm.get('ChildId').setValue(this.data?.id);
    this.EmailSendForm.get('ParentId').setValue(this.data?.parentId);
    this.EmailSendForm.get('previousStep').setValue(this.data?.previousStep ? this.data?.previousStep : this.data?.type?.previousStep)
    this.EmailSendForm.get('step').setValue(this.data?.step ? this.data?.step : this.data?.type?.step)
    this.EmailSendForm.get('showIcon').setValue(this.data?.showIcon ? this.data?.showIcon : this.data?.type?.icon.name)
    this.EmailSendForm.get('showColor').setValue(this.data?.showColor ? this.data?.showColor : this.data?.type?.icon.color)
    this.EmailSendForm.get('positions').setValue(this.data?.Positions ? this.data?.Positions : this.data?.type?._currentPosition)
    this.EmailSendForm.get('Template').setValue(this.file);
    this.EmailSendForm.get('TemplateMediaItemId').setValue(this.filedata?.id);
    this.EmailSendForm.value.isActive = this.data?.type?.isActive;
    
    this.matDialogRef.close(this.EmailSendForm)
  }


  openEvents() {
    const dialogRef = this.dialog.open(EventPopUpDialog, {
      width: '550px',
      autoFocus: false,
      disableClose: true,
      data: {
        eventId: this.data.maildata.eventId,
        dataevent: this.data.maildata,
        screentype: this?.data?.screenType ? this?.data?.screenType : null,
      },
    });
  }
  opentaskDialog() {
    const opentaskDialog= this.dialog.open(ViewTaskComponent, {
       width: '500px',
       autoFocus: false,
       disableClose: true,
       data: this.data.maildata.taskId
     });
     opentaskDialog.afterClosed().subscribe((result: any) => {
       if (result) {

       }
     })
   }

   openPopup(formControlName: string) {
    if(this.data?.Entity == 'Automation' || this.screentype == 'EditSendEmailAutomation'){
      const openAddEventDialog  = this.dialog.open(AccordianViewComponent, {
        width: '350px',
        data: { Entity: this.data?.entity, ParentEntity: this.data?.ParentEntity  , mappingEntity:this.data?.type?.mappingEntity, beforeParentEntity: this.data?.beforeParentEntity },
        autoFocus: false,
        disableClose: true,
        position: { left: '1100px' }
      });
      openAddEventDialog.afterClosed().subscribe((result) => {
        if(result) {
          this.EmailSendForm.get(formControlName).setValue(formControlName + ' ' + 'called' + ' ' + `{${result}}`);
        } 
      })
    }

  }



    // ----------------
  // auto chip for keyword
  // -----------
  add(event: MatChipInputEvent,type) {
    if(event.value.endsWith('@gmail.com')||event.value.endsWith('@hotmail.com')){
      if(type=='TO'){
        const value = (event.value || '').trim();
        if (value) {this.keywordlistTO.push( {id:'',Email:value,GroupId:'',Entity:''} )}
        event.input.value = ''; 
      }else   if(type=='CC'){
        const value = (event.value || '').trim();
        if (value) {this.keywordlistCC.push( {id:'',Email:value,GroupId:'',Entity:''})}
        event.input.value = ''; 
      }else   if(type=='BCC'){
        const value = (event.value || '').trim();
        if (value) {this.keywordlistBCC.push( {id:'',Email:value,GroupId:'',Entity:''})}
        event.input.value = ''; 
      }
    }
}

remove(value: string,type) {
  if(type=='TO'){
    const index = this.keywordlistTO.indexOf(value);
    if (index >= 0) {this.keywordlistTO.splice(index, 1)}
  }else   if(type=='CC'){
    const index = this.keywordlistCC.indexOf(value);
    if (index >= 0) {this.keywordlistCC.splice(index, 1)}
  }else   if(type=='BCC'){
    const index = this.keywordlistBCC.indexOf(value);
    if (index >= 0) {this.keywordlistBCC.splice(index, 1)}
  }
} 


onCheckboxChange1(value: any): void {
  this.users$.forEach(element => {
    if(element.id==value.id){
      element.checked=!element.checked
    }
  });
}
onCheckboxChange2(value: any): void {
  this.userGroups$.forEach(element => {
    if(element.id==value.id){
      element.checked=!element.checked
  this.GroupToSingleconvert(value,'UserContent','Users')
    }
  });
}
onCheckboxChange3(value: any): void {
  this.contacts$.forEach(element => {
    if(element.id==value.id){
      element.checked=!element.checked
    }
  });
}
onCheckboxChange4(value: any): void {
  this.contactGroups$.forEach(element => {
    if(element.id==value.id){
      element.checked=!element.checked
  this.GroupToSingleconvert(value,'ContactContent','Contacts')
    }
  });
}


GetAllDropdown() {
  // All users 
  this.manageUsersService.findAllUsersDropdown().subscribe((res:any) => {
    this.users$ = res;
    this.users$.forEach(element => {
      element.checked=false
      element.Entity ='users'
    });

    this.filteredList1 = this.users$.slice()
  })
  // All contacts 
  this.manageContactsService.findAllContactsDropdown().subscribe((res:any) => {
    this.contacts$ = res;
    this.contacts$.forEach(element => {
      element.checked=false
      element.Entity ='contacts'
    });
    this.filteredList2 = this.contacts$.slice()
    
  })
      // All user groups 
      this.manageUsersService.findAllUsersGroupDropdown().subscribe((res:any) => {
        this.userGroups$ = res;
        this.userGroups$.forEach(element => {
          element.checked=false
        });
        this.filteredList4 = this.userGroups$.slice()

      })  
      // All contact groups 
      this.manageContactsService.findAllContactsGroupDropdown().subscribe((res:any) => {
        this.contactGroups$ = res;
        this.contactGroups$.forEach(element => {
          element.checked=false
        });
        this.filteredList5 = this.contactGroups$.slice()
      })
}
tabChange(tabIndex: number): void {
  this.showicon = false;
  this.tabnumber = tabIndex;
}

applyFilter(filterValue: string) {
  console.log(this.tabnumber)
  console.log(filterValue)
  if(this.tabnumber==0){
    if (filterValue.trim().toLowerCase().length > 0) {
      let arr = this.users$;
      arr = this.users$.filter((item: any) => {
        return (
          item.firstName.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
        );
      });
      this.users$ = arr;
    }
    this.showicon = true;
  }else if(this.tabnumber==1){
    if (filterValue.trim().toLowerCase().length > 0) {
      let arr = this.contacts$;
      arr = this.contacts$.filter((item: any) => {
        return (
          item.firstName.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
        );
      });
      this.contacts$ = arr;
    }
    this.showicon = true;
  }
}
closesearch(){
  this.showicon = false
  this.contacts$=this.filteredList1
  this.users$=this.filteredList2
}

GroupToSingleconvert(group:any, type:any, name:any) {
  if (name == 'Users') {
    this.getuser(group, type)
  } else if (name == 'Contacts') {
    this.Contacts(group, type)
  }
} 

grouptosingleAdd(ids:any, type:any) {
  var unqIds = []
  if (type == 'UserContent') {
    if (this.EmailSendForm.get('UserId')?.value.length != 0) {
      let data = this.EmailSendForm.get('UserId')?.value
      let data1 = ids
      let finalresult = data.concat(data1);
       unqIds = [...new Set(finalresult)];
      this.EmailSendForm.get('UserId')?.setValue(unqIds)
    } else {
       unqIds = [...new Set(ids)];
      this.EmailSendForm.get('UserId')?.setValue(unqIds)
    }
      this.users$.forEach(element => {
       unqIds.forEach(id => {
      if(id===element.id){
        element.checked=!element.checked
      }
      });
    });
  } else {
    if (this.EmailSendForm.get('ContactId')?.value.length != 0) {
      let data = this.EmailSendForm.get('ContactId')?.value
      let data1 = ids
      let finalresult = data.concat(data1);
       unqIds = [...new Set(finalresult)];
      this.EmailSendForm.get('ContactId')?.setValue(unqIds)
    } else {
       unqIds = [...new Set(ids)];
      this.EmailSendForm.get('ContactId')?.setValue(unqIds)
    }
      this.contacts$.forEach(element => {
       unqIds.forEach(id => {
      if(id===element.id){
        element.checked=!element.checked
      }
    });
    });
  }

}

getuser(group:any, type:any) {
  if (group.dynamicGroupId == null) {
    const GroupId = group.id;
    this.manageUsersService.getGroupUserService(GroupId).subscribe((data: any) => {
      if (data) {
        const ids:any= []
        data.forEach((element:any) => {
          ids.push(element.id)
        })
        this.grouptosingleAdd(ids, type)
      }
    });
  } else {
    const GroupId = group.dynamicGroupId;
    this.manageUsersService.getDynamicGroupUserService('Users', GroupId).subscribe((data: any) => {
      if (data) {
        const ids :any= []
        data.forEach((element:any) => {
          ids.push(element.id)
        })
        this.grouptosingleAdd(ids, type)
      }
    });
  }
}

Contacts(group:any, type:any) {
  if (group.dynamicGroupId == null) {
    const GroupId = group.id;
    this.manageContactsService.fetchContactGroupByIdService(GroupId).subscribe((data: any) => {
      if (data) {
        const ids:any = []
        data.forEach((element:any) => {
          ids.push(element.id)
        })
        this.grouptosingleAdd(ids, type)
      }
    });
  } else {
    const GroupId = group.dynamicGroupId;
    this.manageContactsService.getDynamicGroupService('Contacts', GroupId).subscribe((data: any) => {
      if (data) {
        const ids:any = []
        data.forEach((element:any) => {
          ids.push(element.id)
        })
        this.grouptosingleAdd(ids, type)
      }
    });
  }
}


  // handleFileInput 
  handleFileInput(event:any) {
    this.chooseoptionFile = true;
    this.file = event.target.files[0];
    this.fileToUpload = event.target.files[0].name;
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result as string;
      this.htmlContent = result;
    };
    reader.readAsText(event.target.files[0]);
  }

applyformailinset(){
  var seletedUser=[]
    this.users$.forEach(element => {
      if(element.checked==true){
        seletedUser.push(element)
      }
    });
    this.contacts$.forEach(element => {
      if(element.checked==true){
        seletedUser.push(element)
      }
    });

  console.log(seletedUser)
  if(seletedUser.length!=0){
    this.matDialogRef.close(seletedUser)
  }
}
}
