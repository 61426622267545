<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        {{'Approval Report'|translate}}
    </h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
     
    <div class="card mt-2" style="width: max-content !important;">
        <form [formGroup]="ManagerApproval">
        <div class="row mt-3" style="align-items: center;">
            <div class="col-4">
                <!-- <button class="allApproval">All Pending Request Approval</button> -->
            </div>
            <div class="col-5">
            </div>
            <div class="col-3">
                    <mat-form-field appearance="fill" class="w-100">
                      <mat-date-range-input [rangePicker]="dateRangePicker">
                        <input matStartDate placeholder="Start date" formControlName="startDate">
                        <input matEndDate placeholder="End date" formControlName="endDate">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                      <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
                    </mat-form-field>
            </div>
        </div>

            <table>
                <thead>
                  <tr style="background-color: rgb(220, 243, 251);">
                    <th>
                      <input type="text" class="search-input" placeholder="Search..." />
                    </th>
                    <ng-container *ngFor="let date of dates">
                      <th class="align_item">
                        {{ date }}</th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <!-- Row 1: Shifts Available -->
                  <tr style="background-color: rgb(221, 251, 221);">
                    <td class="align_item">
                      <mat-label>{{ "Needed Approvals" | translate }}</mat-label>
                    </td>
                    <ng-container *ngFor="let shift of shiftsAvailable">
                      <td class="align_item">
                        <div class="card" style="color: gray; width: 20px; height: 20px; text-align: center;">
                          {{ shift }}
                        </div>
                      </td>
                    </ng-container>
                  </tr>
              
                  <!-- Row 2: Metrics Data -->
                  <tr style="background-color: rgb(255, 207, 118);">
                    <ng-container *ngFor="let data of metricsData">
                      <td class="align_item">
                        <div class="row align_item">
                          <div class="card" [ngStyle]="{'background-color': data.hoursColor, 'width': '80px', 'height': '25px', 'padding': '3px'}">
                            {{ data.hours }}
                          </div>
                        </div>
                      </td>
                    </ng-container>
                  </tr>
              
                  <!-- Dynamic Team Members -->
                  <tr *ngFor="let member of teamMembers">
                    <td>
                      <div class="row" style="align-items: center;">
                        <img src="../../../../assets/images/team-2.jpg" alt="{{ member.firstName }}'s profile" style="width: 30px; height: 30px; border-radius: 50%; margin-right: 8px;">
                        <div class="d-flex flex-column">
                            <span style="font-weight: bold;">{{ member.firstName }} {{ member.lastName }}</span>
                            <span style="font-size: 12px; color: gray;">{{ member.role }}</span>
                        </div>
                      </div>
                      <div class="row mt-2 ">
                        <div class="d-flex flex-row">
                            <span class="card " style="background-color: rgb(254, 103, 103); width: 80px; height: 25px;text-align: center;">
                                {{ member.hours }}
                              </span>
                              <span  class="card mx-2" style="background-color: rgb(146 146 146);color:white; width: 40px; height: 25px;text-align: center;">
                                  {{ member.NeededApproval }}
                                </span>
                        </div>
                      
                      </div>
                    </td>
              
                    <ng-container *ngFor="let day of member.dailyEntries">
                      <td>
                        <div class="card" style="background-color: #f5fdff; text-align: center; border-radius: 5px;">
                            <div class="row d-flex flex-column" style="padding:5px">
                                <span style="font-weight: bold;">
                                    {{ day.time }}</span>
                                <span>
                                    {{ day.hours }} {{ day.lunch }}
                                </span>
                                <span class="mb-2" [ngStyle]="{'background-color': day.color, 'color': 'white'}" style="padding: 5px 40px; border-radius: 3px; margin: 8px;">
                                    {{ day.taskName }}
                                </span>
                            </div>
                        </div>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
       
            </form>
    </div>

</mat-dialog-content>