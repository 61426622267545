import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import imageCompression from 'browser-image-compression';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
interface CompressionOptions {
  maxSizeMB?: number;
  maxWidthOrHeight?: number;
  useWebWorker?: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class ImageCompressionService {

  constructor() { }



  compressImage(file: File, options: CompressionOptions = {}): Promise<Blob> {
    const defaultOptions = {
      maxSizeMB: 1, // Maximum size of the file after compression (in MB)
      maxWidthOrHeight: 1920, // Maximum width or height of the image
      useWebWorker: true, // Use Web Worker for processing (if supported)
    };

    const finalOptions = { ...defaultOptions, ...options };

    return imageCompression(file, finalOptions)
      .then((compressedFile: Blob) => {
        return compressedFile;
      })
      .catch((error) => {
        console.error('Error during image compression:', error);
        throw error; // Rethrow the error to propagate it
      });
  }
}
