import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { id } from '@swimlane/ngx-charts';
const baseUrl = environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {
  constructor(
    private readonly oAuthservice: OAuthService,
    private http: HttpClient
  ) {

  }

  async convertGoogleDriveUrlToFile(url: string, filename: string): Promise<File> {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename);
  }

  GetFileFromDrive(ACToken: string, FileId:any, fileName:any,isonedrive:any) {
    return this.http.get(baseUrl + '/api/File/GetFileFromDrive?ACToken=' + ACToken + '&FileId=' + FileId + '&fileName=' + fileName+'&isonedrive='+isonedrive,{ responseType: 'blob' });
  }


  verifyGoogleToken(token: string): Observable<any> {
    return this.http.get(`https://oauth2.googleapis.com/tokeninfo?access_token=${token}`);
  }


  GetSyncAccDetails() {
    return this.http.get(baseUrl + '/api/OAuth/GetSyncAccDetails?ApplicationIds=' + '2,4');
  }
  GetAllSynchMailAccounts() {
    return this.http.get(baseUrl + '/api/OAuth/GetAllSynchMailAccounts');
  }

  
  GetGoogleAccessToken(Code:any) {
    return this.http.get(baseUrl + '/api/OAuth/GetGoogleAccessToken?Code=' + Code+'&RedirectUrl='+environment.gooleredirectUri);
  }

  GetMicrosoftAccessToken(Code:any) {
    return this.http.get(baseUrl + '/api/OAuth/GetMicrosoftAccessToken?Code=' + Code+'&RedirectUrl='+environment.MicrosoftredirectUri);
  }



  UpdateAccountAsColabrative(AccountId,IsCollabarative,UserList) {
    return this.http.put(baseUrl + '/api/OAuth/UpdateAccountAsColabrative?AccountId=' + AccountId+'&IsCollabarative='+IsCollabarative+'&UserIds='+UserList.toString(),{});
  }
  CreatePermissionRequest(data){
    return this.http.post(baseUrl + '/api/Accounts/CreatePermissionRequest',data);
  }
  GetSyncAccDetais(google,outlook) {
    return this.http.get(baseUrl + '/api/OAuth/GetSyncAccDetails?google='+google+'&outlook='+outlook);
  }

  DeleteSyncAccount(id:any) {
    return this.http.delete(baseUrl + '/api/OAuth/DeleteSyncAccount?AccountUserId=' + id);
  }

  DeleteSyncAccountservice(id:any) {
    return this.http.delete(baseUrl + '/api/OAuth/DeleteSyncService?Id=' + id);
  }

  DeactivateSyncAccount(id:any,isactive) {
    return this.http.delete(baseUrl + '/api/OAuth/DeactivateSyncAccount?accUserId =' + id+'&IsActive='+isactive);
  }
  DeActivateSyncService (id:any,isactive) {
    return this.http.delete(baseUrl + '/api/OAuth/DeActivateSyncService?Id=' + id+'&IsActive='+isactive);
  }

  GetGoogleAccessTokenByRF(id:any){
    return this.http.get(baseUrl + '/api/OAuth/GetGoogleAccessTokenByRF?Id=' + id);
  }

  GetCollaboratedUsersCategories(id:any){
    return this.http.get(baseUrl + '/api/Email/GetCollaboratedUsersCategories?CollaboratedId=' + id);
  }

  GetMicrosoftAccessTokenByRF(id:any){
    const MicrosoftredirectUri:any=environment.MicrosoftredirectUri
    return this.http.get(baseUrl + '/api/OAuth/GetMicrosoftAccessTokenByRF?Id=' + id+'&RedirectUrl='+MicrosoftredirectUri);
  }


  GoogleEmailRead(accessToken:any){
    return this.http.get(baseUrl + '/api/OAuth/GoogleEmailRead?accessToken=' + accessToken);

  }
  FilterGmailEmails(accessToken:any,formdata:any){
    return this.http.get(baseUrl + '/api/OAuth/FilterGmailEmails?accessToken=' + accessToken+'&subject='+formdata.subject+'&senderName='+formdata.senderName+'&date='+formdata.date+'&body='+formdata.body);

  }
  GetAllMailFromGoogleById(AccountId:any,label:any,Id:number,IsGmail){
    return this.http.get(baseUrl + '/api/OAuth/GetAllMailFromGoogleById?AccountId=' + AccountId+'&label='+label+'&Id='+Id+'&IsGmail='+IsGmail);

  }

  GetMailDtlById(AccountId:any,label:any,Id:number,IsGmail){
    return this.http.get(baseUrl + '/api/OAuth/GetMailDtlById?AccountId=' + AccountId+'&label='+label+'&Id='+Id+'&IsGmail='+IsGmail);

  }

  OutlookEmailRead(accessToken:any){
    return this.http.get(baseUrl + '/api/OAuth/OutlookEmailRead?accessToken=' + accessToken);
  }
  FindEventConvertorFromMail(EventId:any){
    return this.http.get(baseUrl + '/api/Event/FindEventConvertorFromMail?EventId=' + EventId);
  }
  FindTaskConvertorFromMail(TaskId:any){
    return this.http.get(baseUrl + '/api/Task/FindTaskConvertorFromMail?TaskId=' + TaskId);
  }

  gettaskareeventdetails(accessToken:any){
    return this.http.get(baseUrl + '/api/OAuth/OutlookEmailRead?accessToken=' + accessToken);
  }
  GetAllMails(body){
    return this.http.post(baseUrl + '/api/OAuth/GetAllMails',body);
  }


  SendMail(body){
    return this.http.post(baseUrl + '/api/OAuth/SendMail_v1',body);
  }
  ReplyMail(mailId,replyAll,body){
    return this.http.post(baseUrl + '/api/OAuth/ReplyMail?mailId='+mailId+'&replyAll='+replyAll,body);
  }

  CreateContactFromMail(AccountId,Id,IsGmail){
    return this.http.post(baseUrl + '/api/OAuth/CreateContactFromMail?AccountId='+AccountId+'&Id='+Id+'&IsGmail='+IsGmail,{});
  }
  ForwardMail(mailId,body){
    return this.http.post(baseUrl + '/api/OAuth/ForwardMail?mailId='+mailId,body);
  }

  FilterOutlookEmails(accessToken:any,formdata:any){
    return this.http.get(baseUrl + '/api/OAuth/FilterOutlookEmails?accessToken=' + accessToken+'&subject='+formdata.subject+'&senderName='+formdata.senderName+'&date='+formdata.date+'&body='+formdata.body);

  }

}
