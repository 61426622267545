import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class ManageAutomationService {

    private automationDataSource = new BehaviorSubject<any>(null);
  currentAutomationData = this.automationDataSource.asObservable();
  constructor(private http: HttpClient) { }

  loginToken = sessionStorage.getItem('access_token');

  updateAutomationData(data: any) {
    this.automationDataSource.next(data);
  }

  setEntityData(data: any) {
    this.automationDataSource.next(data);
  }

  getGoogleCalender() {
    return this.http.get(baseUrl + '/api/OAuth/GoogleOAuthRedirect');
  }

  findConnector() {
    return this.http.get(baseUrl + '/api/OAuth/FindConnectors');
  }


  CreateScenario(requestData: any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) };
    return this.http.post(baseUrl + '/api/Automation/CreateScenario', requestData, httpOptions);
  }

  UpdateScenario(requestData: any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) };
    return this.http.put(baseUrl + '/api/Automation/UpdateScenario', requestData, httpOptions);
  }

  FindEntity(){
    return this.http.get(baseUrl + '/api/Automation/FindScenarioEvents');
  }

  FindColumns(Entity:any){
    return this.http.get(baseUrl + '/api/Automation/FindColumns?Entity=' + Entity)
  }

  getScenrios(pageno:any, numberofRecord:any, search:any){
    return this.http.get(baseUrl + '/api/Automation/FindAllScenarios?PageNumber=' + pageno + '&NumberOfRecord=' + numberofRecord + '&Search=' + search)
  }

  getScnerioByID(id:any) {
    return this.http.get(baseUrl + '/api/Automation/FindScenarioById?Id=' + id)
  }

  getExecutionsByID(id:any, ScenerioId:any) {
    return this.http.get(baseUrl + '/api/Automation/TrackAutomation?TrackingId=' + id + '&ScenarioId=' + ScenerioId)
  }

  getUsersByID(id:any) {
    return this.http.get(baseUrl + '/api/Automation/FindScenarioUsersById?Id=' + id)
  }

  deleteScenerioById(id: string) {
    return this.http.delete(baseUrl + '/api/Automation/DeleteScenario?Id=' + id).toPromise();
  }

  getExecutionsWithId(id:string, pageno:any, numberofRecord:any, search:any){
    return this.http.get(baseUrl + '/api/Automation/FindScenarioExecutions?ScenarioId=' + id + '&PageNumber=' + pageno + '&NumberOfRecord=' + numberofRecord + '&Search=' + search)
  }

  createScenerio(formData: any) {
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.post(baseUrl + '/api/Automation/CreateScenario', formData, { headers: headers })
  }

  CreateScenarioConditions(requestData: any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) };
    return this.http.post(baseUrl + '/api/Automation/CreateScenarioConditions', requestData, httpOptions);
  }

  UpdateScenarioConditions(requestData: any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) };
    return this.http.put(baseUrl + '/api/Automation/UpdateScenarioCondition', requestData, httpOptions);
  }

  CreateScenarioActions(requestData: any) {
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.post(baseUrl + '/api/Automation/CreateScenarioActions', requestData, { headers: headers });
  }

  UpdateScenarioActions(requestData: any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) };
    return this.http.put(baseUrl + '/api/Automation/UpdateScenarioAction', requestData, httpOptions);
  }

  getEntityColoumns(data:any){
    return this.http.get(baseUrl + '/api/Automation/FindColumns?Entity=' + data);
  }

  getAllExecutions(pageno:any, numberofRecord:any, search:any){
    return this.http.get(baseUrl + '/api/Automation/FindExecutions?PageNumber=' + pageno + '&NumberOfRecord=' + numberofRecord + '&Search=' + search)
  }

}