<div class="d-flex flex-row align-items-center modal-header width_900">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <mat-icon style="position: relative;top: 5px;right: 10px;"> event</mat-icon>
        {{'Event Proposal'|translate}}
    </h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content style="height:500px;">
    <div class="container">
        <div class="row mt-2">
            <div class="col-md-6 col-sm-12">
                <mat-form-field appearance="legacy" class="w-100">
                    <mat-label>{{'Start Date & Time'|translate}}</mat-label>
                    <input matInput type="datetime-local" placeholder="Start Date & Time" id="StartDate"
                        (change)="startDate=$event.target.value;getstartInEvent($event.target.value)">
                </mat-form-field>
            </div>
            <div class="col-md-6 col-sm-12">
                <mat-form-field appearance="legacy" class="w-100">
                    <mat-label>{{'End Date & Time'|translate}}</mat-label>
                    <input matInput type="datetime-local" placeholder="End Date & Time" id="EndDate"
                        (change)="endDate=$event.target.value; getUsersInEvent($event.target.value)">
                </mat-form-field>
            </div>
        </div>
        <div class="row w-100">
          <div class="col-11 w-100">
            <pm-calendar-header (dayViewChanged)="dayViewChanged($event)"
            (calendarViewChanged)="calendarViewChanged($event)" [(view)]="calendarView" [(viewDate)]="viewDate"
            [smallDevice]="smallDevice">
        </pm-calendar-header>
          </div>
        </div>
        <div class="row w-100">
            <!-- calendar-week  -->
            <mwl-calendar-week-view style="width:900px !important; overflow-x: scroll;overflow: hidden !important;"
                *ngIf="calendarView =='week'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                [dayStartHour]="dayStartHour" (eventClicked)="eventClicked($event.event)"[snapDraggedEvents]="snapDraggedEvents">
            </mwl-calendar-week-view>
            <!-- calendar-week  -->
            <!-- calendar-day  -->
            <mwl-calendar-day-view  style="width:900px !important; overflow-x: scroll;overflow: hidden !important;"
            *ngIf="calendarView =='day'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
            [dayStartHour]="dayStartHour"   (eventClicked)="eventClicked($event.event)"[snapDraggedEvents]="snapDraggedEvents">
            </mwl-calendar-day-view>
            <!-- calendar-day  -->
          
            <!-- ----------------------  -->
            <div class="available-user" *ngIf="showUsers">
                <div class="row row_design"> 
                    <div class="col-10">
                        <span class="design"> {{'People Available'|translate}}</span>
                    </div>
                    <span class="close floatright" (click)="showUsers = !showUsers"> X </span>
                </div>
                <ul>
                    <li class=" fw-600 color_grey" *ngFor="let data of availableUsersList">
                        <img [src]="data.profilePicture ? data.profilePicture : 'assets/images/admin.jpg'" />
                        {{data.firstName+' '+data.lastName|titlecase}}
                    </li>
                </ul>
            </div>
        </div>
        <!-- --------------  -->
        <div class="row w-100">
            <div class="col-6"></div>
            <div style="float: right" class="apply"><button mat-raised-button [hidden]="hideButton"
                    (click)="eventProposal()" color="primary">{{'Apply'|translate}}</button></div>&nbsp;&nbsp;
            <div style="float: right"><button mat-raised-button [hidden]="hideButton" (click)="closedialogbox()"
                    color="primary">{{'Cancel'|translate}}</button></div>
        </div>
    </div>

</mat-dialog-content>


