import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { error } from 'console';
import { GoogleApiService } from 'src/app/core/services/SYNC/google-api.service';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { DriveService } from 'src/app/core/services/drive.service';
import { FileService } from 'src/app/core/services/file.service';
import { GraphService } from 'src/app/core/services/graph.service';
import { ManageContactsService } from 'src/app/core/services/manage-contacts.service';
import { ManageProductsService } from 'src/app/core/services/manage-products.service';
import { ManageSitesService } from 'src/app/core/services/manage-sites.service';
import { ManageTransactionsService } from 'src/app/core/services/manage-transactions.service';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { MessageService } from 'src/app/core/services/message.service';
import { StrategyService } from 'src/app/core/services/strategy.service';
import { PopupErrorMessageComponent } from 'src/app/popup-error-message/popup-error-message.component';
import { ReminderCreateComponent } from '../../Reminder/reminder-create/reminder-create.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-popup-common',
  templateUrl: './popup-common.component.html',
  styleUrls: ['./popup-common.component.scss']
})
export class PopupCommonComponent implements OnInit {
  screenType: any;
  entitydata=[];
  DemoOrganation:boolean=false
  message=null;
  value=''
  NewlableName=null
  Email=null
  filterforuserlist: any;
  UsersList;
    // value: any;
  // auto chip for keyword
  separatorKeysCodes: number[] = [ENTER, COMMA];
  keywordlist: string[] = [];
  templateform!: FormGroup;
  htmlContent: string | null = null;
  file: any;
  FromMediaLibrarylist:any
  totalTemplates=0
  NumberOfRecord: number=8;
  PageNumber: number=1;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private MatDialogRefDelet: MatDialogRef<PopupCommonComponent>,
    public snackBar: MatSnackBar,
    private manageContactsService: ManageContactsService,
    private managesitesService: ManageSitesService, 
    private manageproductsService: ManageProductsService,
    private manageUserService: ManageUsersService,
    private calendarService: CalendarService,
    private fileService: FileService,
    private strategyservice: StrategyService,
    private ManageTransactionsService: ManageTransactionsService,
    private graphService: GraphService,
    private DriveService: DriveService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private goole: GoogleApiService,
    private injector: Injector,
    private formBuilder: FormBuilder,
  ) {
    this.templateform = this.formBuilder.group({
      keyword:[]
    })
    if(sessionStorage.getItem('DemoOrganation')==='true'){
      this.DemoOrganation==true
    }else{
      this.DemoOrganation==false
    }
   }

  ngOnInit(): void {
    this.screenType = this.data?.screenType
    this.entitydata = this.data.data;
    console.log(this.data)
    if(this.screenType=='onDoubleClick'){
      this.value==this.data?.value
      console.log(this.data?.value)
    }

    if(this.screenType=='FromMediaLibrary'){
      this.GetTemplates()
     }
      this.getallusers()
  }
  nextpage(e: any) {
    this.PageNumber=e.pageIndex + 1
    this.GetTemplates()
  }

  GetTemplates(){
    this.injector.get(ManageUsersService).GetTemplates(this.NumberOfRecord,this.PageNumber).subscribe((res: any) => {
      if(res){
        this.totalTemplates=res?.totalItems
        if(res.data.lenght!=0){
          this.FromMediaLibrarylist=[]
          res.data.forEach(element => {
            this.FromMediaLibrarylist.push(element)
          });
        }
      }
  })
}

FromMediaLibrary(data){
  console.log(data)
  this.MatDialogRefDelet.close(data)
  }


  getallusers(){
 // get all users
 this.injector.get(ManageUsersService).findAllUsersDropdown().subscribe((res: any) => {
  const data = res;
  this.filterforuserlist = data;
});
  }

  Collaborationbutton(){
    if(this.screenType=='Collaboration_Remove'){
      this.MatDialogRefDelet.close({})
    }else{
    this.MatDialogRefDelet.close(true)

    }
}

  onDoubleClickSubmit(){
    if(this.NewlableName!=null){
      this.MatDialogRefDelet.close(this.NewlableName)
    }

  }

  onSubmit(){
    if(this.screenType=='Accpet'){
      this.MatDialogRefDelet.close(this.message)
    }else if(this.screenType=='Comment'){
      this.MatDialogRefDelet.close(this.message)
    }else if(this.screenType=='checkmailsyncststus'){
      this.MatDialogRefDelet.close(this.Email)
    }else if(this.screenType=='RequestedMailpermission'){
      this.dialog.closeAll()
    }else if(this.screenType=='ResetLabels'){
      this.MatDialogRefDelet.close(true)
    }else if(this.screenType=='Reject'){
      this.MatDialogRefDelet.close(true)
    }else if(this.screenType=='Accpet1'){
      this.MatDialogRefDelet.close(true)
    }

  }
  onSubmitforsavetemplate(){
    console.log(this.templateform.value)
    const data={
      keywordlist:this.keywordlist,
      file:this.file
    }
    this.MatDialogRefDelet.close(data)
  }




    // ----------------
  // auto chip for keyword
  // -----------
  add(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    if (value) {
      this.keywordlist.push(value);
    }
    event.input.value = ''; 
    this.templateform.get('keyword')?.setValue(null);

  }


  remove(value: string) {
    const index = this.keywordlist.indexOf(value);
    if (index >= 0) {
      this.keywordlist.splice(index, 1);
    }
  }


  handleFileInput(event){
    const fileInput = event.target as HTMLInputElement;
    const file = fileInput?.files?.[0];
    this.file=fileInput?.files?.[0];
    if (file && file.type === 'text/html') {
      const reader = new FileReader();
      
      reader.onload = (e) => {
        this.htmlContent = e.target?.result as string;
      };

      reader.readAsText(file);
    } else {
      alert('Please select a valid HTML file.');
    }
  }
  







}
