import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from 'src/app/core/services/file.service';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { ProfilePickFormatService } from 'src/app/core/services/profile-pick-format.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { AdvanceSearchInActivityLogComponent } from '../advanceSearch/advance-search-in-activity-log/advance-search-in-activity-log.component';
import { EditEventComponent } from 'src/app/core/components/event-lists/edit-event/edit-event.component';
import { DeleteCommomComponent } from '../../commonForAll/delete-commom/delete-commom.component';
import { CreateUserComponent } from '../manage-create/create-user/create-user.component';
import { CreateEventComponent } from '../../calendar/create-event/create-event.component';
import { GraphFilterComponent } from '../../graph/components/graph-filter/graph-filter.component';
import { CreateStrategyComponent } from 'src/app/core/components/create-strategy/create-strategy.component';
import { TargetCreateComponent } from 'src/app/core/components/group-target/target-create/target-create.component';
import { AddFileComponent } from '../../files/components/add-file/add-file.component';
import { AddNotesComponent } from '../../files/components/add-notes/add-notes.component';
import { CreateContactComponent } from '../manage-create/create-contact/create-contact.component';
import { EmaildashboardComponent } from '../../emaildashboard/emaildashboard.component';
import { EmailPopupsComponent } from '../../dashboard/email-popups/email-popups.component';
import { CreateTaskComponent } from '../manage-tasks/create-task/create-task.component';
import { GoogleApiService } from 'src/app/core/services/SYNC/google-api.service';

@Component({
  selector: 'app-activit-log',
  templateUrl: './activit-log.component.html',
  styleUrls: ['./activit-log.component.scss']
})
export class ActivitLogComponent implements OnInit {
  ActivitLogdata:any=[]
  PageNo=1;
  PageCount=10
  Entity='';
  UserIds=[]
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  spinnerLoader = false
  DialogData: any;
  AdminStatus: any;
  senderName: any;
  senderEmail: any;
  ccRecipients:any;
  subject: any;
  mediaType: any;
  subNoteAttachments: any;
  logDataLength:any
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public spinner: SpinnerService,
    private injector: Injector,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private matdialogref: MatDialogRef<ActivitLogComponent>,
    private GoogleApiService:GoogleApiService,
  ) { 
    this.AdminStatus = sessionStorage.getItem("isAdmin");
  }

  ngOnInit(): void {
    console.log(this.data)
    this.DialogData = this.data.data
    this.Entity=this.data.Entity

    if(this.data.type == 'Automation' && this.data.data.IsType == 'View') {
      if(this.Entity == 'Condition') {
        this.getConditionLog(1, 10, '')
      } else if (this.Entity == 'Action') {
        this.getActionLog(1, 10, '')
      } else {
        this.getTriggerLog(1, 10, '')
      }
  
    } else if (this.data.type == 'Automation' && this.data.data.IsType == 'Execution') {
      this.ActivitLogdata.length = 1
    }
     else {
      this.getActivityLog()
    }

  }

  getConditionLog(pageno:any, numberOfRecord:any, search:any){
    this.spinnerLoader = true
    this.injector.get(ManageUsersService).GetConditionLog(pageno,numberOfRecord,search,this.data.data.id.toString()).subscribe((result:any)=>{
      if(result){
        this.spinnerLoader = false
        console.log(result)
        this.ActivitLogdata=result.data
        this.logDataLength=result.totalItems;
      } 
    }, error => {
      this.spinnerLoader = false
    })
  }

  getActionLog(pageno:any, numberOfRecord:any, search:any) {
    this.spinnerLoader = true
    this.injector.get(ManageUsersService).GetActionLog(pageno,numberOfRecord,search,this.data.data.id.toString()).subscribe((result:any)=>{
      if(result){
        this.spinnerLoader = false
        console.log(result)
        this.ActivitLogdata=result.data
        this.logDataLength=result.totalItems;
      } 
    }, error => {
      this.spinnerLoader = false
    })
  }

  getTriggerLog(pageno:any, numberOfRecord:any, search:any) {
    this.spinnerLoader = true
    this.injector.get(ManageUsersService).GetTriggerLog(pageno,numberOfRecord,search,this.data.data.id.toString()).subscribe((result:any)=>{
      if(result){
        this.spinnerLoader = false
        console.log(result)
        this.ActivitLogdata=result.data
        this.logDataLength=result.totalItems;
      } 
    }, error => {
      this.spinnerLoader = false
    })
  }

  getActivityLog(){
    this.spinnerLoader = true
    this.injector.get(ManageUsersService).GetActivityLog(this.PageNo,this.PageCount,this.Entity,this.data.data.id.toString()).subscribe((result:any)=>{
      if(result){
        this.spinnerLoader = false
        console.log(result)
        this.ActivitLogdata=result.dto_1
        this.logDataLength=result.totalCount
        console.log(this.ActivitLogdata.length)
      } 
    }, error => {
      this.spinnerLoader = false
    })
  }

  pagnext(e: any) {
    console.log(e.pageIndex)
    this.PageNo=e.pageIndex+1
    if(this.data.type == 'Automation' && this.data.data.IsType == 'View') {
      if(this.Entity == 'Condition') {
        this.getConditionLog(this.PageNo, 10, '')
      } else if (this.Entity == 'Action') {
        this.getActionLog(this.PageNo, 10, '')
      } else {
        this.getTriggerLog(this.PageNo, 10, '')
      }
  
    } else {
      this.getActivityLog()
    }
  
  }

  httpGet(theUrl:any) {
    var xmlHttp:any= null;
    xmlHttp = new XMLHttpRequest();
    xmlHttp.open('GET', theUrl, false);
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200 || xmlHttp.status == 0) {
          this.allText = xmlHttp.responseText;
          this.doc = new DOMParser().parseFromString(this.allText, 'text/html')
        }
      }
    }
    xmlHttp.send(null);
    return xmlHttp.responseText;
  }

  clickingmail(){
    const emaildashboard = this.dialog.open(EmaildashboardComponent, {
      width: '99%',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog-container',
    });

    emaildashboard.afterClosed().subscribe((result) => {

    });
  }

  getsenderemail(email:any){
    const slicedEmail = email.slice(0, 24);
    if (email.length > 24) {
        return slicedEmail + '...'; 
    } else {
        return slicedEmail;
    }
  }

  getfiletype(name:any){
    return name.split('.')[1]
  }
 
  closeDialog(){
    this.matdialogref.close(true)
  }

  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  }
  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  

  opensearch(){
    const AdvanceSearch = this.dialog.open(
      AdvanceSearchInActivityLogComponent,
      {
        disableClose: true,
        width: '500px',
      }
    );
    
    AdvanceSearch.afterClosed().subscribe((result: any) => {
      if (result) {
      }
    })
  }

    // openEditdEventDialog
    openEditdEventDialog(id:any) {
      const openAddEventDialog = this.dialog.open(EditEventComponent, {
        width: '500px',
        data: { eventId: id },
      });
      openAddEventDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.getActivityLog()
        }
      });
    }

    deleteDialogConfirmation(data:any, type:any) {
      const deleteorder = this.dialog.open(DeleteCommomComponent, {
        data: { data, type: type },
      });
      deleteorder.afterClosed().subscribe((result: any) => {
        this.getActivityLog()
      });
    }

    updateuserDialog(row:any) {
      const UpdateUserDialog = this.dialog.open(CreateUserComponent, {
        data: { screenType: 'UpdateUser', data: row },
        disableClose: true,
        width: '500px'
      });
      UpdateUserDialog.afterClosed().subscribe(result => {
        
        console.log(`Dialog result: ${result}`);
      });
    }

     // openAddEventDialog
  openAddEventDialog() {
    this.dialog.open(CreateEventComponent, {
      width: '500px',
      autoFocus: false,
      position: { top: '125px', left: '700px' },
      data: { screenType: 'CreateEvent' },
      disableClose: true,
      panelClass: 'custom-dialog-container'
    });
  }

   // ActivityReport
   ActivityReport(data:any, value:any, name: string) {
    this.dialog.open(GraphFilterComponent, {
      width: '500px',
      position: { top: '125px', left: '700px' },
      autoFocus: false,
      disableClose: true,
      data: { data, Name: name, single: value, management: true },
    });
  }

    // CreateStratergy
    CreateStratergy() {
      this.dialog.open(CreateStrategyComponent, {
        width: '600px',
        position: { top: '125px', left: '700px' },
        autoFocus: false,
        disableClose: true
      });
    }

    // createTarget
  createTarget(row:any) {
    this.dialog.open(TargetCreateComponent, {
      disableClose: true,
      data: { data: row, type: 'Users', state: 'Single' },
      position: { top: '125px', left: '700px' },
      width: '500px',
    });
  }

    // AddFile
    AddFile(user:any, type:any) {
      const addFilesDialog = this.dialog.open(AddFileComponent, {
        disableClose: true,
        data: { data: user, attachmentLevel: 'Users', ismanage: true, type: type },
        position: { top: '125px', left: '700px' },
        width: '500px',
        panelClass: ['addFiles']
      });
      addFilesDialog.afterClosed().subscribe(result => {
        if (result) {
          const formData = new FormData(); formData.append('AttachmentLevel', result.attachmentLevel);
          formData.append('SelectIds', result.selectName);
          formData.append('Keywords', result.Keywords);
          formData.append('Created', new Date().toDateString());
          const accompSize = result.AttachmentsArr.length;
          for (let i = 0; i < accompSize; i++) {
            let element = result.AttachmentsArr[i];
            formData.append('Attachments[' + i + '].file', element.upFile);
            formData.append('Attachments[' + i + '].Title', element.title);
            formData.append('Attachments[' + i + '].IsPrivate', element.isPrivate);
          }
          console.log(formData)
          this.injector.get(FileService).createFile(formData).subscribe(data => {
            if (data) {
              console.log(data)
              this.snackBar.open(data.response[0].message, '', { duration: 2000, });
            }
          });
        }
      });
    }
  
    //  AddNote
    AddNote(row:any, type:any) {
      const addNotesDialog = this.dialog.open(AddNotesComponent, {
        disableClose: true,
        data: { data: row, attachmentLevel: 'Users', ismanage: true, type: type },
        position: { top: '125px', left: '700px' },
        width: '600px',
        panelClass: ['addNotes']
      });
    }

    updateContactDialog(row:any) {
      const createContactDialog = this.dialog.open(CreateContactComponent, {
        data: { screenType: 'UpdateContact', data: row },
        disableClose: true,
        width: '500px'
      });
  
      createContactDialog.afterClosed().subscribe(result => {
        this.ngOnInit()
      });
    }

    // for mails 
    Replyemail(data){
      this.dialog.open(EmailPopupsComponent, {
        width: '600px',
        data:{maildata:data?.mailDto,screentype:'Replyemail'},
        autoFocus: false,
        disableClose: true,
      });
    } 
    Forwardemail(data){
      this.dialog.open(EmailPopupsComponent, {
        width: '600px',
        data:{maildata:data?.mailDto,screentype:'Forwardemail'},
        autoFocus: false,
        disableClose: true,
      });
    }
    CreateTask(x){
      const CreateTask = this.dialog.open(CreateTaskComponent, {
        width: '500px',
        autoFocus: false,
        data: {data: x?.mailDto, type: 'Createtask_with_mail' },
        disableClose: true,
      });
  
      CreateTask.afterClosed().subscribe((result) => {
        if(result){
          this.getActivityLog()
        }
      });
    }
    CreateEvent(x){
      const CreateTask = this.dialog.open(CreateEventComponent, {
        width: '500px',
        autoFocus: false,
        data: {data:x?.mailDto, screenType: 'CreateEvent_with_mail' },
        disableClose: true,
      });
  
      CreateTask.afterClosed().subscribe((result) => {
          if(result){
            this.getActivityLog()
          }
      });
    }
    ConverttoContact(value){
      console.log(value)
      this.GoogleApiService.CreateContactFromMail(value?.accountId,value?.id,value?.isGmail).subscribe((result)=>{
        if(result){
          console.log(result)
          this.snackBar.open('Create Contact From Mail done', '', { duration: 2000, })
        }
      },error=>{
        if(error.status==200){
          this.snackBar.open('Create Contact From Mail done', '', { duration: 2000, })
        }
      })
    }

}
